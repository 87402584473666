import { shapes } from '@greenville/framework';
import { Container } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Paper from '@material-ui/core/Paper';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import { withStyles } from '@material-ui/core/styles';
import HomeIcon from '@mui/icons-material/Home';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, Route, Switch } from 'react-router-dom';
// import LinkMenu from '../../common/components/linkMenu';
import Header from '../../common/header/Header';
import ChatGptUtility from './components/ChatGptUtility';
import ChatGptUtilityFeeback from './components/ChatGptUtilityFeeback';
import ChatGptUtilityTrends from './components/ChatGptUtilityTrends';
import ChannelsComponent from './components/channels/ChannelsComponent';
import ChannelsFeedback from './components/channels/ChannelsFeedback';
import ChannelsTrends from './components/channels/ChannelsTrends';
import ChatDataToc from './components/datagrid/ChatDataToc';
// import ReportsComponent from './components/reports/ReportsComponent';
import TenantList from './components/tenantList/TenantList';
import McqSearch from './components/titleSearch/McqSearch';
// import ChatgptUtilityAdmin from './components/ChatgptUtilityAdmin';
import TooltipCustom from '../../common/components/TooltipCustom';
import ChatgptConfig from '../../common/config/ChatgptConfig';
import UsersPermissions from '../users/models/UsersPermissions';
import AdminView from './components/AdminView';
// import NotFound from './components/NotFound';
import DynamicComponent from './components/DynamicComponent/DynamicComponent';
import AdminPromptView from './components/admin/AdminPromptView';
import TrendingTopicsView from './components/aiCharts/trendingTopics/TrendingTopicsView';
import ChatBotConfig from './components/chatBotConfig/ChatBotConfig';
import EffectivenessMainComponent from './components/effectiveness/EffectivenessMainComponent';
import PopularPageComponent from './components/popularPages/PopularPageComponent';
import PVSAssesment from './components/pvsAssessment/PVSAssesment';
import SelectLesson from './components/pvsAssessment/SelectLesson';
import SelectedChapter from './components/pvsAssessment/SelectedChapter';
import TenantListView from './components/tenantList/TenantListView';
import ChatutilityRolesContainer from './components/user/ChatutilityRolesContainer';
import ChatutilityUsersContainer from './components/user/ChatutilityUsersContainer';

// import McqEditQuestionDialog from './components/tocdialogues/McqEditQuestionDialog';
// import TocAddDialog from './components/tocdialogues/TocAddDialog';
// import Charts from './components/Charts';

const styles = {
  contentWrapper: {
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    top: 75
  },
  buttonStyle: {
    marginLeft: '35px'
  },
  IconButtonStyle: {
    width: '30px',
    height: '30px'
  }
};

/* const breadcrumbStyles = {
  color: '#252525',
  fontSize: '16px',
  fontFamily: 'Open Sans, Calibri, Tahoma, sans-serif',
  fontWeight: '400',
  lineHeight: '1.25'
}; */

/* const activeBreadcrumbStyles = {
  fontWeight: 'bold',
  color: '#252525'
}; */

@inject(
  'chatgptUtility',
  'channelsUtility',
  'chatgptSummaryUtility',
  'chatgptAdminPrompts',
  'language',
  'userPermissions'
)
@observer
class ChatGptUtilityContainer extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    history: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    userPermissions: shapes.modelOf(UsersPermissions).isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      tabValue: 0
      // tabState: '',
    };
  }

  componentDidMount() {
    this.tabHandler();
  }

  componentDidUpdate() {
    const { userPermissions, history } = this.props;
    const { tabValue } = this.state;
    const permissionsList = toJS(userPermissions.permissionList.permissions);
    const isShowPromptManagementTab =
      permissionsList.includes('admin_can_view') || permissionsList.includes('admin_can_edit');
    const isShowContentManagementTab =
      permissionsList.includes('mcq_can_view') || permissionsList.includes('mcq_can_edit');
    let tabNo;
    const length = 1;
    const defaultTabLength = 2;
    const pathName = history.location.pathname;
    if (pathName === '/chatgptutility/aistudytools/promptmgt' && isShowPromptManagementTab) {
      tabNo = defaultTabLength + length;
    } else if (pathName === '/chatgptutility/aistudytools/titlesearch' && isShowContentManagementTab) {
      tabNo = defaultTabLength + length;
      if (isShowPromptManagementTab) {
        tabNo += 1;
      }
    }
    if (tabNo && tabValue !== tabNo) {
      this.setState({ tabValue: tabNo });
    }
  }

  handleResetTabValue = () => {
    this.setState({ tabValue: 0 });
  };

  handleBreadcrumbClick = () => {
    // console.log('am clicked', path);
  };

  handleChange = () => {
    const { history } = this.props;
    this.setState({
      tabValue: 0
    });
    history.push('/chatgptutility');
  };

  handleTabChange = (e, value) => {
    // const { language } = this.props;
    this.setState({
      tabValue: value
    });
  };

  tabHandler() {
    const { history } = this.props;
    let TabValue = 0;
    const pathName = history.location.pathname;
    switch (pathName) {
      case '/chatgptutility/aistudytools':
        TabValue = 0;
        break;
      case '/chatgptutility/aistudytools/feedback':
        TabValue = 1;
        break;
      case '/chatgptutility/aistudytools/trends':
      case '/chatgptutility/aistudytools/trends/effectiveness':
        TabValue = 2;
        break;
      /* case '/chatgptutility/aistudytools/promptmgt':
        TabValue = 3;
        break;
      case '/chatgptutility/aistudytools/titlesearch':
        if (isShowPromptManagementTab) {
          TabValue = 4;
        } else {
          TabValue = 3;
        }
        break; */
      default:
        TabValue = 0;
    }

    /* if (history.location.pathname === '/chatgptutility/aistudytools') {
      TabValue = 0;
    } else if (history.location.pathname === '/chatgptutility/aistudytools/feedback') {
      TabValue = 1;
    } else if (
      history.location.pathname === '/chatgptutility/aistudytools/trends' ||
      history.location.pathname === '/chatgptutility/aistudytools/trends/effectiveness'
    ) {
      TabValue = 2;
    } */
    // } else if (history.location.pathname === '/chatgptutility/aistudytools/reports') {
    //   TabValue = 3;
    // }
    this.setState({
      tabValue: TabValue
    });
  }

  render() {
    // const paths = [{ title: '/ Titlesearch', path: '/chatgptutility/aistudytools/titlesearch' }];
    const { classes, history, language, userPermissions } = this.props;
    const permissionsList = toJS(userPermissions.permissionList.permissions);
    const { tabValue } = this.state;
    const renderTenantWiseTab = () => {
      const tabData = [];
      ChatgptConfig.tenantData.forEach(
        (item) =>
          item.tabDetails &&
          item.tabDetails.forEach((val, index) => {
            const selectedTenantName = history.location.pathname.split('/');
            if (item.name === selectedTenantName[2]) {
              tabData.push([
                <Tab
                  key={index}
                  label={
                    <TooltipCustom title={<FormattedMessage {...language.getText(val.toolTipTitle)} />}>
                      <span>{val.tabName}</span>
                    </TooltipCustom>
                  }
                  component={Link}
                  to={`/chatgptutility/${item.name}/${val.tabUrl}`}
                />
              ]);
            }
          })
      );
      return tabData;
    };
    const renderChannelsTab = [
      <Tab
        label={
          <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.AUDIT_LOG')} />}>
            <span>Audit Logs</span>
          </TooltipCustom>
        }
        component={Link}
        to="/chatgptutility/channels"
      />,
      <Tab
        label={
          <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.USER_FEEDBACK')} />}>
            <span>User Feedback</span>
          </TooltipCustom>
        }
        component={Link}
        to="/chatgptutility/channels/feedback"
      />,
      <Tab
        label={
          <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.TRENDS_INSIGHTS')} />}>
            <span>Trends & Insights</span>
          </TooltipCustom>
        }
        component={Link}
        to="/chatgptutility/channels/trends"
      />
      // <Tab
      //   label={
      //     <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.REPORTS')} />}>
      //       <span>Reports</span>
      //     </TooltipCustom>
      //   }
      //   component={Link}
      //   to="/chatgptutility/channels/reports"
      // />
    ];
    if (permissionsList.includes('admin_can_view') || permissionsList.includes('admin_can_edit')) {
      renderChannelsTab.push(
        <Tab
          label={
            <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.PROMPT_MANANGEMENT')} />}>
              <span>Prompt Management</span>
            </TooltipCustom>
          }
          component={Link}
          to="/chatgptutility/channels/promptmgt"
        />
      );
    }
    const renderAuditTab = [
      <Tab
        label={
          <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.AUDIT_LOG')} />}>
            <span>Audit Logs</span>
          </TooltipCustom>
        }
        component={Link}
        to="/chatgptutility/aistudytools"
      />,
      <Tab
        label={
          <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.USER_FEEDBACK')} />}>
            <span>User Feedback</span>
          </TooltipCustom>
        }
        component={Link}
        to="/chatgptutility/aistudytools/feedback"
      />,
      <Tab
        label={
          <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.TRENDS_INSIGHTS')} />}>
            <span>Trends & Insights</span>
          </TooltipCustom>
        }
        component={Link}
        to="/chatgptutility/aistudytools/trends"
      />
      // <Tab
      //   label={
      //     <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.REPORTS')} />}>
      //       <span>Reports</span>
      //     </TooltipCustom>
      //   }
      //   component={Link}
      //   to="/chatgptutility/aistudytools/reports"
      // />
    ];
    if (permissionsList.includes('admin_can_view') || permissionsList.includes('admin_can_edit')) {
      renderAuditTab.push(
        <Tab
          label={
            <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.PROMPT_MANANGEMENT')} />}>
              <span>Prompt Management</span>
            </TooltipCustom>
          }
          component={Link}
          to="/chatgptutility/aistudytools/promptmgt"
        />
      );
    }
    if (permissionsList.includes('mcq_can_view') || permissionsList.includes('mcq_can_edit')) {
      renderAuditTab.push(
        <Tab
          label={
            <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.SUMMARY_QUIZ_MANANGEMENT')} />}>
              <span>Content Management</span>
            </TooltipCustom>
          }
          component={Link}
          to="/chatgptutility/aistudytools/titlesearch"
        />
      );
    }
    const locationPath = ['/chatgptutility', '/chatgptutility/'];
    return (
      <>
        <Header />
        <main className={classes.contentWrapper} id="mainContent" role="main">
          {!history.location.pathname.includes('/chatgptutility/aistudytools/mcq') &&
            !history.location.pathname.includes('/chatgptutility/aistudytools/summary') &&
            // !history.location.pathname.includes('/chatgptutility/aistudytools/titlesearch') &&
            !history.location.pathname.includes('/chatgptutility/admin') &&
            !locationPath.includes(history.location.pathname) && (
              <Stack direction="row" className={classes.buttonStyle} alignItems="center">
                <TooltipCustom title={<FormattedMessage {...language.getText('chatUtility.HOME')} />}>
                  <IconButton size="large" color="primary" aria-label="HOME" onClick={() => this.handleChange()}>
                    <HomeIcon sx={{ fontSize: '30px' }} />
                  </IconButton>
                </TooltipCustom>
                {/* {history.location.pathname.includes('/chatgptutility/aistudytools/titlesearch') && (
                  <>
                    <LinkMenu
                      paths={paths}
                      separator="/"
                      onClick={this.handleBreadcrumbClick}
                      styles={breadcrumbStyles}
                      activeStyle={activeBreadcrumbStyles}
                    />
                  </>
                )} */}
              </Stack>
            )}
          <Container maxWidth="xl">
            {!history.location.pathname.includes('/chatgptutility/aistudytools/mcq') &&
              !history.location.pathname.includes('/chatgptutility/aistudytools/summary') &&
              // !history.location.pathname.includes('/chatgptutility/aistudytools/titlesearch') &&
              !history.location.pathname.includes('/chatgptutility/admin') &&
              !history.location.pathname.includes('/chatgptutility/users') &&
              !history.location.pathname.includes('/chatgptutility/roles') &&
              !history.location.pathname.includes('chatgptutility/tenants') &&
              !history.location.pathname.includes('/chatgptutility/popularpage') &&
              !history.location.pathname.includes('/chatgptutility/pvsassessment') &&
              !history.location.pathname.includes('/chatgptutility/chatbotconfig') &&
              !history.location.pathname.includes('/chatgptutility/aitrendingtopics') &&
              !locationPath.includes(history.location.pathname) && (
                <>
                  <Paper elevation={3}>
                    <AppBar position="static" color="default">
                      <Tabs
                        // value={0}
                        value={tabValue}
                        onChange={(e, data) => this.handleTabChange(e, data)}
                        indicatorColor="primary"
                        textColor="primary"
                        variant="fullWidth"
                        aria-label="Workflow Status Tabs"
                      >
                        {
                          /* eslint-disable */
                          history.location.pathname.includes('/chatgptutility/channels')
                            ? renderChannelsTab
                            : history.location.pathname.includes('/chatgptutility/aistudytools')
                            ? renderAuditTab
                            : renderTenantWiseTab()
                          /* eslint-disable */
                        }
                      </Tabs>
                    </AppBar>
                  </Paper>
                  <br />
                </>
              )}
            <Switch>
              <Route path="/chatgptutility/users" exact render={() => <ChatutilityUsersContainer />} />

              <Route path="/chatgptutility/roles" exact render={() => <ChatutilityRolesContainer />} />

              <Route path="/chatgptutility/aistudytools" exact render={() => <ChatGptUtility />} />

              <Route path="/chatgptutility/aistudytools/feedback" exact render={() => <ChatGptUtilityFeeback />} />

              <Route path="/chatgptutility/aistudytools/trends" exact render={() => <ChatGptUtilityTrends />} />

              <Route path="/chatgptutility/aistudytools/trends/charts" exact render={() => <ChatGptUtilityTrends />} />

              <Route
                path="/chatgptutility/aistudytools/trends/clustering"
                exact
                render={() => <ChatGptUtilityTrends />}
              />

              <Route
                path="/chatgptutility/aistudytools/mcq/view/:bookId"
                exact
                render={() => <ChatDataToc {...this.props} viewType="quiz" />}
              />
              <Route
                path="/chatgptutility/aistudytools/mcq/add/:bookId"
                exact
                render={() => <ChatDataToc {...this.props} viewType="quiz" />}
              />
              <Route
                path="/chatgptutility/aistudytools/mcq/edit/:bookId"
                exact
                render={() => <ChatDataToc {...this.props} viewviewType="quiz" />}
              />
              <Route path="/chatgptutility/aistudytools/mcq" exact render={() => <ChatDataToc viewType="quiz" />} />
              {/* <Route path="/chatgptutility/mcq/edit" exact render={() => <McqEditQuestionDialog />} />
              <Route path="/chatgptutility/mcq/add" exact render={() => <TocAddDialog />} /> */}
              <Route
                path="/chatgptutility/aistudytools/summary/view/:bookId"
                exact
                render={() => <ChatDataToc {...this.props} viewType="summary" />}
              />
              <Route
                path="/chatgptutility/aistudytools/summary/add/:bookId"
                exact
                render={() => <ChatDataToc {...this.props} viewType="summary" />}
              />
              <Route
                path="/chatgptutility/aistudytools/summary/edit/:bookId"
                exact
                render={() => <ChatDataToc {...this.props} viewType="summary" />}
              />
              <Route
                path="/chatgptutility/aistudytools/summary"
                exact
                render={() => <ChatDataToc viewType="summary" />}
              />

              <Route
                path="/chatgptutility/aistudytools/trends/unanswered"
                exact
                render={() => <ChatGptUtilityTrends />}
              />
              <Route path="/chatgptutility/aistudytools/titlesearch" exact render={() => <McqSearch />} />
              <Route path="/chatgptutility" exact render={() => <TenantList resetTab={this.handleResetTabValue} />} />
              <Route path="/chatgptutility/channels" exact render={() => <ChannelsComponent />} />
              <Route path="/chatgptutility/channels/feedback" exact render={() => <ChannelsFeedback />} />
              <Route path="/chatgptutility/channels/trends" exact render={() => <ChannelsTrends />} />
              <Route path="/chatgptutility/admin" exact render={() => <AdminView />} />
              {/* <Route path="/chatgptutility/aistudytools/reports" exact render={() => <ReportsComponent />} />
              <Route path="/chatgptutility/channels/reports" exact render={() => <ReportsComponent />} /> */}              
              <Route path="/chatgptutility/aistudytools/promptmgt" exact render={() => <AdminPromptView />} />
              <Route
                path="/chatgptutility/aistudytools/promptmgt/effectiveness"
                exact
                render={() => <EffectivenessMainComponent />}
              />
              <Route path="/chatgptutility/channels/promptmgt" exact render={() => <AdminPromptView />} />
              <Route path="/chatgptutility/tenants" exact render={() => <TenantListView />} />
              <Route path="/chatgptutility/popularpage" exact render={() => <PopularPageComponent />} />
              <Route path="/chatgptutility/pvsassessment" exact render={() => <PVSAssesment />} />
              <Route path="/chatgptutility/chatbotconfig" exact render={() => <ChatBotConfig />} />
              <Route
                path="/chatgptutility/pvsassessment/selectlesson"
                exact
                render={() => <SelectLesson {...this.props} />}
              />
              <Route
                path="/chatgptutility/pvsassessment/selectlesson/chapter"
                exact
                render={() => <SelectedChapter {...this.props} />}
              />
              <Route
                path="/chatgptutility/aitrendingtopics"
                exact
                render={() => <TrendingTopicsView {...this.props} />}
              />
              <Route
                path="/chatgptutility/*"
                render={() => <DynamicComponent {...this.props} selectedTabValue={tabValue} />}
              />
            </Switch>
          </Container>
        </main>
      </>
    );
  }
}

export default withStyles(styles)(ChatGptUtilityContainer);

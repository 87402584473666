import MomentUtils from '@date-io/moment';
import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import PanToolAltIcon from '@mui/icons-material/PanToolAlt';
import axios from 'axios';
import * as FormData from 'form-data';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../common/Loader';
import TableComponent from '../../../../common/components/Table';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import UsersPermissions from '../../../users/models/UsersPermissions';
import EffectivenessData from '../../models/StudyToolEffectiveness';
import EffectivenessDialogComponent from './EffectivenessDialogComponent';
import InputFileUploadComponent from './InputFileUploadComponent';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = (theme) => ({
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  datePicker: {
    marginTop: 'inherit'
  },
  marginBox: {
    border: '1px solid #322c2c2e',
    width: '52%',
    padding: '2px 15px 2px 0px',
    borderRadius: '4px',
    color: '#fff',
    height: '41px'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '150px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: '14px !important'
  },
  tableBookWidth: {
    width: '150px'
  },
  tableSummaryWidth: {
    width: '150px'
  },
  centerAlign: {
    textAlign: 'center'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  },
  downloadLink: {
    width: '70%',
    textAlign: 'end',
    paddingTop: '5px',
    paddingBottom: '5px',
    paddingRight: '18px',
    fontSize: '14px'
  },
  downloadBtn: {
    background: 'none',
    border: 'none',
    color: 'blue',
    textDecoration: 'underline',
    cursor: 'pointer',
    padding: '0',
    font: 'inherit'
  },
  fileUploadMessage: {
    width: '80%',
    textAlign: 'end',
    padding: '10px',
    fontSize: '14px',
    color: 'green',
    fontWeight: '600'
  }
});

/* eslint-disable */
const EffectivenessMainComponent = ({
  effectivenessData,
  EffectivenessMetaDataStatus,
  EffectivenessDataStatus,
  classes,
  userPermissions,
  language
}) => {
  const history = useHistory();
  const [effectivenessDataValue, setEffectivenessData] = useState([]);
  const [searchFields, setSearchFields] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [effectivenessRecords, setEffectivenessRecords] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dataValue, setDataValue] = useState({});
  const [modalIndex, setModalIndex] = useState(0);
  const permissionsList = toJS(userPermissions.permissionList.permissions);
  const [effectivenessColumnConfig, setEffectivenessColumnConfig] = useState(ColumnConfig.EffectivenessDataGridColumn);
  const [listOfUploadedFile, setListOfUploadedFile] = useState('');
  const [showUploadMessage, setShowUploadMessage] = useState(false);
  const [displayFileName, setDisplayFileName] = useState('');

  const convertDateHandler = (effectivenessData) => {
    if (effectivenessData) {
      const arrRecord = effectivenessData;
      arrRecord.forEach((item) => {
        const metaDataObj = item.metadata;
        const splitDate = metaDataObj.dateExecuted?.split('-');
        const convertDate = `${splitDate[1]}-${splitDate[0]}-${splitDate[2]}`;
        item.metadata = { ...metaDataObj, dateExecuted: convertDate };
      });
      return arrRecord;
    }
    return effectivenessData;
  };

  useEffect(() => {
    effectivenessData.fetchMetadata();
    Framework.getEventManager().on(constants.SET_STUDYTOOL_EFFECTIVENESS_METADATA, () => {
      const result = convertDateHandler(toJS(effectivenessData.data));
      setEffectivenessData(result);
    });
  }, []);

  const handleUploadFile = (event) => {
    const fileList = event?.target?.files;
    if (fileList?.length > 0) {
      const formData = new FormData();
      formData.append('file', fileList[0]);
      setListOfUploadedFile(formData);
      setShowUploadMessage(true);
    }
    event.preventDefault();
  };

  const handleBackClick = () => {
    setShowResults(false);
  };

  const handleInitiate = () => {
    if (listOfUploadedFile) {
      effectivenessData.initiate(listOfUploadedFile);
      setListOfUploadedFile('');
      setShowUploadMessage(false);
    }
  };

  const format = (records) =>
    records.map((record) => ({
      input: record.input,
      output: record.output.toString().replace(/\\r\\n/g, '<br >'),
      prompt: record.prompt,
      requestId: record.requestId,
      feedbacks: record.feedbacks
    }));

  const handleShowResults = (data) => {
    const formattedData = format(data.records);
    setEffectivenessRecords(formattedData);
    const fileName = data?.metadata?.s3FilePath?.split('/');
    setDisplayFileName(fileName?.length > 0 ? fileName[fileName?.length - 1] : fileName);
    const isGPTResults = formattedData?.some((item) => {
      const feedbackArr = item.feedbacks;
      return feedbackArr.some((obj) => Object.keys(obj).some((val) => val.includes('gpt') && obj[val] !== null));
    });
    if (isGPTResults) {
      setEffectivenessColumnConfig(ColumnConfig.EffectivenessLatestDataGridColumn);
    }
    setShowResults(true);
  };

  const onRowClick = (row, index) => {
    setIsDialogOpen(true);
    setDataValue(effectivenessRecords[index]);
    setModalIndex(index);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
    // setShowResults(false);
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      setDataValue(effectivenessRecords[modalIndex - 1]);
      setModalIndex(modalIndex - 1);
    } else {
      setDataValue(effectivenessRecords[modalIndex + 1]);
      setModalIndex(modalIndex + 1);
    }
  };

  const handleDateChange = (date, name) => {
    const fieldChanged = {};
    fieldChanged[name] = moment(date).format(constants.YYYY_MM_DD_FORMAT);

    setSearchFields({ ...searchFields, ...fieldChanged });
  };

  const handleSearch = (date) => {
    date = moment(date).format(constants.DD_MM_YYYY_FORMAT);
    const payload = {
      dateExecuted: date
    };

    effectivenessData.fetch(payload);
    Framework.getEventManager().on(constants.SET_STUDYTOOL_EFFECTIVENESS_DATA, () => {
      const result = convertDateHandler(toJS(effectivenessData.data));
      setEffectivenessData(result);
    });
  };

  const handleTemplateDownload = () => {
    const csvData = [
      [
        'User Command',
        'Prompt',
        'Textbook Content',
        'Content Filtering',
        'Chat Intent',
        'AI Response',
        'Explain Standalone',
        'RequestId',
        'ServerDateTime',
        'User Id'
      ]
    ];

    const csvContent = csvData.map((row) => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', 'sample.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const handleDownload = async (filePath) => {
    const token = utils.getToken();
    const fileName = filePath.split('/').pop();
    if (fileName) {
      const headers = {
        Authorization: `Bearer ${token}`
      };
      const responseData = await axios
        .post(
          `${env.EVERGREEN_API_BASE_URL}/chatutility/effectiveness/s3download`,
          { fileName },
          {
            headers,
            responseType: 'json'
          }
        )
        .catch((error) => {
          const { response } = error;
          if (response) {
            const { status, data } = response;
            if (status === 500 && data && data.error) {
              const notification = {
                open: true,
                message: data.error.message,
                type: 'error'
              };
              Framework.getStoreRegistry().getStore('notificationState').set(notification);
            }
          } else if (error.message === 'timeout of 60000ms exceeded') {
            const notification = {
              open: true,
              message: error.message,
              type: 'error'
            };
            Framework.getStoreRegistry().getStore('notificationState').set(notification);
          }
        });
      if (responseData.data.status === 'success' && responseData.data.data) {
        const byteArray = new Uint8Array(responseData.data.data.data);
        const text = new TextDecoder().decode(byteArray);
        const blob = new Blob([text], { type: 'text/csv' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        const notification = {
          open: true,
          message: `${fileName} Downloaded Successfully`,
          type: 'success'
        };
        Framework.getStoreRegistry().getStore('notificationState').set(notification);
      }
    }
  };

  const handleLLMBack = () => {
    history.push('/chatgptutility/aistudytools/promptmgt');
  };

  return (
    <>
      <Paper>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ p: 1 }}>
            <IconButton edge="start" color="default" onClick={() => handleLLMBack()} aria-label="back">
              <ArrowBackIosIcon />
            </IconButton>
            <Typography variant="h5" align="center" gutterBottom spacing={9}>
              LLM Evaluation
            </Typography>
          </Box>
          <Box sx={{ p: 1 }}>
            <Typography variant="h6" align="center" gutterBottom spacing={9}>
              {constants.CHAT_CRITERIA_TEXT}
            </Typography>
          </Box>
          <div className={classes.downloadLink}>
            <button onClick={handleTemplateDownload} className={classes.downloadBtn}>
              {constants.DOWNLOAD_TEMPLATE}
            </button>
          </div>
          <Grid container direction="row">
            <Grid item xs={1} />
            <Grid item xs={10}>
              <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center', p: '0px 33px' }}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                  <ThemeProvider theme={materialTheme}>
                    <KeyboardDatePicker
                      className={classes.datePicker}
                      format={constants.MM_DD_YYYY_FORMAT}
                      autoOk
                      size="small"
                      variant="inline"
                      style={{ minWidth: '32%', justifyContent: 'center' }}
                      inputVariant="outlined"
                      disableFuture
                      value={searchFields.lastExecutedDate || null}
                      label="Last Executed Date"
                      onChange={(date) => handleDateChange(date, 'lastExecutedDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date'
                      }}
                      InputLabelProps={{
                        style: { padding: '6px' }
                      }}
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                <ThemeProvider theme={themes}>
                  <Button
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={() => handleSearch(searchFields.lastExecutedDate)}
                    className={classes.buttonStyle}
                  >
                    {constants.CHAT_SEARCH}
                  </Button>
                  {permissionsList.includes('admin_can_view') && (
                    <InputFileUploadComponent handleUploadFile={handleUploadFile} />
                  )}
                  <TooltipCustom
                    title={
                      !permissionsList.includes('mcq_can_edit') ? (
                        <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                      ) : (
                        <FormattedMessage {...language.getText('effectiveness.INITIATE_NEW')} />
                      )
                    }
                  >
                    <Button
                      variant="contained"
                      size="small"
                      color="primary"
                      onClick={handleInitiate}
                      className={classes.buttonStyle}
                      disabled={
                        !permissionsList.includes('admin_can_view') ||
                        (permissionsList.includes('admin_can_view') && !listOfUploadedFile)
                      }
                    >
                      {constants.INITIATE_NEW}
                    </Button>
                  </TooltipCustom>
                </ThemeProvider>
              </Box>
            </Grid>
            <Grid item xs={1} />
            <br />
          </Grid>
          {showUploadMessage && (
            <div className={classes.fileUploadMessage}>
              File Uploaded Successfully. Now Click on Initiate New.
              <span style={{ position: 'absolute' }}>
                <PanToolAltIcon />
              </span>
            </div>
          )}
          <br />
        </Box>
      </Paper>
      <br />
      <LoadingHandler
        loading={EffectivenessMetaDataStatus.isPending || EffectivenessDataStatus.isPending}
        loadingContent={<Loader />}
        content={
          !showResults && (
            <>
              <TableContainer component={Paper} className={classes.root}>
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell align="center" className={classes.tableBookWidth}>
                        <Typography className={classes.columnStyle} variant="h6">
                          {constants.DATE_TIME_GMT}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.tableBookWidth}>
                        <Typography className={classes.columnStyle} variant="h6">
                          {constants.S3_PATH}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.tableBookWidth}>
                        <Typography className={classes.columnStyle} variant="h6">
                          {constants.EXECUTED_BY}
                        </Typography>
                      </TableCell>
                      <TableCell align="center" className={classes.tableBookWidth}>
                        <Typography className={classes.columnStyle} variant="h6">
                          {constants.SHOW_RESULTS}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {effectivenessDataValue &&
                      Array.isArray(effectivenessDataValue) &&
                      effectivenessDataValue.length > 0 &&
                      effectivenessDataValue.map((data) => {
                        return (
                          <>
                            <TableRow>
                              <TableCell>
                                <Typography className={classes.tableText}>{data.metadata.dateExecuted}</Typography>
                              </TableCell>
                              <TableCell className={classes.centerAlign}>
                                <button
                                  onClick={() => handleDownload(data.metadata.s3FilePath)}
                                  className={classes.downloadBtn}
                                >
                                  {data?.metadata?.s3FilePath?.split('/').pop()}
                                </button>
                              </TableCell>
                              <TableCell>
                                <Typography className={classes.tableText}>{data.metadata.executedBy}</Typography>
                              </TableCell>
                              <TableCell className={classes.centerAlign}>
                                <Button
                                  variant="outlined"
                                  size="small"
                                  color="primary"
                                  onClick={() => handleShowResults(data)}
                                  disabled={data.disable}
                                  className={classes.buttonStyle}
                                >
                                  {constants.SHOW_RESULTS}
                                </Button>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })}
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          )
        }
      />
      {showResults && (
        <>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton onClick={() => handleBackClick()}>
              <ArrowBackIosIcon />
            </IconButton>
            <Typography>{displayFileName}</Typography>
          </Box>
          <TableComponent
            columns={effectivenessColumnConfig}
            data={effectivenessRecords}
            onColumnClick={() => {}}
            order=""
            onRowClick={onRowClick}
            selectedValue=""
            directionValue="ASC"
            userEmailDetails=""
            onMouseEnter={() => {}}
            isFromChannelsTrends
          />
        </>
      )}
      {isDialogOpen && (
        <EffectivenessDialogComponent
          dataValue={dataValue}
          openChat={isDialogOpen}
          dataLength={effectivenessRecords.length}
          handleClose={handleClose}
          handleModalChange={handleModalChange}
          modalIndex={modalIndex}
        />
      )}
    </>
  );
};

EffectivenessMainComponent.propTypes = {
  effectivenessData: shapes.modelOf(EffectivenessData).isRequired,
  classes: PropTypes.object.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired,
  language: shapes.language.isRequired
};

export default withStyles(styles)(
  inject(
    'effectivenessData',
    'EffectivenessDataStatus',
    'EffectivenessMetaDataStatus',
    'userPermissions',
    'language'
  )(observer(EffectivenessMainComponent))
);

import Framework, { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { Checkbox, FormControlLabel, FormGroup, Grid, Paper, styled } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { inject, observer } from 'mobx-react';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Loader from '../../../../common/Loader';
import * as constants from '../../../../common/constants';
import SubTypeResponse from '../../models/SubTypeModel';
import utils from '../../../../common/utils';
import MathJaxWrapper from '../../common/components/MathJaxWrapper';
// import { useEffect } from 'react';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    minHeight: '60vh',
    maxWidth: '80%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  aiStudyToolcard: {
    height: '435px',
    overflowY: 'auto !important'
  },
  inCreaseAiStudyToolcard: {
    overflowY: 'auto !important',
    height: '585px'
  },
  promptCard: {
    overflowY: 'auto !important',
    maxHeight: '235px',
    minHeight: '235px'
  },
  minimizedPromptCard: {
    overflowY: 'auto !important',
    height: '130px'
  },
  chatIntentCard: {
    overflowY: 'auto !important',
    height: '200px'
  },
  subTypeModel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  }
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const ChatDialogComponent = ({
  classes,
  openChat,
  dataValue,
  handleClose,
  userTocDetails,
  dataLength,
  filterPromt,
  modalIndex,
  handleModalChange,
  chatgptUtilityResponseStatus,
  isFeedback,
  loaderStatusResponse,
  ChatUtilitySubTypeResponse,
  chatgptUtilitySubTypeResponseStatus,
  problemSolveFlow
  // isTrendsInsightsList
}) => {
  const { SubTypeData } = ChatUtilitySubTypeResponse;
  const [checkboxSelectedValue, setCheckboxSelectedValue] = useState({});
  const [checkRectifierData, setCheckRecitifierData] = useState({});
  const [problemSolveFinalResponse, setProblemSolveFinalResponse] = useState({});
  // const [checkboxDisable, setCheckboxDisable] = useState(false);
  const [checked, setChecked] = useState({
    aiStudyTools: false,
    problemSolveFinalRes: false,
    problemSolveRawRectifier: false
  });
  const [aiStudyRawResponse, setAIStudyRawResponse] = useState('');

  useEffect(() => {
    if (Object.keys(checkboxSelectedValue).length === 0) {
      ChatUtilitySubTypeResponse.resetStoreValues();
    }
  }, [chatgptUtilityResponseStatus, checkboxSelectedValue, dataValue, chatgptUtilitySubTypeResponseStatus]);

  useEffect(() => {
    if (!_.isEmpty(dataValue) && !chatgptUtilitySubTypeResponseStatus.isPending) {
      const { requestId, bookId, type } = dataValue;
      if (type && type === constants.PROBLEM_SOLVE_RECTIFIER) {
        ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE_VERIFIER });
        ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE });
      }
      if (type && type === constants.GENERATE_PYTHON) {
        ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE_FINAL_RESPONSE });
      }
      if (type && type === constants.PROBLEM_SOLVE && problemSolveFlow) {
        if (problemSolveFlow === constants.VERIFIER_FLOW) {
          ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE_VERIFIER });
          Framework.getEventManager().on(constants.SET_PROBLEM_SOLVE_DATA, () => {
            if (SubTypeData.length > 0) {
              const findValue = SubTypeData.find(
                (data) =>
                  data.bookId === bookId &&
                  data.requestId === requestId &&
                  data.type === constants.PROBLEM_SOLVE_VERIFIER
              );
              if (findValue) {
                setCheckRecitifierData(findValue);
              }
            }
          });
        }
        if (problemSolveFlow === constants.GENERATE_PYTHON_FLOW) {
          ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.GENERATE_PYTHON });
          ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE_FINAL_RESPONSE });
          Framework.getEventManager().on(constants.SET_PROBLEM_SOLVE_FINAL_RESPONSE, () => {
            if (SubTypeData.length > 0) {
              const findValue = SubTypeData.find(
                (data) =>
                  data.bookId === bookId &&
                  data.requestId === requestId &&
                  data.type === constants.PROBLEM_SOLVE_FINAL_RESPONSE
              );
              if (findValue) {
                setProblemSolveFinalResponse(findValue);
              }
            }
          });
        }
      }
    }
  }, [dataValue, chatgptUtilitySubTypeResponseStatus]);

  useEffect(() => {
    if (!_.isEmpty(checkRectifierData) && checkRectifierData.botResponse === 'False') {
      const { requestId, bookId } = dataValue;
      ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: constants.PROBLEM_SOLVE_RECTIFIER });
    }
  }, [checkRectifierData]);

  const setRawSummaryResponse = () => {
    const summaryRawResponse = Object.keys(dataValue.botResponse).map((val) => {
      return (
        <>
          {dataValue.botResponse[val] &&
            dataValue.botResponse[val].map((arr, index) => (
              <>
                <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                  {arr.toString()}
                </Typography>
              </>
            ))}
          <br />
        </>
      );
    });
    setAIStudyRawResponse(summaryRawResponse);
  };

  const setCommonRawAuditResponse = () => {
    const returnData =
      dataValue.botResponse &&
      Object.keys(dataValue.botResponse).length > 0 &&
      Object.values(dataValue.botResponse).map((item) => {
        if (Array.isArray(item) && item.length > 0) {
          return item.map((subItem, index) => {
            return (
              <>
                <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                  {subItem}
                </Typography>
              </>
            );
          });
        }
        return '';
      });
    setAIStudyRawResponse(returnData);
  };

  useEffect(() => {
    if (!_.isEmpty(dataValue.botResponse)) {
      if (checked.aiStudyTools) {
        switch (dataValue.type) {
          case constants.SUMMARY_TYPE:
            setRawSummaryResponse();
            break;
          case constants.SUMMARY_EXPLAIN:
            setAIStudyRawResponse(dataValue?.botResponse?.summaryExplainBotResponse?.toString());
            break;
          case constants.NON_MATH_MCQ_VALIDATION:
          case constants.MATH_MCQ_CORE_CONCEPTS:
          case constants.MATH_MCQ_QUESTION_GENERATION:
          case constants.MATH_MCQ:
          case constants.SUMMARY_GPT:
          case constants.PHYSICS_MCQ:
          case constants.PHYSICS_MCQ_QUESTION_GENERATION:
          case constants.GENERATE_PYTHON:
          case constants.PROBLEM_SOLVE_FINAL_RESPONSE:
            setCommonRawAuditResponse();
            break;
          default:
            setAIStudyRawResponse(dataValue.botResponse.toString());
        }
      } else {
        setAIStudyRawResponse('');
      }
    }
  }, [checked]);

  const handleNavigationChange = (nav) => {
    handleModalChange(nav);
    setCheckboxSelectedValue({});
    setChecked({ ...checked, aiStudyTools: false, problemSolveFinalRes: false, problemSolveRawRectifier: false });
    ChatUtilitySubTypeResponse.resetStoreValues();
  };
  const handleSelectedValue = (event) => {
    let sourceType = '';
    const { requestId, bookId } = dataValue;
    if (event.target.checked) {
      sourceType = event.target.value;
    } else {
      // setCheckboxSelectedValue({});
      sourceType = dataValue.mainType;
    }
    const { value } = event.target;
    if (value === 'chat_intent') {
      setCheckboxSelectedValue({ ...checkboxSelectedValue, chat_intent: !checkboxSelectedValue.chat_intent });
    } else if (value === 'content_filter') {
      setCheckboxSelectedValue({ ...checkboxSelectedValue, content_filter: !checkboxSelectedValue.content_filter });
    } else if (value === constants.GENERATE_PYTHON) {
      setCheckboxSelectedValue({ ...checkboxSelectedValue, GENERATE_PYTHON: !checkboxSelectedValue.GENERATE_PYTHON });
    } else {
      setCheckboxSelectedValue({
        ...checkboxSelectedValue,
        explain_stand_alone: !checkboxSelectedValue.explain_stand_alone
      });
    }
    if (sourceType && dataValue.type !== constants.GENERATE_PYTHON) {
      if (SubTypeData.length > 0) {
        const findValue = SubTypeData.find((data) => data.requestId === requestId && data.type === sourceType);
        if (!findValue) {
          ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: sourceType });
        }
      } else {
        ChatUtilitySubTypeResponse.fetch({ requestId, bookId, type: sourceType });
      }
    }
  };
  const handleAlterIndex = (index) => {
    let defIndex = '';
    switch (index) {
      case 0:
        defIndex = ' a) ';
        break;
      case 1:
        defIndex = ' b) ';
        break;
      case 2:
        defIndex = ' c) ';
        break;
      case 3:
        defIndex = ' d) ';
        break;
      default:
    }
    return defIndex;
  };

  const quizObjectIteration = (val) => {
    return Object.keys(val).length > 0 && typeof val !== 'string' ? (
      <>
        <Typography variant="body2" color="text.secondary" textAlign="left">
          {`Question : ${val && val.question}`}
          <br />
          <br />
          {val.options &&
            val.options.map((values, index) => {
              const defIndex = handleAlterIndex(index);
              if (
                values.option.startsWith('a)') ||
                values.option.startsWith('b)') ||
                values.option.startsWith('c)') ||
                values.option.startsWith('d)')
              ) {
                // return `${defIndex})${values.option.slice(2)}`;
                return (
                  <Typography variant="body2" color="text.secondary" textAlign="left">
                    {defIndex} {values.option.slice(2)}
                  </Typography>
                );
              }
              return (
                <Typography variant="body2" color="text.secondary" textAlign="left">
                  {defIndex} {values.option} {values.correct_choice === 'Yes' ? '(Correct)' : ''}
                </Typography>
              );
            })}
          <br />
          {val && val.answer && val.answer.length > 0 && (
            <Typography variant="body2" color="text.secondary" textAlign="left">
              {val.answer}
            </Typography>
          )}
          {val &&
            val.feedback &&
            val.feedback.length !== 0 &&
            val.feedback.map((item, index) => {
              const defIndex = handleAlterIndex(index);
              return (
                <Typography variant="body2" color="text.secondary" textAlign="left">
                  Feedback for option
                  {defIndex} {item}
                </Typography>
              );
            })}
        </Typography>
      </>
    ) : (
      <Typography>{val}</Typography>
    );
  };

  const renderResponsePrompts = () => {
    if (dataValue && dataValue.prompts && dataValue.prompts.length > 0) {
      return dataValue.prompts.map((data) => {
        return (
          <Typography
            variant="body2"
            color="text.secondary"
            align="left"
          >{`${data.name} Version = ${data.version} (${data.status})`}</Typography>
        );
      });
    }
    return <></>;
  };

  const renderSubTypeResponsePrompts = (param) => {
    if (param && param.prompts && param.prompts.length > 0) {
      return param.prompts.map((data) => {
        return (
          <Typography
            variant="body2"
            color="text.secondary"
            align="left"
          >{`${data.name} Version = ${data.version} (${data.status})`}</Typography>
        );
      });
    }
    return <></>;
  };

  const renderMathJaxResponse = (response) => {
    const regesXmlns = /<math([^>]*)>/g;
    const res = utils.containsMathML(response)
      ? response.replace(regesXmlns, '<math xmlns="http://www.w3.org/1998/Math/MathML">')
      : response;
    return (
      <MathJaxWrapper>
        {/* eslint-disable */}
        <span dangerouslySetInnerHTML={{ __html: res }} />
        {/* eslint-disable */}
      </MathJaxWrapper>
    );
  };

  const checkMathJax = (mathJaxData) => {
    let response = mathJaxData;
    if (!isFeedback && !_.isEmpty(mathJaxData)) {
      if (utils.containsMarkDownText(mathJaxData)) {
        response = utils.convertToMarkedDownString(mathJaxData);
      }
      if (response.includes('`') || response.includes('</math>') || response.includes('\\'))
        return renderMathJaxResponse(response);
    }
    if (isFeedback && !_.isEmpty(response)) {
      if (typeof response === 'object') {
        return (
          Object.keys(response).length > 0 &&
          Object.keys(response).map((val) => {
            return (
              <>
                {response[val] &&
                  Array.isArray(response[val]) &&
                  response[val].length > 0 &&
                  response[val].map((arr, index) => (
                    <>
                      <Typography variant="h6">{`Bot Response ${index + 1}`}</Typography>
                      <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                        <>
                          {/* eslint-disable-next-line */}
                          <span
                            ref={(el) => {
                              utils.processMath(el);
                            }}
                            dangerouslySetInnerHTML={{ __html: arr }}
                          />
                        </>
                      </Typography>
                    </>
                  ))}
              </>
            );
          })
        );
      }
      if (response.includes('`') || response.includes('</math>') || response.includes('\\')) {
        return renderMathJaxResponse(response);
      }
    }
    return (
      <>
        {/* eslint-disable-next-line */}
        <div dangerouslySetInnerHTML={{ __html: response }} />
      </>
    );
  };

  /* eslint-disable */
  const objectIteration = (item) => {
    if (item && Object.keys(item).length > 0 && !Array.isArray(item) && typeof item !== 'string') {
      return Object.entries(item).map(([key, value]) => {
        if (Array.isArray(value)) {
          return (
            <>
              {objectIteration(value)} <br />
            </>
          );
        }
        return (
          <Typography variant="body2" color="text.secondary" textAlign="left">
            {checkMathJax(`${key} : ${value} `)}
          </Typography>
        );
      });
    } else if (item && Array.isArray(item)) {
      return item.map((str) => {
        return (
          <>
            {objectIteration(str)}
            <br />
          </>
        );
      });
    }
    return (
      <Typography variant="body2" color="text.secondary" textAlign="left">
        {checkMathJax(item)}
      </Typography>
    );
  };

  const renderExpertDeskResponse = (data) => {
    if (data && data.flashcards && data.flashcards.length > 0) {
      const flashcardsArr = data.flashcards;
      return flashcardsArr.map((item) => {
        return (
          <>
            {item.type && (
              <Typography align="left" variant="subtitle1">
                type : {item.type}
              </Typography>
            )}
            {item.front_side && (
              <Typography align="left" variant="subtitle1">
                front_side : {item.front_side}
              </Typography>
            )}
            {item.back_side && (
              <Typography align="left" variant="subtitle1">
                back_side : {item.back_side}
              </Typography>
            )}
            {item.distractors && (
              <Typography align="left" variant="subtitle1">
                <Typography align="left" variant="subtitle1">
                  distractors :{' '}
                </Typography>
                <Typography align="left" variant="subtitle1">
                  {item.distractors.map((value) => (
                    <Typography align="left" variant="subtitle1">
                      {value}
                    </Typography>
                  ))}
                </Typography>
              </Typography>
            )}
            <br />
          </>
        );
      });
    }
    return <></>;
  };

  const handleToggleCheck = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const renderRawResponse = (paramVal) => {
    if (
      [
        constants.ANSWER_CYU_MCQ,
        constants.EXPLAIN_STAND_ALONE,
        constants.SALUTATION,
        constants.CONTENT_FILTER_TYPE,
        constants.CHAT_INTENT,
        constants.CHAT_ENRICHMENT,
        constants.CONTEXT_IDENTIFIER_USER_SELECTION,
        constants.CONTENT_CLASSIFIER,
        constants.DICTIONARY_SUMMARY,
        constants.DICTIONARY_QUIZ,
        constants.MCQ_MISCONCEPTIONS,
        constants.MCQ_TYPE,
        constants.EXPERTDESK_FREE_RESPONSE,
        constants.EXPERTDESK_MCQ,
        constants.FLASHCARDS,
        constants.MCQ_DISTRACT,
        constants.MCQ_SKILL_INDENTIFIER,
        constants.QUIZ_INTENT,
        constants.QUIZ_VALIDATE_INTENT,
        constants.SUMMARY_TOPIC,
        constants.TOPIC,
        constants.HISTORY_TITLE,
        constants.CONTEXT_IDENTIFIER_SUMMARY,
        constants.SPECIFIC_TOPIC_INTENT_IDENTIFIER,
        constants.SUMMARY_TOPIC_IDENTIFIER
      ].includes(dataValue.type)
    ) {
      return <></>;
    }
    if (paramVal === constants.PROBLEM_SOLVE_RECTIFIER_TEXT) {
      return (
        <FormGroup display="inline" spacing={0} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Switch
                name="problemSolveRawRectifier"
                checked={checked.problemSolveRawRectifier}
                onChange={handleToggleCheck}
              />
            }
            label="Raw Response"
            style={{ alignItems: 'center', padding: '0px' }}
          />
        </FormGroup>
      );
    } else if (paramVal === constants.AI_STUDY_TOOLS_RESPONSE) {
      return (
        <FormGroup display="inline" spacing={0} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormControlLabel
            control={<Switch name="aiStudyTools" checked={checked.aiStudyTools} onChange={handleToggleCheck} />}
            label="Raw Response"
            style={{ alignItems: 'center', padding: '0px' }}
          />
        </FormGroup>
      );
    } else if (paramVal === constants.PROBLEM_SOLVE_FINAL_AI_RESPONSE) {
      return (
        <FormGroup display="inline" spacing={0} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <FormControlLabel
            control={
              <Switch name="problemSolveFinalRes" checked={checked.problemSolveFinalRes} onChange={handleToggleCheck} />
            }
            label="Raw Response"
            style={{ alignItems: 'center', padding: '0px' }}
          />
        </FormGroup>
      );
    }
    return <></>;
  };

  return (
    <>
      {/* <LoadingHandler
        loading={chatgptUtilityResponseStatus.isPending}
        isError={
          chatgptUtilityResponseStatus.isError &&
          chatgptUtilityResponseStatus.error &&
          chatgptUtilityResponseStatus.error.status === 401 &&
          chatgptUtilityResponseStatus.error.status === 500
        }
        content={ */}
      <Dialog fullScreen open={openChat} onClose={handleClose}>
        <Box sx={{ p: 2 }}>
          <Paper elevation={0}>
            <Grid container spacing={0} padding={0}>
              <Grid item xs={5}>
                <Typography align="left" variant="subtitle1">
                  {constants.CHAT_USERLOGIN}: {dataValue.userId}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography align="left" display="inline">
                  <Stack
                    display="inline"
                    spacing={0}
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                  >
                    <Button disabled={modalIndex === 0} onClick={() => handleNavigationChange('prev')} size="large">
                      <ChevronLeftIcon />
                    </Button>
                    <span color="secondary">{modalIndex + 1}</span> /<span color="secondary"> {dataLength}</span>
                    <Button disabled={modalIndex + 1 === dataLength} onClick={() => handleNavigationChange('next')}>
                      <ChevronRightIcon />
                    </Button>
                    {dataValue && dataValue.type && (
                      <Typography>
                        {constants.TYPE} :{' '}
                        {dataValue.type === 'answer' || dataValue.type === 'discuss'
                          ? 'Explain'
                          : dataValue.type.charAt(0).toUpperCase() + dataValue.type.slice(1)}
                        {/* {SubTypeData.type &&
                          (dataValue.mainType === 'answer'
                            ? 'Explain'
                            : dataValue.mainType.charAt(0).toUpperCase() + dataValue.mainType.slice(1))} */}
                      </Typography>
                    )}
                  </Stack>
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography align="right" variant="subtitle1">
                  {Object.keys(dataValue).length > 0 ? (
                    <Typography display="inline" variant="subtitle2">
                      {' '}
                      <span>
                        {/* {isTrendsInsightsList
                          ? dataValue && dataValue.dateStamp
                          : dataValue && dataValue.serverDateTime}
                        {!isTrendsInsightsList && ' (GMT) '} */}
                        {`${dataValue && dataValue.serverDateTime}  (GMT) `}
                      </span>
                      <Box display="inline" sx={{ pl: 4 }}>
                        <IconButton edge="start" color="default" onClick={() => handleClose()} aria-label="close">
                          <Tooltip title="Close">
                            <CloseIcon />
                          </Tooltip>
                        </IconButton>
                      </Box>
                    </Typography>
                  ) : (
                    <span> </span>
                  )}
                </Typography>
              </Grid>
            </Grid>
            {!isFeedback && dataValue.type === constants.GENERATE_PYTHON && (
              <Grid container spacing={0} padding={0} className={classes.subTypeModel}>
                <Stack sx={{ alignItems: 'center' }}>
                  <FormGroup
                    display="inline"
                    spacing={0}
                    style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkboxSelectedValue && checkboxSelectedValue.GENERATE_PYTHON === true}
                          color="primary"
                          value={constants.GENERATE_PYTHON}
                          onChange={(e) => handleSelectedValue(e)}
                        />
                      }
                      label="Phython Code"
                      style={{ alignItems: 'center', padding: '0px' }}
                    />
                  </FormGroup>
                </Stack>
              </Grid>
            )}
            {!isFeedback &&
              (dataValue.type === 'answer' ||
                dataValue.type === constants.PROBLEM_SOLVE ||
                dataValue.type === constants.PROBLEM_SOLVE_RECTIFIER) && (
                <Grid container spacing={0} padding={0} className={classes.subTypeModel}>
                  <Stack sx={{ alignItems: 'center' }}>
                    <FormGroup
                      display="inline"
                      spacing={0}
                      style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                    >
                      {dataValue.type === 'answer' && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxSelectedValue && checkboxSelectedValue.chat_intent === true}
                              color="primary"
                              value="chat_intent"
                              onChange={(e) => handleSelectedValue(e)}
                              // disabled={
                              //   checkboxSelectedValue &&
                              //   checkboxSelectedValue.chat_intent === true &&
                              //   (chatgptUtilitySubTypeResponseStatus.error ||
                              //     chatgptUtilitySubTypeResponseStatus.isError)
                              // }
                            />
                          }
                          label="Include Chat Intent"
                          style={{ alignItems: 'center', padding: '0px' }}
                        />
                      )}
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checkboxSelectedValue && checkboxSelectedValue.content_filter === true}
                            color="primary"
                            value="content_filter"
                            onChange={(e) => handleSelectedValue(e)}
                            // disabled={
                            //   checkboxSelectedValue.content_filter === true &&
                            //   (chatgptUtilitySubTypeResponseStatus.error ||
                            //     chatgptUtilitySubTypeResponseStatus.isError)
                            // }
                          />
                        }
                        label="Include Content Filter"
                        style={{ alignItems: 'center', padding: '0px' }}
                      />
                      {dataValue.type === 'answer' && (
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={checkboxSelectedValue && checkboxSelectedValue.explain_stand_alone === true}
                              color="primary"
                              value="explain_stand_alone"
                              onChange={(e) => handleSelectedValue(e)}
                              // disabled={
                              //   checkboxSelectedValue.content_filter === true &&
                              //   (chatgptUtilitySubTypeResponseStatus.error ||
                              //     chatgptUtilitySubTypeResponseStatus.isError)
                              // }
                            />
                          }
                          label="Include Explain Standalone"
                          style={{ alignItems: 'center', padding: '0px' }}
                        />
                      )}
                    </FormGroup>
                  </Stack>
                </Grid>
              )}
          </Paper>
        </Box>
        <Box sx={{ p: 2, paddingTop: '0px' }}>
          <Box>
            <LoadingHandler
              loading={chatgptUtilityResponseStatus.isPending || chatgptUtilitySubTypeResponseStatus.isPending}
              isError={
                (chatgptUtilityResponseStatus.isError &&
                  chatgptUtilityResponseStatus.error &&
                  chatgptUtilityResponseStatus.error.status === 401 &&
                  chatgptUtilityResponseStatus.error.status === 500) ||
                (chatgptUtilitySubTypeResponseStatus.isError &&
                  chatgptUtilitySubTypeResponseStatus.error &&
                  chatgptUtilitySubTypeResponseStatus.error.status === 401 &&
                  chatgptUtilitySubTypeResponseStatus.error.status === 500)
              }
              content={<></>}
              loadingContent={<Loader loaderStatusResponse={loaderStatusResponse} />}
              errorContent={
                <StateErrorDisplay
                  error={chatgptUtilityResponseStatus.error || chatgptUtilitySubTypeResponseStatus.error}
                  showDetails
                />
              }
            />
          </Box>
          <Grid container spacing={2} style={{ minWidth: 800 }}>
            <Grid item xs={6}>
              <Grid item xs={12}>
                {!isFeedback ? (
                  <Item>
                    <Card style={{ overflowY: 'auto', maxHeight: '145px', minHeight: '145px' }}>
                      <CardHeader title="User Input/Command" style={{ background: '#34282808' }} align="left" />
                      <CardContent>
                        <Typography variant="body2" color="text.primary" textAlign="left">
                          {dataValue && dataValue.question}
                          {userTocDetails &&
                            Object.keys(userTocDetails).map((val) => {
                              return <Typography variant="h6">{`: ${val.sectionName}`}</Typography>;
                            })}
                        </Typography>
                        <Typography variant="body2" color="text.primary" textAlign="left">
                          {dataValue && dataValue.userCommand}
                        </Typography>
                      </CardContent>
                    </Card>
                  </Item>
                ) : (
                  <Item>
                    <Card style={{ overflowY: 'auto', maxHeight: '205px', minHeight: '205px' }}>
                      <CardHeader title="Feedback and Comments" style={{ background: '#34282808' }} align="left" />
                      <CardContent>
                        <Typography variant="body2" color="text.primary" textAlign="left">
                          {dataValue && dataValue.feedback}
                        </Typography>
                        <br />
                        <Typography variant="body2" color="text.primary" textAlign="left">
                          {dataValue && dataValue.comments}
                        </Typography>
                        <br />
                      </CardContent>
                    </Card>
                  </Item>
                )}
              </Grid>
              {isFeedback && (
                <>
                  <br />
                  <Grid item xs={12}>
                    <Item>
                      <Card style={{ overflowY: 'auto', maxHeight: '185px', minHeight: '185px' }}>
                        <CardHeader title="Reason for Dislike" style={{ background: '#34282808' }} align="left" />
                        <CardContent>
                          {dataValue &&
                            dataValue.reasonForDisLike &&
                            dataValue.reasonForDisLike.map((val) => (
                              <Typography variant="body2" color="text.primary" textAlign="left">
                                {val}
                              </Typography>
                            ))}
                          <br />
                          <br />
                        </CardContent>
                      </Card>
                    </Item>
                  </Grid>
                </>
              )}
              <br />
              {!isFeedback && dataValue && (
                <Grid>
                  <Item>
                    <Card
                      className={dataValue.type === 'content_filter' ? classes.minimizedPromptCard : classes.promptCard}
                    >
                      <CardHeader
                        title={constants.CHAT_PROMPT_CONTENT}
                        style={{ background: '#34282808' }}
                        align="left"
                      />
                      <CardContent>
                        {dataValue &&
                          dataValue.content &&
                          Array.isArray(dataValue.content) &&
                          dataValue.content.map((val, index) => (
                            <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                              {dataValue.type === 'summary' ? (
                                <>
                                  <Typography variant="h6">Chunk {index + 1}</Typography>
                                  <Typography variant="body2" color="text.secondary" textAlign="left">
                                    {' '}
                                    {val}
                                  </Typography>
                                </>
                              ) : (
                                <Typography variant="body2" color="text.secondary" textAlign="left">
                                  {' '}
                                  {val}
                                </Typography>
                              )}
                              <br />
                            </Typography>
                          ))}
                        {dataValue && dataValue.content && dataValue.content.length === 0 ? (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            There is no data {dataValue.type === 'answer' ? 'context' : 'content'} available for this
                            record.
                          </Typography>
                        ) : (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {dataValue.content}
                          </Typography>
                        )}
                        <br />
                        {renderResponsePrompts()}
                        {filterPromt.includes(dataValue.type) &&
                        dataValue.prompt &&
                        typeof dataValue.prompt === 'object' ? (
                          dataValue.prompt.map((data) => {
                            return (
                              <>
                                <Typography
                                  variant="body2"
                                  color="text.primary"
                                  textAlign="center"
                                >{`Role: ${data.role}`}</Typography>
                                {data.prompt && (
                                  <>
                                    <Typography variant="body2" color="text.primary" textAlign="left">
                                      {constants.CHAT_PROMPT}:
                                    </Typography>{' '}
                                    <Typography variant="body2" color="text.secondary" textAlign="left">
                                      {data.prompt.toString().replace(/\n/g, '\\n')}
                                    </Typography>
                                    <br />
                                  </>
                                )}
                                {data.safety && (
                                  <>
                                    <Typography variant="body2" color="text.primary" textAlign="left">
                                      {constants.CHAT_SAFETY}:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" textAlign="left">
                                      {data.safety}
                                    </Typography>
                                    <br />
                                  </>
                                )}
                                {data.userTopic && (
                                  <>
                                    <Typography variant="body2" color="text.primary" textAlign="left">
                                      {constants.CHAT_USERTOPIC}:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" textAlign="left">
                                      {data.userTopic}
                                    </Typography>
                                    <br />
                                  </>
                                )}
                              </>
                            );
                          })
                        ) : (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {dataValue && dataValue.prompt && dataValue.prompt.toString().replace(/\n/g, '\\n')}
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Item>
                </Grid>
              )}

              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (typeData && typeData.type === constants.PROBLEM_SOLVE) {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title={`${constants.CHAT_PROMPT_CONTENT} - Problem Solve`}
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent>
                              {renderSubTypeResponsePrompts(typeData)}
                              {typeData.prompt &&
                                typeof typeData.prompt === 'object' &&
                                typeData.prompt.map((data) => {
                                  return (
                                    <>
                                      <Typography
                                        variant="body2"
                                        color="text.primary"
                                        textAlign="center"
                                      >{`Role: ${data.role}`}</Typography>
                                      {data.prompt && (
                                        <>
                                          {' '}
                                          <Typography variant="body2" color="text.primary" textAlign="left">
                                            {constants.CHAT_PROMPT}:
                                          </Typography>{' '}
                                          <Typography variant="body2" color="text.secondary" textAlign="left">
                                            {data.prompt.toString().replace(/\n/g, '\\n')}
                                          </Typography>
                                          <br />
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}

              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (typeData && typeData.type === constants.PROBLEM_SOLVE_VERIFIER) {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title={`${constants.CHAT_PROMPT_CONTENT} - Problem Solve Verifier`}
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent>
                              {renderSubTypeResponsePrompts(typeData)}
                              {typeData.prompt &&
                                typeof typeData.prompt === 'object' &&
                                typeData.prompt.map((data) => {
                                  return (
                                    <>
                                      <Typography
                                        variant="body2"
                                        color="text.primary"
                                        textAlign="center"
                                      >{`Role: ${data.role}`}</Typography>
                                      {data.prompt && (
                                        <>
                                          {' '}
                                          <Typography variant="body2" color="text.primary" textAlign="left">
                                            {constants.CHAT_PROMPT}:
                                          </Typography>{' '}
                                          <Typography variant="body2" color="text.secondary" textAlign="left">
                                            {data.prompt.toString().replace(/\n/g, '\\n')}
                                          </Typography>
                                          <br />
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}

              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (typeData && typeData.type === constants.PROBLEM_SOLVE_RECTIFIER) {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title={`${constants.CHAT_PROMPT_CONTENT} - Problem Solve Rectifier`}
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent>
                              {renderSubTypeResponsePrompts(typeData)}
                              {typeData.prompt &&
                                typeof typeData.prompt === 'object' &&
                                typeData.prompt.map((data) => {
                                  return (
                                    <>
                                      <Typography
                                        variant="body2"
                                        color="text.primary"
                                        textAlign="center"
                                      >{`Role: ${data.role}`}</Typography>
                                      {data.prompt && (
                                        <>
                                          {' '}
                                          <Typography variant="body2" color="text.primary" textAlign="left">
                                            {constants.CHAT_PROMPT}:
                                          </Typography>{' '}
                                          <Typography variant="body2" color="text.secondary" textAlign="left">
                                            {data.prompt.toString().replace(/\n/g, '\\n')}
                                          </Typography>
                                          <br />
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}

              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (typeData && typeData.type === constants.GENERATE_PYTHON) {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title={`${constants.CHAT_PROMPT_CONTENT} - ${constants.GENERATE_PYTHON_AI_RESPONSE}`}
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent>
                              {renderSubTypeResponsePrompts(typeData)}
                              {typeData.prompt &&
                                typeof typeData.prompt === 'object' &&
                                typeData.prompt.map((data) => {
                                  return (
                                    <>
                                      <Typography
                                        variant="body2"
                                        color="text.primary"
                                        textAlign="center"
                                      >{`Role: ${data.role}`}</Typography>
                                      {data.prompt && (
                                        <>
                                          {' '}
                                          <Typography variant="body2" color="text.primary" textAlign="left">
                                            {constants.CHAT_PROMPT}:
                                          </Typography>{' '}
                                          <Typography variant="body2" color="text.secondary" textAlign="left">
                                            {data.prompt.toString().replace(/\n/g, '\\n')}
                                          </Typography>
                                          <br />
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}

              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (typeData && typeData.type === constants.PROBLEM_SOLVE_FINAL_RESPONSE) {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title={`${constants.CHAT_PROMPT_CONTENT} - ${constants.PROBLEM_SOLVE_FINAL_AI_RESPONSE}`}
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent>
                              {renderSubTypeResponsePrompts(typeData)}
                              {typeData.prompt &&
                                typeof typeData.prompt === 'object' &&
                                typeData.prompt.map((data) => {
                                  return (
                                    <>
                                      <Typography
                                        variant="body2"
                                        color="text.primary"
                                        textAlign="center"
                                      >{`Role: ${data.role}`}</Typography>
                                      {data.prompt && (
                                        <>
                                          {' '}
                                          <Typography variant="body2" color="text.primary" textAlign="left">
                                            {constants.CHAT_PROMPT}:
                                          </Typography>{' '}
                                          <Typography variant="body2" color="text.secondary" textAlign="left">
                                            {data.prompt.toString().replace(/\n/g, '\\n')}
                                          </Typography>
                                          <br />
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}
              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (checkboxSelectedValue.content_filter === true && typeData && typeData.type === 'content_filter') {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title="Content Filter Response"
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent>
                              {typeData &&
                                typeData.type === 'content_filter' &&
                                typeData.botResponse &&
                                Object.keys(typeData.botResponse).length > 0 &&
                                Object.keys(typeData.botResponse).map((key) => {
                                  if (key === 'Category_Severity') {
                                    return typeData.botResponse[key].map((data) => {
                                      return (
                                        <>
                                          <Typography variant="body2" color="text.secondary" textAlign="left">
                                            Category : {data.Category}
                                          </Typography>
                                          <Typography variant="body2" color="text.secondary" textAlign="left">
                                            Severity : {data.Severity}
                                          </Typography>
                                        </>
                                      );
                                    });
                                  }
                                  /* else if (key === 'blocklistsMatchResults') {
                                    return (
                                      <>
                                        <Typography variant="body2" color="text.secondary" textAlign="left">
                                          blocklistsMatchResults :{' '}
                                          {typeData.botResponse[key].length > 0 ? (
                                            typeData.botResponse[key].map((data, index1) => {
                                              return Object.entries(data).map(
                                                ([blockListResultKey, blockListResultValue]) => (
                                                  <Typography
                                                    variant="body2"
                                                    color="text.secondary"
                                                    textAlign="left"
                                                    key={index1}
                                                  >
                                                    {blockListResultKey} : {blockListResultValue}
                                                  </Typography>
                                                )
                                              );
                                            })
                                          ) : (
                                            <Typography variant="body2" color="text.secondary" textAlign="left">
                                              []
                                            </Typography>
                                          )}
                                        </Typography>
                                        <br />
                                      </>
                                    );
                                  } */
                                  return <></>;
                                })}
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}
              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (
                    checkboxSelectedValue.explain_stand_alone === true &&
                    typeData &&
                    typeData.type === 'explain_stand_alone'
                  ) {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title="Explain Standalone Response"
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent>
                              {typeData &&
                                typeData.type === 'explain_stand_alone' &&
                                typeData.botResponse &&
                                typeData.botResponse.explainStandAloneBotResponse && (
                                  <>
                                    <Typography variant="body2" color="text.secondary" textAlign="left">
                                      {typeData.botResponse.explainStandAloneBotResponse}
                                    </Typography>
                                  </>
                                )}
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}
              {!isFeedback &&
                dataValue &&
                dataValue.type === 'content_filter' &&
                dataValue.botResponse &&
                Object.keys(dataValue.botResponse).length > 0 && (
                  <>
                    <br />
                    <Item>
                      <Card className={classes.chatIntentCard}>
                        <CardHeader title="Content Filter Response" style={{ background: '#34282808' }} align="left" />
                        <CardContent>
                          {Object.keys(dataValue.botResponse).map((key) => {
                            if (key === 'Category_Severity') {
                              return dataValue.botResponse[key].map((data, index) => {
                                return (
                                  <>
                                    <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                      Category : {data.Category}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                      Severity : {data.Severity}
                                    </Typography>
                                  </>
                                );
                              });
                            }
                            return (
                              <>
                                <Typography variant="body2" color="text.secondary" textAlign="left">
                                  blocklistsMatchResults :{' '}
                                  {dataValue.botResponse[key].length > 0 ? (
                                    dataValue.botResponse[key].map((data, index1) => {
                                      return Object.entries(data).map(([blockListResultKey, blockListResultValue]) => (
                                        <Typography
                                          variant="body2"
                                          color="text.secondary"
                                          textAlign="left"
                                          key={index1}
                                        >
                                          {blockListResultKey} : {blockListResultValue}
                                        </Typography>
                                      ));
                                    })
                                  ) : (
                                    <Typography variant="body2" color="text.secondary" textAlign="left">
                                      []
                                    </Typography>
                                  )}
                                </Typography>
                                <br />
                              </>
                            );
                          })}
                        </CardContent>
                      </Card>
                    </Item>
                  </>
                )}
              {!isFeedback &&
                dataValue.type === constants.GENERATE_PYTHON &&
                checkboxSelectedValue.GENERATE_PYTHON === true && (
                  <>
                    <>
                      <br />
                      <Item>
                        <Card className={classes.chatIntentCard}>
                          <CardHeader
                            title={constants.GENERATE_PYTHON_AI_RESPONSE}
                            style={{ background: '#34282808' }}
                            align="left"
                          />
                          <CardContent>
                            <Typography variant="body2" color="text.secondary" textAlign="left">
                              {dataValue?.botResponse?.generatePythonResponse &&
                                dataValue?.botResponse?.generatePythonResponse.length > 0 &&
                                dataValue?.botResponse?.generatePythonResponse.map((item) =>
                                  item.content.toString().replaceAll(/\n/g, '\\n')
                                )}
                            </Typography>
                          </CardContent>
                        </Card>
                      </Item>
                    </>
                  </>
                )}
            </Grid>
            <br />
            <Grid item xs={6}>
              {!isFeedback ? (
                <Item>
                  <Card
                    className={
                      dataValue.type === 'content_filter' ? classes.inCreaseAiStudyToolcard : classes.aiStudyToolcard
                    }
                  >
                    <CardHeader
                      title="AI Study Tool Response"
                      style={{ background: '#34282808' }}
                      align="left"
                      action={renderRawResponse(constants.AI_STUDY_TOOLS_RESPONSE)}
                    />
                    {!checked.aiStudyTools ? (
                      <CardContent>
                        {dataValue &&
                          dataValue.type === 'chat_intent' &&
                          dataValue.botResponse &&
                          Array.isArray(dataValue.botResponse) &&
                          dataValue.botResponse.map((data, index) => {
                            return (
                              <>
                                {' '}
                                {dataValue.botResponse.length > 1 && (
                                  <Typography variant="body2" color="text.secondary" textAlign="left">
                                    {index === 0 ? '[NORM-GPT]' : '[BAD-GPT]'}
                                  </Typography>
                                )}
                                {Object.entries(data).map(([key, value]) => (
                                  <Typography variant="body2" color="text.secondary" textAlign="left">
                                    {key} : {value}
                                  </Typography>
                                ))}
                                <br />
                              </>
                            );
                          })}
                        {dataValue &&
                          dataValue.type === 'chat_intent' &&
                          dataValue.botResponse &&
                          typeof dataValue.botResponse === 'string' && (
                            <Typography variant="body2" color="text.secondary" textAlign="left">
                              {dataValue.botResponse}
                            </Typography>
                          )}
                        {dataValue &&
                          (dataValue.type === 'problem_solve' ||
                            dataValue.type === constants.PROBLEM_SOLVE_RECTIFIER) &&
                          dataValue.botResponse &&
                          typeof dataValue.botResponse === 'string' && (
                            <Typography variant="body2" color="text.secondary" textAlign="left">
                              {checkMathJax(dataValue.botResponse)}
                            </Typography>
                          )}
                        {dataValue && dataValue.type === 'content_filter' && dataValue.finalResponse && (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {dataValue.finalResponse}
                          </Typography>
                        )}
                        {/* {dataValue &&
                        dataValue.type === 'quiz' &&
                        dataValue.botResponse &&
                        dataValue.botResponse.map((data, index) => (
                          <>
                            <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                              {data.question}{' '}
                              {data.optionsList.map((x, index2) => (
                                <Typography variant="body2" color="text.secondary" textAlign="left" key={index2}>
                                  {checkMathJax(x)}
                                </Typography>
                              ))}
                            </Typography>
                            <br />
                          </>
                        ))} */}
                        {dataValue &&
                          dataValue.type === 'summary' &&
                          Object.keys(dataValue.botResponse).map((val) => {
                            return (
                              val === 'processedSummary' && (
                                <>
                                  {dataValue.botResponse[val] &&
                                    dataValue.botResponse[val].map((arr, index) => (
                                      <>
                                        {val === 'processedSummary' && (
                                          <Typography variant="h6">{`Bot Response ${index + 1}`}</Typography>
                                        )}
                                        <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                          {checkMathJax(arr)}
                                        </Typography>
                                      </>
                                    ))}
                                  <br />
                                </>
                              )
                            );
                          })}
                        {dataValue &&
                          dataValue.type === 'summary' &&
                          Object.keys(dataValue.botResponse).map((val) => {
                            return (
                              val === 'botSummary' && (
                                <>
                                  <Typography variant="h6">Final Summary</Typography>
                                  {dataValue.botResponse[val] &&
                                    dataValue.botResponse[val].map((arr, index) => (
                                      <>
                                        <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                          {checkMathJax(arr)}
                                        </Typography>
                                      </>
                                    ))}
                                  <br />
                                </>
                              )
                            );
                          })}
                        {dataValue &&
                          dataValue.type === 'flashcards' &&
                          dataValue.botResponse &&
                          dataValue.botResponse.map((data, index) => (
                            <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                              {data.question}
                              <br />
                              {data.answer.map((ans, indexKey) => (
                                <Typography variant="body2" color="text.secondary" textAlign="left" key={indexKey}>
                                  {checkMathJax(ans)}
                                  <br />
                                </Typography>
                              ))}
                              <br />
                            </Typography>
                          ))}
                        {dataValue &&
                          filterPromt.includes(dataValue.type) &&
                          dataValue.type !== 'chat_intent' &&
                          dataValue.type !== 'problem_solve' &&
                          dataValue.type !== constants.PROBLEM_SOLVE_RECTIFIER &&
                          dataValue.type !== 'explain_stand_alone' &&
                          dataValue.type !== constants.ANSWER_CYU_MCQ &&
                          dataValue.type !== 'salutation' &&
                          dataValue.type !== 'chat_enrichment' &&
                          dataValue.type !== 'flashcards' &&
                          dataValue.type !== 'quiz_mcq' &&
                          dataValue.type !== 'quiz' &&
                          dataValue.type !== 'content_classifier' &&
                          dataValue.type !== 'context_identifier_user_selection' &&
                          dataValue.type !== constants.SUMMARY_EXPLAIN &&
                          dataValue.type !== constants.EXPERTDESK_MCQ &&
                          dataValue.type !== constants.EXPERTDESK_FREE_RESPONSE &&
                          dataValue.type !== constants.HISTORY_TITLE &&
                          dataValue.type !== constants.CONTEXT_IDENTIFIER_SUMMARY &&
                          dataValue.type !== constants.SPECIFIC_TOPIC_INTENT_IDENTIFIER &&
                          dataValue.type !== constants.SUMMARY_TOPIC_IDENTIFIER &&
                          dataValue.botResponse &&
                          Array.isArray(dataValue.botResponse) &&
                          dataValue.botResponse.map((data, index) => (
                            <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                              {checkMathJax(data)}
                            </Typography>
                          ))}
                        {dataValue.type === constants.GENERATE_PYTHON ? (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {problemSolveFinalResponse?.botResponse?.problemSolveFinalResponse &&
                              checkMathJax(problemSolveFinalResponse?.botResponse?.problemSolveFinalResponse)}
                          </Typography>
                        ) : (
                          constants.commonAuditTypesResponse.includes(dataValue.type) &&
                          dataValue.botResponse &&
                          Object.keys(dataValue.botResponse).length > 0 &&
                          Object.values(dataValue.botResponse).map((item) => {
                            if (Array.isArray(item) && item.length > 0) {
                              return item.map((subItem, index) => {
                                if (
                                  (Array.isArray(subItem) || Object.keys(subItem).length > 0) &&
                                  typeof subItem !== 'string' &&
                                  typeof subItem !== 'number'
                                ) {
                                  return (
                                    <>
                                      {objectIteration(subItem)}
                                      <br />
                                      <br />
                                    </>
                                  );
                                }
                                return (
                                  <>
                                    <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                      {checkMathJax(subItem)}
                                    </Typography>
                                    <br />
                                  </>
                                );
                              });
                            }
                            return <></>;
                          })
                        )}
                        {dataValue &&
                          [
                            constants.EXPLAIN_STAND_ALONE,
                            constants.SALUTATION,
                            constants.ANSWER_CYU_MCQ,
                            constants.HISTORY_TITLE,
                            constants.CHANNEL_FETCH_TYPE
                          ].includes(dataValue.type) &&
                          dataValue.botResponse &&
                          (dataValue.botResponse.explainStandAloneBotResponse ||
                            dataValue.botResponse.answerCyuMcqParserBotResponse ||
                            dataValue.botResponse.salutationBotResponse ||
                            dataValue.botResponse.historyTitle ||
                            dataValue.botResponse.channelFetch) && (
                            <>
                              <Typography variant="body2" color="text.secondary" textAlign="left">
                                {dataValue.botResponse.explainStandAloneBotResponse ||
                                  dataValue.botResponse.salutationBotResponse ||
                                  dataValue.botResponse.answerCyuMcqParserBotResponse ||
                                  dataValue.botResponse.historyTitle ||
                                  dataValue.botResponse.channelFetch}
                              </Typography>
                            </>
                          )}
                        {dataValue &&
                          filterPromt.includes(dataValue.type) &&
                          (dataValue.type === 'quiz_mcq' || dataValue.type === 'quiz') &&
                          dataValue.botResponse &&
                          dataValue.botResponse.map((data) => {
                            const changedValue = JSON.parse(data);
                            return changedValue.questions.map((value) => {
                              return (
                                <>
                                  <Typography variant="body2" color="text.secondary" textAlign="left">
                                    Question Id :<b> {`${value.id}`}</b>
                                    <br />
                                    <br />
                                    <span>
                                      {'Question : '} {value.question_stem}
                                    </span>
                                    <br />
                                    <br />
                                  </Typography>
                                  {value.options.map((values) => {
                                    return (
                                      <Typography variant="body2" color="text.secondary" textAlign="left">
                                        {`${values.id}.`}
                                        {values.distractor}
                                      </Typography>
                                    );
                                  })}
                                  <br />
                                  {value.options.map((feed) => {
                                    return (
                                      <Typography variant="body2" color="text.secondary" textAlign="left">
                                        {`Feedback for answer choices : ${feed.id}.`}
                                        {feed.feedback}
                                        <br />
                                        <br />
                                      </Typography>
                                    );
                                  })}
                                  <br />
                                </>
                              );
                            });
                          })}
                        {dataValue && dataValue.type === 'content_classifier' && dataValue.botResponse && (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {checkMathJax(dataValue.botResponse)}
                          </Typography>
                        )}
                        {dataValue && dataValue.type === 'chat_enrichment' && dataValue.botResponse && (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {checkMathJax(dataValue.botResponse)}
                          </Typography>
                        )}
                        {dataValue && dataValue.type === constants.SUMMARY_EXPLAIN && dataValue.botResponse && (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {checkMathJax(dataValue.botResponse.summaryExplainBotResponse)}
                          </Typography>
                        )}
                        {dataValue &&
                          dataValue.type === 'context_identifier_user_selection' &&
                          dataValue.botResponse &&
                          Array.isArray(dataValue.botResponse) &&
                          dataValue.botResponse.map((data) => {
                            return (
                              <>
                                <Typography
                                  variant="body2"
                                  color="text.primary"
                                  textAlign="center"
                                >{`Role: ${data.role}`}</Typography>
                                <>
                                  <Typography variant="body2" color="text.secondary" textAlign="left">
                                    {`Content: ${data.content}`}
                                  </Typography>
                                  <br />
                                </>
                              </>
                            );
                          })}
                        {dataValue &&
                          (dataValue.type === constants.EXPERTDESK_FREE_RESPONSE ||
                            dataValue.type === constants.EXPERTDESK_MCQ) &&
                          dataValue.botResponse &&
                          dataValue.botResponse.expertDeskMcqBotResponse && (
                            <>
                              <Typography>flashcards</Typography>
                              {renderExpertDeskResponse(dataValue.botResponse.expertDeskMcqBotResponse)}
                            </>
                          )}
                        {dataValue &&
                          [
                            constants.CONTEXT_IDENTIFIER_SUMMARY,
                            constants.SPECIFIC_TOPIC_INTENT_IDENTIFIER,
                            constants.SUMMARY_TOPIC_IDENTIFIER
                          ].includes(dataValue.type) &&
                          dataValue.botResponse &&
                          typeof dataValue.botResponse === 'object' && (
                            <>
                              <Typography
                                variant="body2"
                                color="text.primary"
                                textAlign="center"
                              >{`Role: ${dataValue?.botResponse?.role}`}</Typography>
                              <>
                                <Typography variant="body2" color="text.secondary" textAlign="left">
                                  {`Content: ${dataValue.botResponse?.content}`}
                                </Typography>
                                <br />
                              </>
                            </>
                          )}
                        <br />
                        <br />
                      </CardContent>
                    ) : (
                      <CardContent>
                        <Typography variant="body2" color="text.secondary" textAlign="left">
                          {aiStudyRawResponse}
                        </Typography>
                      </CardContent>
                    )}
                  </Card>
                </Item>
              ) : (
                <>
                  <Item>
                    <Card style={{ overflowY: 'auto', minHeight: '205px', maxHeight: '205px' }}>
                      <CardHeader title="AI Study Tool Response" style={{ background: '#34282808' }} align="left" />
                      <CardContent style={{ overflowY: 'auto' }}>
                        {/* {dataValue &&
                          dataValue.type === 'quiz' &&
                          dataValue.botResponse &&
                          dataValue.botResponse.map((data, index) => (
                            <>
                              <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                {data.question}{' '}
                                {data.optionsList.map((x, index2) => (
                                  <Typography variant="body2" color="text.secondary" textAlign="left" key={index2}>                                    
                                    {checkMathJax(x)}  
                                  </Typography>
                                ))}
                              </Typography>
                              <br />
                            </>
                          ))} */}
                        {dataValue &&
                          dataValue.feedbackContent &&
                          !Array.isArray(dataValue.feedbackContent) &&
                          Object.keys(dataValue.feedbackContent).length > 0 &&
                          Object.entries(dataValue.feedbackContent).map(([key, value]) => (
                            <Typography variant="body2" color="text.secondary" textAlign="left">
                              {key} : {value}
                            </Typography>
                          ))}
                        {dataValue &&
                          dataValue.feedbackContent &&
                          Array.isArray(dataValue.feedbackContent) &&
                          dataValue.feedbackContent.map((item) => {
                            if (typeof item === 'string') {
                              return checkMathJax(item);
                            } else if (Object.keys(item).length > 0 && !Array.isArray(item)) {
                              return quizObjectIteration(item);
                            } else {
                              return item;
                            }
                          })}
                      </CardContent>
                    </Card>
                  </Item>
                  <br />
                  <Item>
                    <Card style={{ overflowY: 'auto', minHeight: '185px', maxHeight: '185px' }}>
                      <CardHeader
                        title={constants.CHAT_PROMPT_CONTENT}
                        style={{ background: '#34282808' }}
                        align="left"
                      />
                      <CardContent>
                        {/* {dataValue && dataValue.feedbackContent && dataValue.type === 'summary' && (
                        <Typography variant="body2" color="text.primary" textAlign="left">
                          {dataValue && dataValue.feedbackContent}
                        </Typography>
                      )} */}
                        {/* {dataValue &&
                          dataValue.type === 'quiz' &&
                          dataValue.feedbackContent &&
                          dataValue.feedbackContent.map((val, index) => (
                            <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                              {val && val.answer} {''}
                              <br />
                              <Typography variant="body2" color="text.primary" textAlign="center">
                                {val.question && 'Question'}
                              </Typography>
                              {val && val.question} {''}
                              <br />
                              <Typography variant="body2" color="text.primary" textAlign="center">
                                {val && val.options && val.options.length > 0 ? 'Options' : ''}
                              </Typography>
                              {val && val.options}
                            </Typography>
                          ))} */}
                        {/* {dataValue &&
                          dataValue.type === 'summary' &&
                          dataValue.feedbackContent &&
                          dataValue.feedbackContent.length > 0 &&
                          dataValue.feedbackContent.map((val, index) => (
                            <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                              {val && val} {''}
                            </Typography>
                          ))} */}
                        {dataValue &&
                          (dataValue.type === 'discuss' ||
                            // dataValue.type === 'quiz' ||
                            dataValue.type === 'answer' ||
                            dataValue.type === 'summary') &&
                          dataValue.content &&
                          dataValue.content.map((val, index) => (
                            <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                              {val && val}
                            </Typography>
                          ))}
                        <br />
                        {filterPromt.includes(dataValue.type) &&
                        dataValue.prompt &&
                        typeof dataValue.prompt === 'object' ? (
                          dataValue.prompt.map((data) => {
                            return (
                              <>
                                <Typography
                                  variant="body2"
                                  color="text.primary"
                                  textAlign="center"
                                >{`Role: ${data.role}`}</Typography>
                                {data.prompt && (
                                  <>
                                    {' '}
                                    <Typography variant="body2" color="text.primary" textAlign="left">
                                      {constants.CHAT_PROMPT}:
                                    </Typography>{' '}
                                    <Typography variant="body2" color="text.secondary" textAlign="left">
                                      {data.prompt.toString().replace(/\n/g, '\\n')}
                                    </Typography>
                                    <br />
                                  </>
                                )}
                              </>
                            );
                          })
                        ) : (
                          <Typography variant="body2" color="text.primary" textAlign="left">
                            {dataValue && dataValue.prompt && dataValue.prompt.toString().replace(/\n/g, '\\n')}
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Item>
                </>
              )}
              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (typeData && checkboxSelectedValue.chat_intent === true && typeData.type === 'chat_intent') {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader title="Chat Intent Response" style={{ background: '#34282808' }} align="left" />
                            <CardContent
                            // style={{
                            //   maxHeight:
                            //     checkboxSelectedValue.content_filter === true && typeData.type === 'content_filter'
                            //       ? '120px'
                            //       : '110px'
                            // }}
                            >
                              {typeData.botResponse &&
                                typeData.botResponse.chatIntentResponse &&
                                typeData.botResponse.chatIntentResponse.length > 0 &&
                                typeData.botResponse.chatIntentResponse.map((data, index) => {
                                  return (
                                    <>
                                      {' '}
                                      {typeData.botResponse.chatIntentResponse.length > 1 && (
                                        <Typography variant="body2" color="text.secondary" textAlign="left">
                                          {index === 0 ? '[NORM-GPT]' : '[BAD-GPT]'}
                                        </Typography>
                                      )}
                                      {Object.entries(data).map(([key, value]) => {
                                        if (key !== 'blocklistsMatchResults' && key !== 'Category_Severity') {
                                          return (
                                            <Typography variant="body2" color="text.secondary" textAlign="left">
                                              {key} : {value}
                                            </Typography>
                                          );
                                        }
                                        return <></>;
                                      })}
                                      <br />
                                    </>
                                  );
                                })}
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  /* if (checkboxSelectedValue.content_filter === true && typeData && typeData.type === 'content_filter') {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card style={{ maxHeight: '130px', minHeight: '130px' }}>
                            <CardHeader
                              title="content_filter Response"
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent style={{ overflowY: 'auto', maxHeight: '110px' }}>
                              {typeData.botResponse &&
                                typeData.botResponse.Category_Severity &&
                                typeData.botResponse.Category_Severity.map((data, index) => {
                                  return (
                                    <>
                                      <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                        Category : {data.Category}
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                        Severity : {data.Severity}
                                      </Typography>
                                    </>
                                  );
                                })}
                              {typeData.botResponse.blocklistsMatchResults && (
                                <>
                                  <Typography variant="body2" color="text.secondary" textAlign="left">
                                    blocklistsMatchResults :{' '}
                                    {typeData.botResponse.blocklistsMatchResults.length > 0 ? (
                                      typeData.botResponse.blocklistsMatchResults.map((data, index) => (
                                        <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                          {data}
                                        </Typography>
                                      ))
                                    ) : (
                                      <Typography variant="body2" color="text.secondary" textAlign="left">
                                        []
                                      </Typography>
                                    )}
                                  </Typography>
                                </>
                              )}
                              <br />
                              <br />
                            </CardContent>
                            <br />
                          </Card>
                        </Item>
                      </>
                    );
                  } */
                  return <></>;
                })}
              {SubTypeData &&
                checkboxSelectedValue.explain_stand_alone === true &&
                SubTypeData.map((typeData) => {
                  if (typeData && typeData.type === 'explain_stand_alone') {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title="Explain Standalone Prompt"
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent>
                              {typeData.prompt &&
                                typeof typeData.prompt === 'object' &&
                                typeData.prompt.map((data) => {
                                  return (
                                    <>
                                      <Typography
                                        variant="body2"
                                        color="text.primary"
                                        textAlign="center"
                                      >{`Role: ${data.role}`}</Typography>
                                      {data.prompt && (
                                        <>
                                          {' '}
                                          <Typography variant="body2" color="text.primary" textAlign="left">
                                            {constants.CHAT_PROMPT}:
                                          </Typography>{' '}
                                          <Typography variant="body2" color="text.secondary" textAlign="left">
                                            {data.prompt.toString().replace(/\n/g, '\\n')}
                                          </Typography>
                                          <br />
                                        </>
                                      )}
                                    </>
                                  );
                                })}
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}
              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (typeData && typeData.type === constants.PROBLEM_SOLVE) {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title="AI Study Tools Response - Problem Solve"
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent>
                              <Typography variant="body2" color="text.secondary" textAlign="left">
                                {checkMathJax(typeData.botResponse)}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}
              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (typeData && typeData.type === constants.PROBLEM_SOLVE_VERIFIER) {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title="AI Study Tools Response - Problem Solve Verifier"
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent>
                              <Typography variant="body2" color="text.secondary" textAlign="left">
                                {checkMathJax(typeData.botResponse)}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}
              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (typeData && typeData.type === constants.PROBLEM_SOLVE_RECTIFIER) {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title="AI Study Tools Response - Problem Solve Rectifier"
                              style={{ background: '#34282808' }}
                              align="left"
                              action={renderRawResponse(constants.PROBLEM_SOLVE_RECTIFIER_TEXT)}
                            />
                            <CardContent>
                              <Typography variant="body2" color="text.secondary" textAlign="left">
                                {!checked.problemSolveRawRectifier
                                  ? checkMathJax(typeData.botResponse)
                                  : typeData.botResponse}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}
              {SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (typeData && typeData.type === constants.GENERATE_PYTHON) {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title={constants.GENERATE_PYTHON_AI_RESPONSE}
                              style={{ background: '#34282808' }}
                              align="left"
                            />
                            <CardContent>
                              <Typography variant="body2" color="text.secondary" textAlign="left">
                                {typeData?.botResponse?.generatePythonResponse &&
                                  typeData?.botResponse?.generatePythonResponse.length > 0 &&
                                  typeData?.botResponse?.generatePythonResponse.map((item) =>
                                    item.content.toString().replaceAll(/\n/g, '\\n')
                                  )}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}
              {dataValue.type !== constants.GENERATE_PYTHON &&
                SubTypeData &&
                SubTypeData.map((typeData) => {
                  if (typeData && typeData.type === constants.PROBLEM_SOLVE_FINAL_RESPONSE) {
                    return (
                      <>
                        <br />
                        <Item>
                          <Card className={classes.chatIntentCard}>
                            <CardHeader
                              title={constants.PROBLEM_SOLVE_FINAL_AI_RESPONSE}
                              style={{ background: '#34282808' }}
                              align="left"
                              action={renderRawResponse(constants.PROBLEM_SOLVE_FINAL_AI_RESPONSE)}
                            />
                            <CardContent>
                              <Typography variant="body2" color="text.secondary" textAlign="left">
                                {!checked.problemSolveFinalRes
                                  ? checkMathJax(typeData.botResponse?.problemSolveFinalResponse)
                                  : typeData.botResponse?.problemSolveFinalResponse}
                              </Typography>
                            </CardContent>
                          </Card>
                        </Item>
                      </>
                    );
                  }
                  return <></>;
                })}
              <>
                <br />
              </>
            </Grid>
          </Grid>
        </Box>
      </Dialog>
      <br />
    </>
  );
};

ChatDialogComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  openChat: PropTypes.bool.isRequired,
  isFeedback: PropTypes.bool.isRequired,
  loaderStatusResponse: PropTypes.bool.isRequired,
  // chatgptUtilityResponseStatus: PropTypes.bool.isRequired,
  chatgptUtilityResponseStatus: shapes.state.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataValue: PropTypes.object,
  dataLength: PropTypes.object,
  modalIndex: PropTypes.string,
  filterPromt: PropTypes.object,
  userTocDetails: PropTypes.object,
  problemSolveFlow: PropTypes.string,
  handleModalChange: PropTypes.func.isRequired,
  chatgptUtilitySubTypeResponseStatus: shapes.state.isRequired,
  ChatUtilitySubTypeResponse: shapes.modelOf(SubTypeResponse).isRequired
  // isTrendsInsightsList: PropTypes.bool
};

ChatDialogComponent.defaultProps = {
  modalIndex: '',
  filterPromt: [],
  dataValue: {},
  dataLength: {},
  userTocDetails: {},
  problemSolveFlow: ''
  // isTrendsInsightsList: false
};

// export default withStyles(styles)(ChatDialogComponent);
// export default withStyles(styles)(inject('language')(observer(Footer)));
export default withStyles(styles)(
  inject(
    'chatgptUtilityResponseStatus',
    'ChatUtilitySubTypeResponse',
    'chatgptUtilitySubTypeResponseStatus'
  )(observer(ChatDialogComponent))
);

import MomentUtils from '@date-io/moment';
import Framework, { LoadingHandler, StateErrorDisplay, shapes } from '@greenville/framework';
import { Box, Button, Grid, Paper, TextField, Typography } from '@material-ui/core';
import { ThemeProvider, createTheme, withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import FormControl from '@mui/material/FormControl';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Stack from '@mui/material/Stack';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import Loader from '../../../../common/Loader';
import ChatgptConfig from '../../../../common/config/ChatgptConfig';
import * as constants from '../../../../common/constants';
import ChannelsListing from '../../models/ChannelsListing';
import ChannelsTrendsModel from '../../models/ChannelsTrends';
import ChannelsTrendsGrid from './datagrid/ChannelsTrendsGrid';

const materialTheme = createTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersYear: {
      root: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      yearSelected: {
        color: constants.PEARSON_PRIMARY_COLOR
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    },
    MuiPickersDay: {
      day: {
        '&:hover': {
          backgroundColor: '#d2cfc4'
        }
      },
      daySelected: {
        backgroundColor: constants.PEARSON_PRIMARY_COLOR,
        '&:hover': {
          backgroundColor: constants.PEARSON_PRIMARY_COLOR
        }
      },
      current: {
        color: constants.PEARSON_PRIMARY_COLOR
      }
    }
  }
});

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 30
      }
    }
  }
});

const styles = (theme) => ({
  buttonGroupStyle: {
    justifyContent: 'center'
  },
  datePicker: {
    marginTop: 'inherit'
  },
  marginBox: {
    border: '1px solid #322c2c2e',
    width: '52%',
    padding: '2px 15px 2px 0px',
    borderRadius: '4px',
    color: '#fff',
    height: '41px'
  },
  blurStyle: {
    position: 'absolute',
    background: 'rgb(255 255 255 / 20%)',
    top: '0',
    width: '93%',
    height: '100%',
    backdropFilter: 'blur(0.3px)',
    zIndex: '9999'
  },
  buttonStyle: {
    width: '120px',
    margin: '10px'
  },
  container: {
    marginBottom: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  title: {
    marginTop: -20
  },
  labelWidht: {
    width: '380px'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  button: {
    paddingTop: theme.spacing(2),
    marginBottom: '-3em'
  },
  root: {
    top: '12%'
  },
  msgStyle: {
    fontSize: 'large',
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(0)
    },
    zIndex: 9999
  }
});

@inject(
  'channelsListingUtility',
  'ChannelsUtilityTrendsListingStatus',
  'channelsTrendsUtility',
  'channelsUtilityTrendsStatus'
)
@observer
class ChannelsTrends extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    channelsTrendsUtility: shapes.modelOf(ChannelsTrendsModel).isRequired,
    channelsListingUtility: shapes.modelOf(ChannelsListing).isRequired,
    ChannelsUtilityTrendsListingStatus: shapes.state.isRequired,
    channelsUtilityTrendsStatus: shapes.state.isRequired
  };

  constructor(props) {
    super(props);
    const { channelsTrendsUtility, channelsListingUtility, ChannelsUtilityTrendsListingStatus } = props;
    this.defaultSearch = {
      channelsId: '',
      startDate: '',
      endDate: ''
    };
    this.state = {
      newRequest: true,
      searchFields: '',
      gptResponse: channelsTrendsUtility,
      gptListingResponse: {
        chatListDatas: channelsListingUtility,
        chatListStatus: ChannelsUtilityTrendsListingStatus,
        loaderStatusResponse: true
      },
      endDisabled: true,
      showDateFields: false,
      showOutline: '1D',
      setOrder: 'ASC',
      toggleBtnValue: 'cluster',
      selectListingOption: false,
      loaderStatus: true,
      setDirectionValue: '',
      selectRadioOption: false
    };
  }

  componentDidMount = () => {
    const { channelsTrendsUtility } = this.props;
    /* eslint-disable camelcase */
    const temp = window.location.pathname;
    if (temp === '/chatutility/channels/TrendsInsights') {
      this.setState({
        selectRadioOption: false,
        selectListingOption: false
      });
    }

    Framework.getEventManager().on(constants.GET_CHANNELS_LISTING_MODEL_DATA, () => {
      const { channelsListingUtility } = this.props;
      const { gptListingResponse } = this.state;
      this.setState({
        gptListingResponse: {
          ...gptListingResponse,
          chatListDatas: channelsListingUtility
        }
      });
    });

    Framework.getEventManager().on(constants.GET_CHANNELS_TRENDS_MODEL_DATA, () => {
      const { data } = channelsTrendsUtility;
      if (channelsTrendsUtility) {
        const { newRequest, gptResponse } = this.state;
        const combineData = [...data];
        ChatgptConfig.channelsTypeData.forEach((value) => {
          const alteredValue = value.name;
          if (!alteredValue) return;
          combineData.forEach((dataValue, index) => {
            if (dataValue.type === alteredValue) {
              combineData[index].title = value.label;
            }
          });
        });
        const combineDatatoJS = JSON.parse(JSON.stringify(toJS(combineData)));
        /* eslint-enable camelcase */
        if (newRequest) {
          const sortedDate = combineDatatoJS.sort((x, y) => new Date(y.date) - new Date(x.date));
          this.setState({ gptResponse: sortedDate });
        } else {
          const combineDataGpt = [...gptResponse, ...combineDatatoJS];
          const sortedDate = combineDataGpt.sort((x, y) => new Date(y.date) - new Date(x.date));
          this.setState((prev) => ({ gptResponse: [...prev.gptResponse, ...sortedDate] }));
        }
      }
    });
  };

  handleChange = (e) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[e.target.name] = e.target.value;
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
  };

  handleToggleBtnChange = (e) => {
    const { channelsListingUtility, channelsTrendsUtility } = this.props;
    this.setState({
      toggleBtnValue: e.target.value,
      selectRadioOption: e.target.value
    });
    channelsListingUtility.resetStoreValues();
    channelsTrendsUtility.resetStoreValues();
  };

  handleReset = () => {
    const { gptListingResponse } = this.state;
    this.setState({
      showOutline: '',
      toggleBtnValue: 'cluster',
      searchFields: {
        ...this.defaultSearch
      },
      gptListingResponse: {
        ...gptListingResponse,
        chatListDatas: []
      }
    });
  };

  handleSearch = (customPayload) => {
    const { searchFields, toggleBtnValue } = this.state;
    const { channelsListingUtility, channelsTrendsUtility } = this.props;
    if (customPayload && customPayload.lastKey) {
      this.setState({ newRequest: false });
    } else {
      this.setState({ newRequest: true });
    }
    const payload = { ...searchFields, ...customPayload };

    let endDateChanged = new Date();
    endDateChanged = moment(endDateChanged).format(constants.YYYY_MM_DD_FORMAT);

    if ((payload && payload.startDate) || (payload && payload.channelsId)) {
      if (!payload.endDate && payload.startDate) {
        payload.endDate = endDateChanged;
        this.setState({
          searchFields: { ...searchFields, endDate: endDateChanged }
        });
      }
    } else {
      payload.endDate = endDateChanged;
      payload.startDate = endDateChanged;
      this.setState({
        searchFields: { ...searchFields, startDate: endDateChanged, endDate: endDateChanged }
      });
    }
    if (toggleBtnValue === 'cluster') {
      this.setState({
        selectRadioOption: false,
        selectListingOption: true
      });
      channelsListingUtility.fetch(payload);
    } else {
      channelsTrendsUtility.fetch(payload);
    }
  };

  trendsDataFetch = (value) => {
    const { showDateFields } = this.state;
    if (value === 'custom') {
      this.setState({
        showOutline: value,
        showDateFields: !showDateFields
      });
    }
  };

  handleDateChange = (date, name) => {
    const { searchFields } = this.state;
    const fieldChanged = {};
    fieldChanged[name] = moment(date).format(constants.YYYY_MM_DD_FORMAT);
    this.setState({
      searchFields: { ...searchFields, ...fieldChanged }
    });
    const payload = fieldChanged;
    if (payload.startDate) {
      this.setState({
        endDisabled: false
      });
    }
  };

  handleDateclicker = (event, value) => {
    const { searchFields } = this.state;
    const daysvalue = event.target.innerText;
    let startDate = new Date();
    let endDate = new Date();
    switch (daysvalue) {
      case '1d':
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '3d':
        startDate.setDate(startDate.getDate() - 2);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      case '1w':
        startDate.setDate(startDate.getDate() - 6);
        this.setState({
          showOutline: value,
          showDateFields: false
        });
        break;
      default:
    }
    startDate = moment(startDate).format(constants.YYYY_MM_DD_FORMAT);
    endDate = moment(endDate).format(constants.YYYY_MM_DD_FORMAT);
    this.setState({
      searchFields: { ...searchFields, startDate, endDate }
    });
  };

  handleAscending = (dir, value) => {
    const { gptResponse } = this.state;
    if (dir === 'asc') {
      const sorted = [...gptResponse].sort((a, b) => (a[value] > b[value] ? 1 : -1));
      this.setState({
        ...gptResponse,
        gptResponse: sorted
      });
    } else {
      const sorted = [...gptResponse].sort((a, b) => (a[value] < b[value] ? 1 : -1));
      this.setState({
        ...gptResponse,
        gptResponse: sorted
      });
    }
  };

  sorting = (value) => {
    const { setOrder } = this.state;
    if (setOrder === 'ASC') {
      this.handleAscending('asc', value);
      this.setState({
        setOrder: 'DSC',
        setDirectionValue: value
      });
    }
    if (setOrder === 'DSC') {
      this.handleAscending('des', value);
      this.setState({
        setOrder: 'ASC',
        setDirectionValue: value
      });
    }
  };

  render() {
    const { classes, channelsUtilityTrendsStatus } = this.props;
    const {
      searchFields,
      endDisabled,
      showDateFields,
      showOutline,
      toggleBtnValue,
      gptResponse,
      gptListingResponse,
      setOrder,
      setDirectionValue,
      selectRadioOption,
      selectListingOption,
      loaderStatus
    } = this.state;
    const { chatListStatus } = gptListingResponse;

    return (
      <>
        <Paper>
          <Box sx={{ flexGrow: 1 }}>
            <Box sx={{ p: 2 }}>
              <Typography variant="h5" align="center" gutterBottom spacing={9}>
                {constants.CHAT_CRITERIA_TEXT}
              </Typography>
            </Box>
            <Grid container direction="row">
              <Grid item xs={2} />
              <Grid item xs={8}>
                <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', p: '0px 33px' }}>
                  <FormControl sx={{ minWidth: '45%', maxWidth: '90%', mr: 4 }} size="small">
                    <TextField
                      label="Channels Id"
                      margin="dense"
                      name="channelsId"
                      onChange={($event) => this.handleChange($event)}
                      style={{ minWidth: '32%', marginTop: '0px' }}
                      value={searchFields.channelsId}
                      variant="outlined"
                    />
                  </FormControl>
                  <Box className={classes.marginBox} sx={{ '& button': { m: 31, p: 12 } }}>
                    <Stack direction="row" spacing={1}>
                      <Button
                        size="small"
                        variant={showOutline === '1D' ? 'outlined' : 'text'}
                        style={{ color: '#0d0c0c8c' }}
                        onClick={(event) => this.handleDateclicker(event, '1D')}
                      >
                        1d
                      </Button>
                      <Button
                        size="small"
                        style={{ color: '#0d0c0c8c' }}
                        variant={showOutline === '3D' ? 'outlined' : 'text'}
                        onClick={(event) => this.handleDateclicker(event, '3D')}
                      >
                        3d
                      </Button>
                      <Button
                        size="small"
                        style={{ color: '#0d0c0c8c' }}
                        variant={showOutline === '1W' ? 'outlined' : 'text'}
                        onClick={(event) => this.handleDateclicker(event, '1W')}
                      >
                        1w
                      </Button>
                      <Button
                        size="small"
                        variant={showOutline === 'custom' ? 'outlined' : 'text'}
                        style={{ color: '#0d0c0c8c' }}
                        onClick={() => this.trendsDataFetch('custom')}
                      >
                        custom
                        <CalendarMonthIcon />
                      </Button>
                    </Stack>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={2} />
            </Grid>
            <br />
            {showDateFields && (
              <Grid container direction="row">
                <Grid item xs={2} />
                <Grid item xs={8}>
                  <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'flex-end', p: '0px 33px' }}>
                    <MuiPickersUtilsProvider utils={MomentUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <KeyboardDatePicker
                          className={classes.datePicker}
                          format={constants.MM_DD_YYYY_FORMAT}
                          autoOk
                          size="small"
                          variant="inline"
                          inputVariant="outlined"
                          style={{ minWidth: '32%', marginRight: '6%' }}
                          disableFuture
                          value={searchFields.startDate || null}
                          label="Start Date"
                          onChange={(date) => this.handleDateChange(date, 'startDate')}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                        <KeyboardDatePicker
                          className={classes.datePicker}
                          // margin="normal"
                          format={constants.MM_DD_YYYY_FORMAT}
                          autoOk
                          variant="inline"
                          size="small"
                          style={{ minWidth: '32%' }}
                          inputVariant="outlined"
                          disableFuture
                          value={searchFields.endDate || null}
                          label="End Date"
                          onChange={(date) => this.handleDateChange(date, 'endDate')}
                          disabled={endDisabled}
                          KeyboardButtonProps={{
                            'aria-label': 'change date'
                          }}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </Box>
                  <br />
                </Grid>
                <Grid item xs={2} />
              </Grid>
            )}
            <Grid container direction="row" justifyContent="center">
              <Grid item xs={8} style={{ marginLeft: '6%' }}>
                <Box
                  sx={{ pl: '10%', ml: '20%' }}
                  style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', width: '100%' }}
                >
                  <Typography style={{ paddingBottom: '10px', fontWeight: '600', fontSize: '15px' }}>
                    Report By:
                  </Typography>
                  <ToggleButtonGroup
                    color="primary"
                    value={toggleBtnValue}
                    exclusive
                    onChange={this.handleToggleBtnChange}
                    aria-label="Platform"
                  >
                    <ToggleButton value="cluster">{constants.CLUSTERING}</ToggleButton>
                    <ToggleButton value="count">{constants.COUNT}</ToggleButton>
                    {/* <ToggleButton value="userFeedBack">User Feedback Chart</ToggleButton>
                    <ToggleButton value="blockedCount">Blocked Count Chart</ToggleButton> */}
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              <Grid item xs={6} />
            </Grid>
            <br />
          </Box>
          <br />
          <Box>
            <Grid container justify="space-evenly" className={classes.buttonGroupStyle}>
              <ThemeProvider theme={themes}>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={() => this.handleSearch()}
                  className={classes.buttonStyle}
                >
                  {constants.CHAT_SEARCH}
                </Button>
                <Button
                  variant="contained"
                  size="small"
                  color="primary"
                  onClick={this.handleReset}
                  className={classes.buttonStyle}
                >
                  {constants.CHAT_RESET}
                </Button>
              </ThemeProvider>
            </Grid>
            <br />
          </Box>
        </Paper>
        <LoadingHandler
          loading={channelsUtilityTrendsStatus.isPending || chatListStatus.isPending}
          isError={
            (channelsUtilityTrendsStatus.isError || chatListStatus.isError) &&
            (channelsUtilityTrendsStatus.error || chatListStatus.isError) &&
            (channelsUtilityTrendsStatus.error === 401 || chatListStatus.error === 401) &&
            (channelsUtilityTrendsStatus.error === 500 || chatListStatus.error === 500)
          }
          content={
            (gptResponse && gptResponse.length > 0) ||
            (gptListingResponse.chatListDatas.data && gptListingResponse.chatListDatas.data.length > 0) ? (
              <Box>
                <br />
                <ChannelsTrendsGrid
                  channelsListingUtilityData={gptListingResponse}
                  selectListingOption={selectListingOption}
                  channelsUtilityTrendsData={gptResponse}
                  sorting={this.sorting}
                  setOrder={setOrder}
                  setDirectionValue={setDirectionValue}
                  selectRadioOption={selectRadioOption}
                  startDate={searchFields.startDate || ''}
                  endDate={searchFields.endDate || ''}
                />
              </Box>
            ) : (
              <>
                <br />
                {['count', 'cluster'].includes(toggleBtnValue) ? (
                  <Paper>
                    <br />
                    <Box>
                      <br />
                      <Typography
                        variant="h4"
                        align="center"
                        gutterBottom
                        spacing={9}
                        className={classes.title}
                        style={{ p: 5, mt: 25 }}
                      >
                        {constants.CHAT_FETCH}
                      </Typography>
                    </Box>
                  </Paper>
                ) : (
                  <></>
                )}
              </>
            )
          }
          loadingContent={<Loader loaderStatus={loaderStatus} />}
          errorContent={
            <StateErrorDisplay error={channelsUtilityTrendsStatus.error || chatListStatus.error} showDetails />
          }
        />
      </>
    );
  }
}

export default withStyles(styles)(ChannelsTrends);

import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import { ExportJsonCsv } from 'react-export-json-csv';

const useStyles = makeStyles({
  mainContainer: {
    textAlign: 'end',
    marginBottom: '15px'
  },
  exportCsvButton: {
    backgroundColor: '#005d83 !important',
    color: 'white !important',
    cursor: 'pointer',
    height: '30px',
    border: 'none',
    borderRadius: '10px',
    width: '10%',
    textTransform: 'uppercase'
  }
});

const ExportToCSV = (props) => {
  const classes = useStyles();
  const { headers, data, fileTitle } = props;

  return (
    <div className={classes.mainContainer}>
      <ExportJsonCsv headers={headers} items={data} fileTitle={fileTitle} className={classes.exportCsvButton}>
        Export CSV
      </ExportJsonCsv>
    </div>
  );
};

ExportToCSV.propTypes = {
  headers: PropTypes.array,
  data: PropTypes.array,
  fileTitle: PropTypes.string
};

ExportToCSV.defaultProps = {
  headers: [],
  data: [],
  fileTitle: ''
};

export default ExportToCSV;

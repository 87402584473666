/* eslint-disable max-len */
const en = {
  'common.LOGOUT': 'Logout',
  'common.PREVIEW': 'Preview',
  'common.CLOSE': 'Close',
  'common.EDIT': 'Edit',
  'common.a11y.MORE_ICON': 'More...',
  'common.a11y.LOGO': 'Pearson Logo',
  'common.BACK': 'Back',
  'common.SAVE': 'Save',
  'common.DOWNLOAD': 'Download',
  'common.MIGRATE': 'Migrate',
  'common.START': 'Start',
  'common.DELETE': 'Delete',
  'common.SEND': 'Send',
  'common.SAVE_REQUEST': 'Save Request',
  'common.CANCEL_REQUEST': 'Cancel Request',
  'common.CANCEL': 'Cancel',
  'common.CANCEL_PROCESS': 'Cancel Process',
  'common.SEARCH': 'Search',
  'common.RESET': 'Reset',
  'common.APPLY': 'Apply',
  'common.EMAIL': 'Email',
  'common.REMOVE': 'Remove',
  'common.HOME': 'Home',
  'common.NO': 'No',
  'common.YES': 'Yes',
  'common.OK': 'Ok',
  'common.UPDATE_TITLE': 'Update Title',
  'common.DELETE_TITLE': 'Delete Title',
  'common.SUCCESS': 'Success',
  'common.SUBMIT_REQUEST': 'Submit Request',
  'common.FAILED': 'Failed',
  'common.IN_PROGRESS': 'In Progress',
  'common.NOT_STARTED': 'Not Started',
  'common.COVER_PAGE_NOT_FOUND': ' (Cover page not found)',
  'common.SLATES_NOT_FOUND': ' (Slates not found)',
  'common.BACkHOME': 'Book Detail',
  'common.BOOKS': 'Books',
  'common.TRY_LOGIN_AGAIN': 'Try Logging in Again',
  'common.GO_TO_DASHBOARD': 'Go to Dashboard',
  'common.JOIN_TWO': '{statement1} {joiner} {statement2}',
  'common.NO_RESULT_ERROR_DESC': 'Some error occured, try refreshing the application.',
  'common.NO_RESULT_FOUND': 'No results found',
  'common.NO_RESULT_FILTER_DESC': 'Try adjusting your search or filter.',
  'common.NO_RESULT_DATA_NOT_FOUND': `No 'In Progress' or 'Not Started' title in queue`,
  'common.MOBILE': 'Mobile',
  'common.EXPAND': 'Expand',
  'common.COLLAPSE': 'Collapse',
  'common.CLEARFORM': 'Clear Form',
  'common.RESET_ALL_FIELDS': 'Reset All Fields',
  'common.PHONENUMBER': 'Phone Number',
  'common.ADDEMAIL': 'Additional Email',
  'label.COMPARE_TITLE': 'Compare',
  'common.DRAG_&_DROP': 'Drag & drop files, or click to select files',
  'common.NO_FILE_FOLDER': 'No files or folders',
  'common.NOT_CHOOSEN_FILE_FOLDER': 'You have not chosen any files or folders to upload.',
  'common.select': 'select',
  'common.SELECT': 'Select',
  'label.VIS-A-VIS': 'Vis-a-Vis',
  'label.SOURCE_PDF': 'Source PDF',
  'label.COVER_PAGE': 'Cover Page',
  'label.IPAD_COVER_PAGE': 'iPad Cover Page',
  'message.CLOSE': 'Press X or Esc to close',
  'message.DRAG_OR_REPOSITION': 'Move cursor to top of the window to drag & reposition',
  'home.WELCOME_EARLY': 'Looks like you’re an early bird, {userName}',
  'home.WELCOME_MORNING': 'Good morning, {userName}',
  'home.WELCOME_AFTERNOON': 'Good afternoon, {userName}',
  'home.WELCOME_EVENING': 'Good evening, {userName}',
  'home.WELCOME_NIGHT': 'Looks like you’re a night owl, {userName}',
  'label.STATUS': 'Status',
  'label.TITLE': 'Title',
  'label.AUTHOR': 'Author',
  'label.TITLE_TYPE': 'Title Type',
  'label.GLOBAL_BOOK_ID': 'Global Book Id',
  'label.EDITION': 'Edition',
  'label.BOOK_ID': 'Book Id',
  'label.PRODUCT_ID': 'Product Id',
  'label.MODIFIED_BY': 'Modified By',
  'label.LAST_MODIFIED': 'Last Modified',
  'label.ISBN': 'ISBN',
  'label.DI_SUPPORTED': 'DI Supported',
  'label.SERVER_SIDE_ENABLED': 'Server Side Enabled',
  'label.S3_UPLOAD_STATUS': 'S3 Upload Status',
  'label.PDF_PLAYER_SUPPORTED': 'PDF Player Supported',
  'label.TOTAL_PAGES': 'Total pages',
  'label.PAGE_COUNT': 'Page count',
  'label.TOTAL_COUNT': 'Total count: ',
  'label.SERVER_SIDE_PROCESS_STATUS': 'Server Side Process Status',
  'label.DATE_CREATED': 'Created Date',
  'label.DATE_UPDATED': 'Updated Date',
  'label.EDIT': 'Edit',
  'label.UPDF_URL': 'uPDF URL',
  'label.VERSION': 'Version',
  'label.DATE': 'Date',
  'label.DATE_FROM': 'Date from',
  'label.DATE_TO': 'Date to',
  'label.ACTION_DATE': 'Date of Action',
  'label.WORKFLOW': 'Workflow',
  'label.ISBN_10': 'ISBN 10',
  'label.ISBN_13': 'ISBN 13',
  'label.ACTION_USER': 'Action User',
  'label.SUB_BUSINESS_UNIT': 'Sub Business',
  'label.DIRECTORY': 'Directory',
  'label.AUTHORING_SERVER_&_PUBLISH_STATUS': 'Server/ Publish Status to Authoring',
  'label.AUTHORING_SERVER': 'Authoring Server',
  'value.SERVER_SIDE_PROCESS_STATUS': ' ({successPages} out of {totalPages} pages succeed)',
  'label.SERVER_SIDE_PROCESS_UUID': 'Server Side Process UUID',
  'label.SERVER_SIDE_PROCESSED': 'Server Side Processed',
  'desc.EDIT': 'Review and Enter Book Metadata',
  'title.DISABLED_PREVIEW': "Preview is not available as S3 Upload Status & PDF Player Supported is 'N' for this title",
  'label.PREVIEW': 'Preview',
  'label.FOLDER': 'Folder',
  'label.FILE': 'File',
  'label.TYPE': 'Type',
  'label.SIZE': 'Size',
  'label.REQUEST': 'eText Project',
  'label.INGESTION': 'Ingestion',
  'label.BOOK': 'Book',
  'desc.PREVIEW': 'Review eText pages, Region Hotspots',
  'label.ENTITLEMENT_PUBLISH': 'Entitlement and Publish',
  'label.DISABLED_ENTITLEMENT_PUBLISH':
    'Entitlement and Publish is not available as the title is not server side processed.',
  'label.SPECIFY_BASEPATH': 'Basepath',
  'label.PREVIEW_HOTSPOT_TOGGLE': 'Preview',
  'label.POSITION_HOTSPOT_TOGGLE': 'Positioning',
  'label.REQUESTED_BY': 'Requested By',
  'label.REVISION_NO': 'Revision No',
  'label.REQUEST_NO': 'Request No',
  'label.ORIGINAL_REQUEST_DATE': 'Original Request Date',
  'desc.SPECIFY_BASEPATH': 'Specify Basepath',
  'desc.ENTITLEMENT_PUBLISH': 'Specify SMS/Rumba information, push content to production servers and make live',
  'label.HISTORY': 'History',
  'label.CANCEL_INGESTION': 'Cancel Ingestion',
  'desc.HISTORY': 'View Book history',
  'label.ICON': 'Icon',
  'desc.CUSTOM_ICON': 'Custom icon',
  'label.REVERT_BACK': 'Revert Back',
  // 'error.HEADER': 'Didn\'t load some information. ',
  'label.INTERFACE_COLOR': 'Colors',
  'label.CHATGPT': 'chatgptutility',
  'desc.INTERFACE_COLORS': 'Color & Player Settings',
  'error.HEADER': "Didn't load some information. ",
  'error.HEADER_LINK': 'Please try again',
  'error.NOT_READY_HEADER': "We're setting up your data. Hold tight.",
  'user.USER_MANAGEMENT_LABEL': 'User Management',
  'user.USER_MANAGEMENT_DESC': 'Manage user information',
  'user.SIGNOUT': 'Sign Out',
  'header.a11y.SKIP_TO_MAIN': 'Skip to main content',
  'footer.COPYRIGHT': 'Copyright \u00A9 {year} Pearson Education Inc. All Rights Reserved.',
  'footer.PRIVACY': 'Privacy Policy',
  'footer.TERMS_COND': 'Terms \u0026 Conditions',
  'footer.ACCESSIBILITY': 'Accessibility',
  // 'unauthorized.EXPLANATION_TEXT': '401 Error',
  'unauthorized.TITLE_TEXT': 'Unauthorized Access',
  /* 'unauthorizedbody.BODY_PRIVILEGES': 'Sorry, we tried but you don’t have permission to access this page.', */
  'unauthorized.BODY_TEXT':
    'Your privileges do not let you access this Page. Please contact your Pearson production manager for assistance with requesting access to this application.',
  'search.FILTER_BY': 'Filter by',
  'search.ENTER_TEXT_HERE': 'Enter text here',
  'metadata.BOOKTITLE': 'Book Title',
  'metadata.BOOKEDIION': 'Book Edition',
  'metadata.BOOKEDITIONTYPE': 'Book Edition Type',
  'metadata.AUTHORS': 'Authors',
  'metadata.COPYRIGHTYEAR': 'Copyright year',
  'metadata.ISBN': 'ISBN',
  'metadata.DESCRIPTION': 'Description',
  'metadata.ORIENTATION': 'Orientation',
  'metadata.BOOKTOCFLATTEN': 'Book TOC Flatten (PDF Player)',
  'metadata.BOOKTOCLEVEL': 'Book TOC Levels',
  'metadata.BOOKTOCSELECT': 'Number to Show',
  'metadata.COVERART': 'Cover Art',
  'metadata.MINHOTSPOT': 'Minimum Hotspot Height',
  'metadata.ROLE': 'Role',
  'metadata.Author': 'Add an author',
  'metadata.SAVE_BOOK_METADATA': 'Save Book Metadata',
  'metadata.CANCEL_EDIT_BOOK_METADATA': 'Cancel Editing Book Metadata',
  'metadata.TITLE_TEXT':
    'Review and enter/edit book metadata information using the section below.Click on the Task Complete when you are done with reviewing this section.',
  'entitle.ENTER': 'ENTER',
  'entitle.SMS': 'SMS',
  'entitle.OR': 'or',
  'entitle.RUMBA': 'RUMBA',
  'entitle.SAVE_ENTITLEMENT_SETTINGS': 'Save Entitlement Settings',
  'entitle.CANCEL_EDIT_ENTITLEMENT_SETTINGS': 'Cancel Editing Entitlement Settings',
  'entitle.TITLE_TEXT': 'information for this eText in the appropriate section(s) below.',
  'entitle.SUBTITLE_TEXT': 'Primary checkbox denotes Book specific Module/Resource ID',
  'entitle.PUBLISH_ETEXT': 'Publish to eText',
  'entitle.PUBLISH_TITLE_ETEXT': 'Publish title to eText',
  'entitle.UNPUBLISH_ETEXT': 'Unpublish from eText',
  'entitle.PUBILSH_DIGITAL_LIBRARY': 'Publish to Digital Library',
  'entitle.DESCRIPTION':
    'Click on the Publish button to make the book available in the viewer platform. Click on Unpublish to make the book unavailable in the viewer platform.',
  'specify.BASEPATH': 'Basepath',
  'specifybasepath.ASSETFILEPATH': 'Asset File Path',
  'breadcrumbs.ENTITLEMENT': 'Entitlement',
  'entitle.BOOK_STATUS': 'Book Status',
  'entitle.PUBLISH_DATE': 'Publish Date',
  'entitle.PUBLISH_SUCCESS': 'The Publish Info is successfully updated.',
  'entitle.PUBLISH_ERROR': 'Error occured while saving the Publish Info.',
  'entitle.VALIDATION_ERROR_IS_PRIMARY_11':
    'Primary SMS Module ID is blank for Scenario 11 (Full Book Access). Please enter a primary SMS Module ID in order to proceed.',
  'entitle.VALIDATION_ERROR_IS_PRIMARY_88':
    'Primary SMS Module ID is blank for Scenario 11 (Disaggregated Access).Please enter a primary SMS Module ID in order to proceed.',
  'entitle.VALIDATION_ERROR_11':
    'The SMS Module ID cannot be left blank in any row of Scenario 11 (Full Book Access). Either delete the unused row or enter a valid SMS Module ID in order to proceed.',
  'entitle.VALIDATION_ERROR_88':
    'The SMS Module ID cannot be left blank in any row of Scenario 11 (Disaggregated Access). Either delete the unused row or enter a valid SMS Module ID in order to proceed.',
  'entitle.VALIDATION_ERROR_MODULEID_IS_PRIMARY_1': 'Primary SMS Module ID is blank for Scenario',
  'entitle.VALIDATION_ERROR_MODULEID_1': 'The SMS Module ID cannot be left blank in any row of Scenario',
  'entitle.VALIDATION_ERROR_MODULEID_IS_PRIMARY_2': 'Please enter a primary SMS Module ID in order to proceed.',
  'entitle.VALIDATION_ERROR_MODULEID_2':
    'Either delete the unused row or enter a valid SMS Module ID in order to proceed.',
  'entitle.VALIDATION_ERROR_PDF_PLAYER':
    'This title is not PDF Player supported. Please deselect the ‘PDF Player’ checkbox from respective scenarios in order to proceed.',
  'entitle.VALIDATION_ERROR_NON_PRIMARY_11':
    'Please make Primary SMS Module ID Live for Scenario 11 (Full Book Access) in order to proceed.',
  'entitle.VALIDATION_ERROR_NON_PRIMARY_88':
    'Please make Primary SMS Module ID Live for Scenario 11 (Disaggregated Access) in order to proceed.',
  'entitle.VALIDATION_ERROR_NON_PRIMARY_1': 'Please make Primary SMS Module ID Live for Scenario',
  'entitle.VALIDATION_ERROR_NON_PRIMARY_2': 'in order to proceed.',
  'entitle.VALIDATION_ERROR_MODULEID_NON_PRIMARY_11':
    'Please make SMS Module ID Live for Scenario 11 (Full Book Access) in order to proceed.',
  'entitle.VALIDATION_ERROR_MODULEID_NON_PRIMARY_1': 'Please make SMS Module ID Live for Scenario',
  'entitle.VALIDATION_ERROR_MODULEID_NON_PRIMARY_2': 'in order to proceed.',
  'entitle.VALIDATION_ERROR_PRODUCTID_11':
    'A Product ID must be entered for Integration Scenario 11 (Full Book Access).',
  'entitle.VALIDATION_ERROR_PRODUCTID_88':
    'A Product ID must be entered for Integration Scenario 11 (Disaggregated Access).',
  'entitle.VALIDATION_ERROR_PRODUCTID': 'A Product ID must be entered for Integration Scenario',
  'entitle.VALIDATION_ERROR_URL_11': 'A URL must be entered for Integration Scenario 11 (Full Book Access).',
  'entitle.VALIDATION_ERROR_URL_88': 'A URL must be entered for Integration Scenario 11 (Disaggregated Access).',
  'entitle.VALIDATION_ERROR_URL': 'A URL must be entered for Integration Scenario',
  'entitle.VALIDATION_ERROR_EMPTY_MODULEID_11':
    'Module ID must be defined before saving purchase details for Integration Scenario 11 (Full Book Access).',
  'entitle.VALIDATION_ERROR_EMPTY_MODULEID_88':
    'Module ID must be defined before saving purchase details for Integration Scenario 11 (Disaggregated Access).',
  'entitle.VALIDATION_ERROR_EMPTY_MODULEID':
    'Module ID must be defined before saving purchase details for Integration Scenario',
  'entitle.VALIDATION_ERROR_IPAD_NOT_SUPPORTED_SMS':
    'This book is not compatible for Mobile and Android. Please unselect Mobile and Android check boxes in SMS Scenario:1 to proceed.',
  'entitle.VALIDATION_ERROR_IPAD_NOT_SUPPORTED_RUMBA':
    'This book is not compatible for Mobile and Android. Please unselect Mobile and Android check boxes in RUMBA Scenario:16 to proceed.',
  'entitle.VALIDATION_ERROR_RUMBA_ISPRIMARY_1': 'Primary RUMBA Resource ID is blank for Scenario',
  'entitle.VALIDATION_ERROR_RUMBA_ISPRIMARY_2': 'Please enter a primary RUMBA Resource ID in order to proceed.',
  'entitle.VALIDATION_ERROR_RUMBA_NON_PRIMARY_1': 'The RUMBA Resource ID cannot be left blank in any row of Scenario',
  'entitle.VALIDATION_ERROR_RUMBA_NON_PRIMARY_2':
    'Either delete the unused row or enter a valid RUMBA Resource ID in order to proceed.',
  'entitle.VALIDATION_ERROR_RUMBA_NON_ACTIVE_PRIMARY_1': 'Please make Primary RUMBA Resource ID Live for Scenario',
  'entitle.VALIDATION_ERROR_RUMBA_NON_ACTIVE_PRIMARY_2': 'in order to proceed.',
  'entitle.VALIDATION_ERROR_RUMBA_NON_ACTIVE_NON_PRIMARY_1': 'Please make RUMBA Resource ID Live for Scenario',
  'entitle.VALIDATION_ERROR_RUMBA_NON_ACTIVE_NON_PRIMARY_2': 'in order to proceed.',
  'entitle.VALIDATION_ERROR_EMPTY_RESOURCEID':
    'RUMBA Resource ID cannot be blank. Please Enter RUMBA Resource ID before Saving or Publishing the eText for Integration Scenario',
  'entitle.VALIDATION_ERROR_EMPTY_SAVE': 'Please select is-live for at least one scenario.',
  'tooltip.ICON_HOTSPOT': 'Icon Hotspot',
  'tooltip.PAGE_HOTSPOT': 'Page Hotspot',
  'tooltip.PREVIEW_HOTSPOT_ON_OFF': 'Turn Hotspot Preview Mode On or Off',
  'tooltip.POSITION_HOTSPOT_ON_OFF': 'Turn Hotspot Positioning Mode On or Off',
  'hotspot.HOTSPOT_PROPERTIES': 'Hotspot Properties',
  'hotspot.LINK_TYPE': 'Link Type',
  'hotspot.LINK_VALUE': 'Link Value',
  'hotspot.LINK_XYWH': 'Link X/Y/W/H',
  'hotspot.MEDIA_WH': 'Media W/H',
  'hotspot.SEARCH_STRING': 'Search String',
  'hotspot.X': 'X',
  'hotspot.Y': 'Y',
  'hotspot.W': 'W',
  'hotspot.H': 'H',
  'hotspot.TYPE': 'Type',
  'hotspot.ROLE': 'Role',
  'hotspot.ICON': 'Icon',
  'hotspot.TRANSPARENT': 'Transparent',
  'hotspot.NAME': 'Name',
  'hotspot.GLOSS_ENTRY_ID': 'Gloss Entry ID',
  'hotspot.PLATFORM': 'Platform',
  'hotspot.READY_TO_PUBLISH': 'Ready To Publish',
  'hotspot.DESCRIPTION': 'Description',
  'icon.CUSTOM_ICON': 'Custom Icon',
  'hotspot.DELETE_CONFIRMATION': 'Are you sure you want to delete this hotspot ?',
  'upload.DELETE_CONFIRMATION': 'Are you sure you want to permanently delete the objects ?',
  'upload.CREATE_FOLDER_CONFIRMATION': 'Title is not available. Would you like to create the new title ?',
  'hotspot.LTI_LINK_DIALOG': 'The LTI platform is {platformName} and the link value is {handler}',
  'hotspot.LTI_LINK_DIALOG_HEADER': 'LTI Link',
  'hotspot.PREVIEW_UNSUPPORTED':
    'Preview of this asset is not supported in Evergreen at the moment. Please preview the asset in OneReader post publishing the Title',
  'hotspot.HOTSPOT_UNSUPPORTED': 'The selected asset is no longer supported.',
  'hotspot.LABEL_POSITIONING': 'Positioning',
  'hotspot.LABEL_METADATA': 'Metadata',
  'icon.HEADER': 'Uploading Custom Icons and Viewing the Icon Palette',
  'icon.DESCRIPTION':
    'You may use the provided default palette of icons or upload custom icons for use in this book. Use in this book. use the sections below to upload custom icon and to view the current icon palette. Select which type of icon you wish to upload using the dropdown provided below. Find the file you wish to upload by clicking on the Browse button.',
  'icon.TEXT_ICONS': 'Upload eText Icons',
  'icon.MOBILE_ICONS': 'Upload Mobile Icons',
  'icon.PALETTE': 'View Current Icon Palette',
  'icon.HOTSPOT_TYPE_TEXT': 'All current icon with thier associated hotspot type are listed below',
  'icon.INFO':
    '*Indicates that a custom icon has replaced a default one. Click on revert to default icon to use the default icon for this link type',
  'basepath.HEADER':
    'Use the section below to specify asset file paths for this book. Click Save when you are done with the section.',
  'basepath.BREAD_CRUMBS': 'Basepath',
  'basepath.SAVE_BASEPATH': 'Save Basepath',
  'basepath.CANCEL_EDIT_BASEPATH': 'Cancel Editing Basepath',
  'basepath.ASSET_FILE_PATH': 'Asset File Path',
  'interface.SCHEME': 'Color & Player Settings',
  'interface.HIGHTLIGHT': 'Hightlighting Scheme',
  'interface.TEXT':
    'Use this section to define color & player settings for this book. You can define the hex color code (ex: #000fff) for different section of the viewer. Use the save button to save the color & Player settings.',
  'interface.HIGHTLIGHT_TEXT':
    'Use this section below to specify the highlighting options available to instructors and students for this book.',
  'interface.AUDIO': 'Audio Text Synch Options',
  'interface.AUDIO_TEXT': 'Use this section to specify your settings for audio text synch.',
  'interface.UNDERLINE_HOTSPOT_COLOR': 'Underline hotspot color',
  'interface.HOTSPOT_HOVER': 'Underline hotspot hover color',
  'interface.HOTSPOT_THICKNESS': 'Underline hotspot thickness',
  'interface.PDZ_PRODUCT': 'PDZ Product ID',
  'interface.PRINT_SELECTION': 'Print Selection',
  'interface.AUDIO_TEXT_SYNCH_UP': 'Audio Text Synch Up',
  'interface.PLAYER': 'Player',
  'interface.PRINTING_ENABLED': 'Printing Enabled',
  'interface.AUDIO_ONEREADER': 'Audio Player Setting - OneReader',
  'interface.STUDY_ONEREADER': 'Study Tool Setting - OneReader',
  'interface.SERVER_SIDE_ENABLED_ONEREADER': 'Server Side Enabled - OneReader',
  'interface.AUDIO_TEXT_SYNCH': 'Audio Text Synch',
  'interface.MULTIPLE_AUDIO_FLOW': 'Multiple Audio Flow',
  'interface.UNDERLINE_HOTSPOT': 'Underline hotspot',
  'interface.REGIONAL_HOTSPOT_ALPHA': 'Regional hotspot alpha',
  'interface.HOTSPOT_COLOR': 'Hotspot color',
  'interface.SAVE_INTERFACE_SETTINGS': 'Save Interface Settings',
  'interface.CANCEL_INTERFACE_SETTINGS': 'Cancel Interface Settings',
  'interface.PRINT_PAGE_COUNT': 'Print Page Count',
  'interface.PRINT_PAGE_TOTAL_COUNT': 'Total Pages Count',
  'interface.SLIDER_PERCENTAGE': 'Print Percentage 0-100%',
  'server.TITLE_TEXT': 'Please enter the Global Book Id using the section below to start the server side processing.',
  'server.ENTER_GLOBAL_BOOK_ID_HERE': 'Enter global book id',
  'server.ENTER_MULTI_GLOBAL_BOOK_ID_HERE': 'To process mulitple Books, please provide comma separated globalBookIds',
  'dashboard.HEADER_TEXT': 'Dashboard',
  'dashboard.CM_LABEL': 'Ingestion Workflow',
  'dashboard.CM_DESC': 'Ingest/Reingest Titles, check workflow status',
  'dashboard.AUTHORING_LABEL': 'Authoring/Preview',
  'dashboard.AUTHORING_DESC': 'Search, Preview, Review & Edit Hotspots, Publish Titile',
  'dashboard.ETEXTDB_LABEL': 'Etext Project',
  'dashboard.ETEXTDB_DESC': 'Submit a request for new Title conversion',
  'dashboard.USER_MGMT_LABEL': 'User Management',
  'dashboard.USER_MGMT_DESC': 'Manage Users, Roles & permissions',
  'dashboard.REPORTS_LABEL': 'Reports',
  'dashboard.REPORTS_DESC': 'Manage & View Reports',
  'contentmanager.STANDARD': 'Standard',
  'contentmanager.SIMPLE': 'Simple',
  'contentmanager.SPDF': 'Spdf',
  'contentmanager.WORKFLOW': 'Queue Status',
  'contentmanager.WORKFLOW_HISTORY': 'Ingestion Workflow History',
  'contentmanager.IDENTIFIER': 'Identifier',
  'contentmanager.TITLE': 'Title',
  'contentmanager.AUTHORS': 'Authors',
  'contentmanager.WORK_FLOW': 'WorkFlow',
  'contentmanager.BUSINESS_UNIT': 'Business Unit',
  'contentmanager.TITLE_TYPE': 'Content Type',
  'contentmanager.JOBS': 'Jobs',
  'contentmanager.GLOBAL_BOOK_ID': 'Global Book ID',
  'contentmanager.BOOK_ID': 'Book ID',
  'contentmanager.PROCESS_XML': 'Glossary XML, Basket XML, Region Info XML & Audio XML',
  'contentmanager.PDF': 'PDF Cropping, Thumbnail & Text',
  'contentmanager.LOW': 'Low Res PDF generation',
  'contentmanager.PERFORM': 'Perform PDF Scaling',
  'contentmanager.PROCESS_FROM': 'Process From Optimized PDF',
  'contentmanager.ANNOTATED': 'Process Annotated PDF (Create Zoomarea)',
  'contentmanager.DIGITAL': 'Process For Digital Library',
  'contentmanager.INGESTION_PREFERNECE': 'Ingestion Preferences',
  'contentmanager.UPLOAD_TEXT_EPUB': 'Upload your source PDF or ePub Package & associated XML / XLS files here',
  'contentmanager.UPLOAD_TEXT': 'Upload your source PDF & associated XML / XLS files here',
  'contentmanager.UPLOAD_HELPERTEXT': 'Filename should not contain # character',
  'contentmanager.UPLOAD_HELPERTEXTSIZE': 'Total file size: 0.00 MB / 20 MB',
  'contentmanager.SUBMIT': 'Submit',
  'contentmanager.INGESTION': 'Ingest',
  'contentmanager.UPDF': 'uPDF',
  'contentmanager.TITLE_ACTION': 'Title Action',
  'contentmanager.CREATEPACKAGE': 'Create Derivative Package',
  'contentmanager.TITLE_INGESTION_EPUB': 'Title Ingestion(PDF, SPDF or ePub)',
  'contentmanager.TITLE_INGESTION': 'Title Ingestion(Standard & Simple e-book)',
  'contentmanager.IDENTIFIER_ISBN': 'Identifier / ISBN (10 OR 13) :',
  'contentmanager.BUSINESSUNIT': 'Business unit : ',
  'contentmanager.TITLE_BACKUP': 'Backup',
  'contentmanager.TITLE_MANAGE_BOOKINFO': 'Manage BookInfo',
  'contentmanager.TITLE_MANAGE_MANIFST': 'Manage Manifest',
  'contentmanager.TITLE_MIGRATE': 'Migrate',
  'contentmanager.TITLE_EXPORT_EXCEL': 'Export To Excel',
  'contentmanager.TITLE_CREATE_ASSETS': 'Create Assets',
  'contentmanager.TITLE_UPLOAD_AUTHORING': 'Upload To Authoring',
  'contentmanager.CREATE_BTN': 'Create',
  'contentmanager.CREATE_TITLE': 'Create Title',
  'contentmanager.VALIDATE_TITLE': 'Validate Title',
  'contentmanager.VALIDATE_BTN': 'Validate',
  'contentmanager.CHOOSEFILES': 'Choose Files',
  'contentmanager.CHOOSEFOLDERS': 'Choose Folders',
  'contentmanager.UPLOAD_CONTENT': 'Upload Content',
  'contentmanager.UPLOAD_CONTENT_IF_NOT_UPLOADED': 'Upload Source Assets/PDFs (If not uploaded already)',
  'contentmanager.UPLOAD': 'Upload',
  'contentmanager.UPLOAD_ETEXT_ICON': 'Upload eText Icon',
  'contentmanager.UPLOAD_MOBILE_ICON': 'Upload Mobile Icon',
  'contentmanager.VIEW_COVER': 'View Cover Image',
  'contentmanager.VIEW_IPAD': 'View iPad Cover pdf',
  'contentmanager.PREV': 'Prev',
  'contentmanager.NEXT': 'Next',
  'contentmanager.INGEST': 'Ingest Title for Authoring/Preview',
  'contentmanager.BACKUP': 'Initiate Backup of already ingested Title',
  'contentmanager.BOOKINFO': 'Modify/Update BookInfo for Simple eBook Workflow',
  'contentmanager.MANIFEST': 'Modify/Update Manifest for Simple eBook Workflow',
  'contentmanager.EXPORT': 'Export Title including changes done in Authoring/Preview',
  'contentmanager.AUTO': 'Auto generate Cover Image & Thumbnail for a Title',
  'contentmanager.UPDF_BATCH': 'Process to create Universal PDF for previously ingested Title',
  'contentmanager.STATUS': 'Queue Status',
  'contentmanager.INGEST_TAB': 'Ingest',
  'contentmanager.TITLE_TAB': 'Title Action',
  'contentmanager.CONFIRMTEXT': 'Do you want to create uPDf ?',
  'contentmanager.TITLEHISTORY': 'Title History',
  'contentmanager.SUBBUSINESS_UNIT': 'Sub Business Unit',
  'contentmanager.DIRECTORY': 'Directory',
  'contentmanager.SELECTED_EDITION': 'Selected Edition As',
  'contentmanager.GENERATE_DERIVATIVE': 'Generate Derivative Package',
  'contentmanager.PARENT_EBOOK': 'Parent eBook Id',
  'contentmanager.GENERATE_DERIVATIVE_EBOOK': 'Generate Derivative e-Book',
  'contentmanager.COPYSERVER': 'Copy To Server',
  'contentmanager.VERIFY': 'Verify',
  'contentmanager.SPDF_TITLE': 'SPDF Functionality coming soon',
  'eTextconversion.TEXT': 'Project Request Queue Status',
  'eTextconversion.UPDATE_ETEXT_CONVERSION': 'Update eText Conversion',
  'eTextconversion.DELTE_ETEXT_CONVERSION': 'Delete eText Conversion',
  'eTextrequest.TEXT': 'HED/INTL eText Conversion Request',
  'eTextrequest.PUBLISH': 'Publishing Company / Division',
  'eTextrequest.CONTACT': 'Contact Information',
  'eTextrequest.PARENT': 'Parent Print Information',
  'eTextrequest.UPLOAD_SOURCE_FILES': 'Upload Source Assets/PDFs',
  'eTextrequest.GENERAL': 'General eText Information',
  'eTextrequest.USER': 'User Interface Options',
  'eTextrequest.TYPESOURCE': 'Type of source files being submitted?',
  'eTextrequest.DISCIPLINECODE': 'Discipline Code',
  'eTextrequest.eText': 'eText UI Language',
  'eTextrequest.IPAD': 'iPad view required?',
  'eTextrequest.COVER': 'Cover Artwork Information',
  'eTextrequest.ARTWORK': 'Cover Artwork',
  'eTextrequest.CONVERSION': 'eText Conversion',
  'eTextrequest.GENERALINFO': 'General/Contact Info',
  'eTextrequest.MOST_RECENT': 'Most recent comments from BU',
  'eTextrequest.PRODUCTION': 'Production Notes',
  'eTextrequest.SCHEDULE_STATUS': 'Schedule Status:',
  'eTextrequest.INFORMATION': 'eText Information',
  'eTextInfo.HEXNUMBER': 'Hex Numbers: visible:',
  'eTextInfo.UNDERLINE': 'underline:',
  'eTextInfo.HOVER': 'hover:',
  'eTextrequest.COVERARTWORKTXT':
    'Cover art asset guidelines should be reviewed carefully to ensure that the proper number and format of required cover images is provided for production. Art Asset Guidelines are',
  'eTextrequest.COVERARTWORKTXTNOTE':
    'NOTE: If you choose to have the eText production vendor create your three cover images, you waive all rights to request changes to their designs.',
  'eTextrequest.REQUIRED': 'Required eText TOC',
  'eTextrequest.TOC': 'TOC Spreadsheet',
  'eTextrequest.eTEXTOPTION': 'eText Options',
  'eTextrequest.Contents': 'Contents Links / Hotspots',
  'eTextrequest.INDEX': 'Index Links / Hotspots',
  'eTextrequest.GLOSARRY': 'Glossary Spreadsheet',
  'eTextrequest.CROSS': 'Cross Reference Hotspots',
  'eTextrequest.CUSTOM': 'Custom Hotspots Spreadsheet',
  'eTextrequest.HOTSPOT': 'Hotspot UI',
  'eTextrequest.CUSTOMICONS': 'Custom Icons',
  'eTextrequest.CUSTOMBASKETS': 'Custom Baskets Spreadsheet',
  'eTextrequest.NONMODAL': 'Non-Modal Audio',
  'eTextrequest.SPECIAL': 'Special Sections Information',
  'eTextrequest.SPECIALTXTBOOKS': 'Special book features that should be converted ?',
  'eTextrequest.RIGHTS': 'Rights & Permissions Section',
  'eTextrequest.CORPOARATERIGHTS':
    'Corporate Rights Policy is now requiring that a Digital Confirmation Form (DCF) for your title be uploaded for all Higher Ed (Arts & Sciences or Professional & Career) eBook conversion requests. This is not required for International, PTG or PLS eBooks. The Digital Confirmation Form confirms that your product can be converted to an eBook format. Contact Rights and Permissions to receive the DCF for your title. Rights & Permissions contacts can be found on NEO here.',
  'eTextrequest.SPECIALSUBTXT':
    'Please indicate what would you like each of your supplemental sections to be called along with their exact placement in the hierarchy of the Table of Contents. For example: End Papers will be called Graphs and Charts and will be located after the index',
  'eTextrequest.SPECIALTXT':
    'When producing the etext, the exact placement of content as listed in your textbook Table of Contents will be used. If you will be submitting additional PDFs for special chapters/sections not indicated in the textbook Table of Contents,please list these additional sections in the box(es) below. Special section content should only be material which is essential to the integrity of the textbook. Special sections which deal exclusively with marketing copy are not necessary to include in your product. The associated PDFs for each of the special sections must be supplied at time of file upload for conversion. Do not request these sections if you have not arranged to upload associated PDF files. The PDFs must adhere to the eText PDF guidelines. If the original PDFs are formatted as spreads they must be split up into individual pages.',
  'eTextrequest.UPLOAD': 'Upload DCF',
  'eTextrequest.CONFIRMATION':
    'The Digital Confirmation Form lists ANY assets that have Rights Restrictions. If there are assets that need to be masked out, check the box below and then copy/paste these assets from the DCF into the provided text field.',
  'eTextrequest.COPYdESC':
    'Copy the description of items to be masked out from the Digital Confirmation Form and paste below',
  'eTextrequest.RETAINCAPTION':
    'If you would like to retain any caption text associated with non-permissioned images, figures, and/or charts, please list these items below. Otherwise, all captions will be removed.',
  'eTextrequest.RETAINITEMS': 'Retain captions for these items',
  'eTextrequest.COMMENTS': 'Additional Comments',
  'eTextrequest.HEDINTL': 'HED/INTL eText Revision Request',
  'eTextrequest.REVISIONS': 'Revisions',
  'eTextrequest.INDICATE':
    'Indicate the type of change you would like to your eText title. All changes, except for title/author/edition updates and permission changes, will require the upload of revised files to our FTP server. These files should be posted following the instructions located in the eText Reprint Process document.',
  'eTextrequest.LIST':
    'Please list details regarding the revisions you would like to your eText title in the space below. Be as descriptive as possible.',
  'eTextrequest.DESCRIPTION': 'Description of item(s) to revise',
  'eTextrequest.FILELOCATION': 'File Location',
  'eTextrequest.GUIDELINES':
    'Guidelines on organizing and uploading textbook required files for revising eText titles are available in the eText File Submission Guidelines. If your revision requires new files or spreadsheets for revisions, these files must be uploaded prior to submitting this form and only files for those chapters or pages which need updating should be provided. These files should be posted following the instructions located in the eText Reprint Process document.',
  'eTextrequest.PLEASEREVIEW':
    'Please review Content Manager FTP in case your project folder with content still exists. This is to avoid duplication of files on server. If not, enter the exact folder name of the source files, cover images, and any necessary spreadsheets that have been uploaded to the FTP site for processing. An example of a correctly-named folder would be "0205777503_sayre". Consult the eText File Submission Guidelines for more details on appropriate folder naming conventions.',
  'eTextreivsion.ISBN-10': 'eText ISBN-10',
  'eTextreivsion.ISBN-13': 'eText ISBN-13',
  'eTextreivsion.BOOKID': 'Book Id',
  'eTextreivsion.INSIDE': 'Inside Front Cover',
  'eTextreivsion.PERMISSION': 'Permission change (masking/unmasking of items)',
  'eTextreivsion.NEW': 'New or revised hotspot links',
  'eTextreivsion.NEWGLOSSARY': 'New or revised glossary content',
  'eTextreivsion.PAGE': 'Page additions or subtractions',
  'eTextreivsion.CHANGE': 'Change in content (textual/image/icons)',
  'eTextreivsion.UPDATE': 'Update to title, edition, and /or author(s)',
  'eTextreivsion.TOCCHANGE': 'TOC change',
  'eTextreivsion.CHANGECOVER': 'Change to cover image',
  'eTextreivsion.IPADVIEW': 'iPad view',
  'eTextreivsion.UPDATEPRODUCT': 'Update Product Type to Demo/Partial eText or Full eText',
  'eTextreivsion.OTHER': 'Other',
  'eTextreivsion.SPECIALBOOK': 'Special book features that should be converted ?',
  'eTextreivsion.INSIDEBACK': 'Inside Back Cover',
  'eTextreivsion.COLORINSERTS': 'Color Inserts',
  'eTextreivsion.BONUSCHAPTER': 'Bonus Chapters',
  'eTextreivsion.TEARCARD': 'Tear Card',
  'eTextreivsion.ENDPAPERS': 'End Papers',
  'eTextreivsion.PLEASEINDICATE':
    'Please indicate what would you like each of your supplemental sections to be called along with their exact placement in the hierarchy of the Table of Contents. For example: End Papers will be called Graphs and Charts and will be located after the index',
  'eTextreivsion.TYPESREVISION': 'Type of Revision',
  'eTextreivsion.PERMISSIONCHANGE': 'Permission change (masking/unmasking of items)',
  'eTextreivsion.NEWORREVHOTSPOT': 'New or revised hotspot links',
  'eTextreivsion.NEWORREVGLOSSARY': 'New or revised glossary content',
  'eTextreivsion.PAGEADDITIONS': 'Page additions or subtractions',
  'eTextreivsion.CHANGEINCONTENT': 'Change in content (textual/image/icons)',
  'eTextreivsion.UPDATETOTITLE': 'Update to title, edition, and /or author(s)',
  'eTextreivsion.CHANGETOCOVER': 'Change to cover image',
  'eTextreivsion.UPDATEPRODUCTIONTYPE':
    'Digital Library Required   (only request Digital Library if you are sure it is needed at this time)',
  'eTextreivsion.UPDATEPRODUCTTYPE': 'Update Product Type to Demo/Partial eText or Full eText',
  'eTextreivsion.FOLDERNAME': 'Folder Name',
  'eTextconvergence.ATLEAST': '(Atleast one should be selected in below radio group)',
  'eTextconvergence.SUPPLIED': 'Supplied',
  'eTextconvergence.NOTNEEDED': 'Not needed (XML from ETM is complete for TOC ingestion)',
  'eTextconvergence.NOTSUPPLIEDVENDOR':
    'Not supplied (vendor will create from the exact listing of the textbook TOC PDF file)',
  'eTextconvergence.SUPPLYADDITIONAL':
    'I am supplying additional sections which are not listed on my textbook TOC page and this requires a pagination chart. The vendor will create my spreadsheet from this pagination chart.',
  'eTextconvergence.NOTSUPPLIED': 'Not supplied',
  'eTextconvergence.NOTETMTEXT': 'Not needed (XML from ETM is complete for Glossary ingestion)',
  'eTextconvergence.NOTVENDORPDF': 'Not supplied (vendor will manually create from the Glossary PDF file)',
  'eTextconvergence.NOTTITLE': 'Not necessary for this title',
  'eTextconvergence.WHATTHIS': 'Whats This ?',
  'eTextconvergence.NOTNEEDEDHOTSPOT': 'Not needed (XML from ETM is complete for all hotspot ingestion)',
  'eTextconvergence.VENDORLINK': 'Vendor will manually link/hotspot Index (page numbers only)',
  'eTextgeneral.DIVISION': 'Division',
  'eTextgeneral.LANGUAGE': 'UI Langauge',
  'eTextgeneral.VENDOR': 'Vendor',
  'eTextgeneral.MANAGEDBY': 'Managed By',
  'eTextgeneral.SOURCE': 'Source',
  'eTextgeneral.IPADVIEW': 'Ipad View',
  'eTextgeneral.DIGITALLIBRARY': 'Digital Library',
  'eTextgeneral.UIPAGE': 'UI 1st page',
  'eTextgeneral.INTLREGION': 'Intl Region',
  'eTextgeneral.PRODUCTION': 'Production Status',
  'eTextgeneral.TOTAL': 'Total number of pages in eText product',
  'eTextgeneral.GENERAL': 'eText Product Type',
  'eTextgeneral.CHARGEISBN': 'Charge ISBN',
  'eTextgeneral.COMPOSITOR': 'Compositor',
  'eTextgeneral.ISBNORID': 'PIMS information',
  'eTextgeneral.DEMO': 'Demo/Partial eText',
  'eTextgeneral.FULLETEXT': 'Full eText',
  'eTextgeneral.XML': 'XML from ETM and Hi-res PDFs',
  'eTextgeneral.HIRES': 'Hi-res PDFs only',
  'eTextrevision.DESCRIPTION': 'Description of item(s) to revise',
  'eTextconversion.SUBMIT_ETEXT_CONVERSION': 'Submit eText Conversion Request',
  'eTextrevision.SUPPLEMENTAL': 'Supplemental section information',
  'eTextrevision.REVISION': 'eText Revision',
  'eTextrevision.UPDATE_ETEXT_REVISION': ' Update eText Revision',
  'eTextrevision.DELETE_ETEXT_REVISION': 'Delete eText Revision',
  'eTextrevision.GENERAL': ' General/ Contact Info :',
  'eTextrevision.INITIAL': 'Initial comments from BU :',
  'eTextrevision.PRODUCTION': 'Production Notes :',
  'eTextrevision.SUBMIT_ETEXT_REVISION': 'Submit eText Revision Request',
  'eTextrevision.SCHEDULE': ' Schedule Status :',
  'eTextrevision.ETEXTISBN10': ' eText ISBN-10',
  'eTextrevision.ETEXTISBN13': ' eText ISBN-13',
  'eTextrevision.PARENTISBN10': ' Parent ISBN-10',
  'eTextrevision.PARENTISBN13': ' Parent ISBN-13',
  'eTextrevision.REVISION_DETAILS': ' Revision Details',
  'eTextgeneral.CHARGEISBNREV': 'ChargeISBN',
  'eTextrevision.OTHERS': ' Others',
  'eTextrevision.UPDATE': ' Update',
  'eTextrevision.DELETE_ETEXT': ' Delete eText',
  'eTextrevision.DUMMYTEXT': 'Select UI Language for dummy request',
  'eTextrevision.BOOKIDTEXT':
    'We could not find eText request for Book ID you provided. If you want to create a dummy eText Conversion request for this Book ID then press "Yes" else "No"',
  'eTextupdf.SPREADSHEET': 'Spreadsheet Name',
  'eTextupdf.SEARCHBY': 'Search By',
  'eTextupdf.BATCHID': 'Batch Id',
  'eTextupdf.CREATE_UPDF': 'Create uPDF',
  'eTextgeneral.NEW': 'New Request Form',
  'eTextgeneral.REVISION': 'Revision Request Form',
  'eTextgeneral.REQUEST': 'Revision Queue',
  'eTextInfo.COMPOSITOR': 'compositor',
  'eTextInfo.PIMSINFO': 'PIMS Info',
  'eTextInfo.PAGECOUNT': 'Page Count',
  'eTextInfo.AUDIOSYNCH': 'Audio Synch',
  'eTextInfo.INSIDEFRONTCOVER': 'Inside Front Cover',
  'eTextInfo.SOURCEFILE': 'Source File',
  'eTextInfo.DISCIPLINE': 'Discipline Code',
  'eTextInfo.TYPEETEXT': 'eText Type',
  'eTextInfo.REMOVEALL': 'Remove all captions/figs',
  'eTextInfo.RETAINFIGS': 'Retain these captions/figs:',
  'eTextInfo.NONEXCLUDE': 'Non-Permissioned to Exclude:',
  'eTextInfo.SECINFO': 'Supplemental section information',
  'eTextInfo.TOCSPREAD': 'TOC Spreadsheet',
  'eTextInfo.GLOSSARY': 'Glossary Spreadsheet',
  'eTextInfo.CONTENTSLINK': 'Contents Links/Hotspots',
  'eTextInfo.INDEXLINKS': 'Index Links/Hotspots',
  'eTextInfo.CROSSREF': 'Cross Reference Hotspots',
  'eTextInfo.HOTSPOTSPREADSHEET': 'Hotspots Spreadsheet',
  'eTextInfo.CUSTOMBASKET': 'Custom Baskets Spreadsheet',
  'eTextInfo.HOTSPOTUI': 'Hotspots UI',
  'eTextInfo.UNDERLINECOLOR': 'Underline hotspot color',
  'eTextInfo.UNDERLINEHOVER': 'Underline hotspot hover',
  'eTextInfo.INITIALREVISION': 'InitialRevisionRequested',
  'eTextInfo.REVISIONID': 'RevisionID',
  'eTextInfo.REVISIONSTATUS': 'RevisionStatus',
  'eTextInfo.TYPEREVISION': 'Typerevision',
  'eTextconversion.REQUEST': 'Request #',
  'eTextconversion.REVISION': 'Revision #',
  'eTextconversion.ISBN': 'eText ISBN10',
  'eTextconversion.ISBNS': 'eText ISBN13',
  'eTextconversion.PARENTISBN': 'Parent ISBNs10',
  'eTextconversion.PARENTISBNS': 'Parent ISBNs13',
  'eTextconversion.TITLEEDITION': 'Title/Edition',
  'eTextconversion.AUTHORS': 'Author(s)',
  'eTextconversion.BOOKID': 'bookID',
  'eTextconversion.GLOABALBOOKID': 'GlobalBookID',
  'eTextconversion.CHARGEISBN': 'Charge ISBN',
  'eTextconversion.TITLE': 'Title',
  'eTextconversion.AUTHOR': 'Author',
  'eTextconversion.COPYRIGHT': 'Copyright',
  'eTextconversion.NAME': 'Name',
  'eTextconversion.EMAIL': 'Email',
  'eTextconversion.ADDITIONALEMAIL': 'Additional Email',
  'eTextconversion.REQFORM': 'Req.Form Submitted',
  'eTextconversion.COMPLETION': 'Estimated Completion',
  'eTextconversion.FOLDERNAME': 'Folder Name',
  'eTextconversion.REVIEWCOUNT': 'Review Count',
  'eTextconversion.PROJECT': 'Project Initiated',
  'eTextconversion.AVAILABLE': 'Available Revisions',
  'eTextconversion.ADJUSTED': 'Adjusted Projection',
  'eTextconversion.HOLDDAYS': '# of hold days',
  'eTextconversion.OVERALLDATE': 'Overall Completion Date',
  'user.ROLES': 'Roles & Permission',
  'user.PERMISSIONS_LABEL': "You don't have required permissions",
  'query.GET_QUERY_RESULT': 'Get Query Result',
  'query.ENTER_QUERY': 'Please Enter Query',
  'query.RESET_QUERY': 'Reset Query',
  'query.WHERE_CLAUSE_IS_MISSING': 'Where Clause is missing from Query',
  'query.FROM_KEYWORD_MISSING': 'From Keyword is missing from Query',
  'query.ONLY_SELECT_QUERY': 'Only Select Query is Supported',
  'user.ROLENAME': 'Role Name: ',
  'user.DESC': 'Role Description: ',
  'user.PERMISSION': 'Permission',
  'user.FAVORITE': 'Add to Favorite',
  'user.USER_MANAGEMENT': 'User Manangement',
  'user.ETEXT_PROJECT': 'eText Project',
  'user.PREVIEW': 'Authoring & Preview',
  'user.REPORTS': 'Reports',
  'user.ADMIN_TEXT':
    'This role provides Admin access to all the features like eText Project, Ingestion Workflow,Authoring/Preview and Reports.',
  'user.USERLIST': 'User List',
  'user.USERACTION': 'User Edit',
  'user.USERADD': 'User Add',
  'user.LIST': 'List',
  'user.EDIT': 'Edit',
  'user.ADD': 'Add',
  'user.USERS': 'Users',
  'useraction.FIRSTNAME': 'First Name',
  'useraction.LASTNAME': 'Last Name',
  'useraction.USERLOGIN': ' User ID',
  'useraction.EMAIL': 'Email',
  'useraction.ROLE': 'Role',
  'useredit.PERMISSION': 'View Permission',
  'user.ADD_USER': 'Add User',
  'user.SAVE_USER_DETAILS': 'Save User Details',
  'user.RESET': 'Reset All Fields',
  'user.EDIT_DETAILS': 'Edit User Details',
  'user.CANCEL_ADDING_NEW_USER': 'Cancel Adding New User',
  'user.CANCEL_EDITING_USER': 'Cancel Editing User Details',
  'bulkutility.TITLE_BULKACTION': 'Bulk Action Processor',
  'bulkutility.UPLOAD_EXCEL': 'Upload Your SpreadSheet Here',
  'bulkutility.TITLE_BULKINGESTION': 'Bulk Ingestion',
  'bulkutility.BULK_MIGRATION': 'Bulk Migration',
  'bulkutility.UUID': 'Ingestion UUID',
  'bulkutility.CONTENT_PATH': 'Content Path',
  'bulkutility.TOTAL_RECORDS': 'Total Records',
  'bulkutility.FAILED_RECORDS': 'Failed Records',
  'bulkutility.ACTION_USER': 'Action User',
  'bulkutility.STATUS': 'Status',
  'bulkutility.UPLOADED_DATE': 'Uploaded Date',
  'bulkutility.TITLE_MIGRATION': 'Please Enter Product Ids to migrate',
  'bulkutility.TEXT': 'To process mulitple Titles, please provide comma separated Ids',
  'bulkutility.TEXT1': ' Please Note: Enter all Ids of same Region',
  'bulkutility.CONTENT_ID': 'Content Id',
  'bulkutility.VERSION': 'Version',
  'bulkutility.CONTENT_TYPE': 'Content Type',
  'bulkutility.UPDATED_TIME': 'Updated Time',
  'bulkutility.VIEW_STATUS': 'View Status',
  'bulkutility.MIGRATION_STATUS': 'Migration Status',
  'bulkutility.REGION': 'Region',
  'env.PROD': 'PROD',
  'env.QA': 'QA',
  'mcq.ADD': 'Add New Question',
  'mcq.DELETE': 'Delete Question',
  'mcq.EDIT': 'Edit Question',
  'mcq.PUBLISH': 'Publish Question',
  'mcq.UN_PUBLISH': 'Un-Publish Question',
  'mcq.NEXT': 'Next Question',
  'mcq.PREVIOUS': 'Previous Question',
  'mcq.PUBLISHALL': 'Publish All',
  'summary.ADD': 'Add New Summary',
  'summary.DELETE': 'Delete Summary',
  'summary.EDIT': 'Edit Summary',
  'summary.PUBLISH': 'Publish Summary',
  'summary.UN_PUBLISH': 'Un-Publish Summary',
  'summary.NEXT': 'Next Summary',
  'summary.PREVIOUS': 'Previous Summary',
  'admin.ADD': 'Add',
  'admin.UPDATE': 'Update',
  'chatUtility.HOME': 'Go to ChatGptutilty Home',
  'chatUtility.AUDIT_LOG': 'Audit Logs',
  'chatUtility.USER_FEEDBACK': 'User Feedback',
  'chatUtility.TRENDS_INSIGHTS': 'Trends & Insights',
  'chatUtility.REPORTS': 'Reports',
  'admin.ADD_NEW_PROMPT': 'Add New Prompt',
  'prompt.PUBLISH': 'Publish Prompt',
  'chatUtility.USER_INPUT': 'User Input/Command',
  'chatUtility.AI_RESPONSE': 'AI Study Tool Response',
  'chatUtility.PROMPT': 'Prompt',
  'effectiveness.INITIATE_NEW': 'INITIATE NEW',
  'chatUtility.PROMPT_MANANGEMENT': 'Prompt Management',
  'chatUtility.SUMMARY_QUIZ_MANANGEMENT': 'Content Management',
  'effectiveness.LLM_EVALUATION': 'LLM Evaluation'
};

export default en;

/**
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc. All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Framework, { shapes } from '@greenville/framework';
import { inject, observer } from 'mobx-react';
import { FormattedMessage } from 'react-intl';
import {
  FormControl,
  TextField,
  Typography,
  Button,
  Grid,
  Paper,
  Box,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  RadioGroup
} from '@material-ui/core';
import BulkAssetsTable from './BulkAssetsTable';
import BulkIngestionLog from '../models/BulkIngestionLog';
import BulkAssets from '../models/BulkAssets';
import environment from '../../../common/env';
import * as constants from '../../../common/constants';
import BulkIngestionConfig from '../../../common/config/BulkIngestionConfig';

const styles = (theme) => ({
  container: {
    marginBottom: 20
  },
  gridItem: {
    marginTop: 20
  },
  paddingRadioGrp: {
    padding: '0 10spx'
  },
  labelRadioGrp: {
    fontSize: 14
  },
  paddingTopZero: {
    paddingTop: 18
  },
  formControl: {
    marginLeft: 20,
    marginRight: 20,
    minWidth: 120
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'left',
    color: theme.palette.text.secondary
  },
  textareaWidth: {
    width: '60ch'
  },
  boxWidth: {
    paddingTop: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingLeft: theme.spacing(4)
  },
  filterControl: {
    minWidth: 300
  }
});
@inject('bulkIngestionLog', 'bulkAssets')
@observer
class BulkMigration extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    language: shapes.language.isRequired,
    bulkIngestionLog: shapes.modelOf(BulkIngestionLog).isRequired,
    bulkAssetStatus: shapes.state.isRequired,
    bulkAssets: shapes.modelOf(BulkAssets).isRequired,
    data: PropTypes.object.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      searchText: null,
      data: {},
      regionId: null,
      region: '',
      env: 'PROD'
    };
  }

  componentDidMount() {
    const { bulkAssets } = this.props;
    bulkAssets.fetchAssets();
    Framework.getEventManager().on(constants.SET_PULSE_ASSETS, () => {
      this.setState({ data: bulkAssets.data });
    });
  }

  handleTextChange = (value) => {
    this.setState({
      searchText: value
    });
  };

  startMigrating = (bookId) => {
    const { bulkIngestionLog } = this.props;
    const { region, env } = this.state;
    const booksToProcessArr = bookId.replace(/\s+/g, '').split(',').filter(String);
    const payload = { productIds: booksToProcessArr, region, env };
    bulkIngestionLog.start(payload);
    this.setState({ searchText: bookId });
  };

  handleSelectionChange = (event, value) => {
    // const { region } = this.state;
    if (value === 'region') {
      this.setState({
        region: BulkIngestionConfig.region.find((data) => data.id === event.target.value).value,
        regionId: event.target.value
      });
    }
  };

  handleChange = (event, value) => {
    if (value === 'env') {
      this.setState({
        env: event.target.value
      });
    }
  };

  render() {
    const { classes, language, bulkAssets } = this.props;
    const { searchText, data, regionId, region, env } = this.state;

    return (
      <div className={classes.container}>
        <Paper className={classes.paper} elevation={3} mb={3}>
          <Typography variant="h3" align="center" gutterBottom spacing={3}>
            <FormattedMessage {...language.getText('bulkutility.TITLE_MIGRATION')} />
          </Typography>
          <Grid container direction="column" alignItems="center" justify="space-between">
            <Grid item>
              <FormControl className={classes.formControl}>
                <TextField
                  label={<FormattedMessage {...language.getText('bulkutility.TEXT')} />}
                  multiline
                  rows={7}
                  className={classes.textareaWidth}
                  value={searchText}
                  onChange={(e) => this.handleTextChange(e.target.value)}
                  InputProps={{}}
                />
              </FormControl>
            </Grid>
            <Box mb={0.5} mr={1} className={classes.boxWidth}>
              <FormControl fullWidth className={classes.filterControl}>
                <InputLabel>
                  <FormattedMessage {...language.getText('bulkutility.REGION')} />
                </InputLabel>

                <Select
                  className={classes.labelWidht}
                  name="region"
                  defaultValue="0"
                  value={regionId}
                  onChange={(event) => this.handleSelectionChange(event, 'region')}
                >
                  {BulkIngestionConfig.region.map((filter) => (
                    <MenuItem value={filter.id}>{filter.value}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
            {!(environment.ENVIRONMENT === 'prod') ? (
              <Grid>
                <Box ml={3}>
                  <RadioGroup
                    value={env}
                    onChange={(event) => this.handleChange(event, 'env')}
                    row
                    aria-label="Migration env Radio group"
                  >
                    <FormControlLabel
                      className={classes.paddingTopZero}
                      value={constants.ENV_QA}
                      name="qaRadioInput"
                      control={<Radio color="primary" className={classes.paddingRadioGrp} />}
                      label={
                        <span className={classes.labelRadioGrp}>
                          <FormattedMessage {...language.getText('env.QA')} />
                        </span>
                      }
                    />
                    <FormControlLabel
                      className={classes.paddingTopZero}
                      value={constants.ENV_PROD}
                      name="prodRadioInput"
                      control={<Radio color="primary" className={classes.paddingRadioGrp} />}
                      label={
                        <span className={classes.labelRadioGrp}>
                          <FormattedMessage {...language.getText('env.PROD')} />
                        </span>
                      }
                    />
                  </RadioGroup>
                </Box>
              </Grid>
            ) : (
              ''
            )}
            <Grid item className={classes.gridItem}>
              <Button
                variant="outlined"
                color="primary"
                size="small"
                disabled={!region}
                onClick={() => this.startMigrating(searchText)}
              >
                <FormattedMessage {...language.getText('common.MIGRATE')} />
              </Button>
            </Grid>
          </Grid>
        </Paper>
        <br />

        <Paper variant="outlined">
          <Box m={1}>
            <BulkAssetsTable data={data} bulkAssets={bulkAssets} />
          </Box>
        </Paper>
      </div>
    );
  }
}

export default withStyles(styles)(observer(inject('language')(BulkMigration)));

import Framework, { shapes } from '@greenville/framework';
import { Box, Paper } from '@material-ui/core';
import TablePagination from '@mui/material/TablePagination';
import { BarElement, CategoryScale, Chart as ChartJS, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import TableComponent from '../../../../common/components/Table';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import * as constants from '../../../../common/constants';
import ExportToCSV from '../../common/components/ExportToCSV';
import AIChartsUtilityModel from '../../models/AIChartsUtility';
import AIChartsDialog from './AIChartsDialog';
import ChatgptUtilityIESUser from '../../models/ChatgptIESUser';
import AIFeedbackChartsDetailsModel from '../../models/AIFeedbackChartsDetails';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const AIChartsComponent = (props) => {
  const {
    aiChartsUtility,
    searchFields,
    ChatgptUtilityIESUserData,
    aiFeedbackChartsDetails,
    handleOnSearch,
    isSearchClicked
  } = props;
  const [chartsData, setChartsData] = useState({});
  const [tableData, setTableData] = useState([]);
  const [selectedValue, setSelectedValue] = useState({});
  const [userEmailDetails, setUserEmailDetails] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [modalIndex, setModalIndex] = useState(0);
  const [order, SetOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = useState('');

  const isSearchCompleted = (param) => {
    handleOnSearch(param);
  };

  useEffect(() => {
    if (isSearchClicked) {
      const getChartsData = async () => {
        const payload = {
          ...(searchFields?.bookId && searchFields?.bookId !== constants.ALL_BOOKS && { bookId: searchFields?.bookId }),
          ...(searchFields?.startDate && { startDate: searchFields.startDate }),
          ...(searchFields?.endDate && { endDate: searchFields.endDate })
        };
        aiChartsUtility.fetch(payload);
        Framework.getEventManager().on(constants.SET_AI_CHARTS_UTILITY_DATA, () => {
          const { data } = aiChartsUtility;
          const response = toJS(data);
          if (response) {
            const filteredRes = response.filter((count) => count.likeCount >= 2 && count.dislikeCount >= 2);
            const orgLables = filteredRes.map((typ) => typ.type);
            const types = filteredRes.map((item) =>
              item.type
                .replace(/discuss/g, 'Explain')
                .replace(/_/g, ' ')
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')
            );
            const likeCounts = filteredRes.map((item) => item.likeCount);
            const dislikeCounts = filteredRes.map((item) => item.dislikeCount);

            const chartResData = {
              respLables: orgLables,
              labels: types,
              datasets: [
                {
                  label: 'LIKE',
                  data: likeCounts,
                  backgroundColor: 'rgba(75, 192, 192, 0.5)',
                  barThickness: 20,
                  stack: 'stack0' // Use a stack group to align bars
                },
                {
                  label: 'DISLIKE',
                  data: dislikeCounts,
                  backgroundColor: 'rgba(255, 99, 132, 0.5)',
                  barThickness: 20,
                  stack: 'stack1' // Use a different stack group to align bars
                }
              ]
            };
            setChartsData(chartResData);
          } else {
            setTableData([]);
            setChartsData({});
          }
        });
        /* eslint-disable camelcase */
        Framework.getEventManager().on(constants.SET_CHATGPT_IES_USER_DATA, () => {
          const { userData } = ChatgptUtilityIESUserData;
          if (userData.length > 0) {
            setUserEmailDetails(userData[userData.length - 1]);
          }
        });
        isSearchCompleted(false);
      };

      getChartsData();
    }
  }, [isSearchClicked]);

  /* eslint-disable */
  const handleBarClick = (event, elements) => {
    if (elements.length > 0) {
      const chartElement = elements[0];
      const datasetIndex = chartElement.datasetIndex;
      const dataIndex = chartElement.index;
      const type = chartsData.respLables[dataIndex];
      const feedback = chartsData.datasets[datasetIndex].label;
      const payload = {
        ...(searchFields?.bookId && searchFields?.bookId !== constants.ALL_BOOKS && { bookId: searchFields?.bookId }),
        ...(searchFields?.startDate && { startDate: searchFields.startDate }),
        ...(searchFields?.endDate && { endDate: searchFields.endDate }),
        type,
        feedback
      };
      aiFeedbackChartsDetails.fetch(payload);
      Framework.getEventManager().on(constants.SET_AI_CHARTS_UTILITY_LIST_DATA, () => {
        const { data } = aiFeedbackChartsDetails;
        const response = toJS(data);
        if (response) {
          setTableData(response);
          setPage(0);
        }
      });
    }
  };
  /* eslint-enable */

  const onRowClick = (val, index) => {
    setOpenDialog(true);
    setModalIndex(index);
    setSelectedValue(val);
  };

  const handleClose = () => {
    setOpenDialog(false);
    setModalIndex(1);
    setSelectedValue({});
  };

  const handleModalChange = (value) => {
    if (value === 'prev' && modalIndex > 0) {
      setSelectedValue(tableData[modalIndex - 1]);
      setModalIndex(modalIndex - 1);
    } else {
      setSelectedValue(tableData[modalIndex + 1]);
      setModalIndex(modalIndex + 1);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const setFromToValueForPagination = (from, to, count) => {
    return `${from}-${to} of ${count}`;
  };

  const handleAscending = (dir, value) => {
    if (dir === 'asc') {
      const sorted = [...tableData].sort((a, b) => (a[value] > b[value] ? 1 : -1));
      setTableData(sorted);
    } else {
      const sorted = [...tableData].sort((a, b) => (a[value] < b[value] ? 1 : -1));
      setTableData(sorted);
    }
  };

  const sorting = (value) => {
    if (order === 'ASC') {
      handleAscending('asc', value);
      SetOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC') {
      handleAscending('des', value);
      SetOrder('ASC');
      setDirectionValue(value);
    }
  };

  const onColumnClick = (val) => {
    sorting(val);
  };

  const hanldeGETUserId = (userId) => {
    const { userData } = ChatgptUtilityIESUserData;
    if (userId) {
      const dataMatch = userData && userData.find((item) => item.userId === userId);
      if (dataMatch) {
        setUserEmailDetails(dataMatch);
      } else {
        ChatgptUtilityIESUserData.fetch({ userId });
      }
    }
  };

  const onMouseEnter = (val) => {
    hanldeGETUserId(val);
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top'
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const label = context.dataset.label || '';
            const value = context.raw || 0;
            return `${label}: ${value}`;
          }
        }
      }
    },
    hover: {
      mode: 'nearest',
      intersect: true
    },
    interaction: {
      mode: 'nearest',
      intersect: true
    },
    elements: {
      bar: {
        hoverBorderWidth: 2
      }
    },
    scales: {
      x: {
        stacked: false,
        grid: {
          display: false
        },
        ticks: {
          autoSkip: false
        }
      },
      y: {
        stacked: true, // Stack bars on the y-axis if needed
        beginAtZero: true
      }
    },
    onClick: (event, elements) => handleBarClick(event, elements)
  };

  return (
    <>
      {!_.isEmpty(chartsData?.labels) && chartsData?.labels.length > 0 && (
        <Paper style={{ padding: '10px', marginBottom: '10px' }}>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <div
              style={{
                width: '60%',
                display: 'flex',
                alignItems: 'center',
                flexDirection: 'column',
                cursor: 'pointer'
              }}
            >
              <h2>User Feedback Chart</h2>
              <Bar data={chartsData} options={chartOptions} />
            </div>
          </div>
        </Paper>
      )}
      {!_.isEmpty(tableData) && tableData.length > 0 && (
        <>
          <ExportToCSV
            headers={ColumnConfig.AIStudyChartsFeedbackColumn}
            data={tableData}
            fileTitle="AIUserFeedBackData"
          />
          <Paper
            style={{
              maxHeight: '500px',
              overflow: 'hidden',
              overflowY: 'scroll',
              borderRadius: '2px',
              border: '1px solid #0003',
              padding: '10px'
            }}
          >
            <Box sx={{ width: '100%' }} style={{ marginTop: '30px' }}>
              <TableComponent
                columns={ColumnConfig.AIStudyChartsFeedbackColumn}
                data={tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
                onColumnClick={onColumnClick}
                order={order}
                onRowClick={onRowClick}
                directionValue={directionValue}
                userEmailDetails={userEmailDetails}
                onMouseEnter={onMouseEnter}
                isFromCharts
              />
              <TablePagination
                component="div"
                count={tableData.length}
                labelDisplayedRows={({ from, to, count }) =>
                  useMemo(() => setFromToValueForPagination(from, to, count), [from, to, count])
                }
                page={page}
                onPageChange={handleChangePage}
                rowsPerPage={rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{ display: 'flex', justifyContent: 'center' }}
              />
            </Box>
          </Paper>
        </>
      )}
      {openDialog && (
        <AIChartsDialog
          openChat={openDialog}
          dataLength={tableData.length}
          handleClose={handleClose}
          dataValue={selectedValue}
          modalIndex={modalIndex}
          handleModalChange={handleModalChange}
          isFeedback
        />
      )}
    </>
  );
};

AIChartsComponent.propTypes = {
  aiChartsUtility: shapes.modelOf(AIChartsUtilityModel).isRequired,
  aiFeedbackChartsDetails: shapes.modelOf(AIFeedbackChartsDetailsModel).isRequired,
  searchFields: PropTypes.object.isRequired,
  ChatgptUtilityIESUserData: shapes.modelOf(ChatgptUtilityIESUser).isRequired,
  handleOnSearch: PropTypes.func.isRequired,
  isSearchClicked: PropTypes.bool.isRequired
};

export default inject(
  'aiChartsUtility',
  'aiFeedbackChartsDetails',
  'ChatgptUtilityIESUserData'
)(observer(AIChartsComponent));

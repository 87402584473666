import Framework, { LoadingHandler, shapes } from '@greenville/framework';
import { Box, Grid, MenuItem, Paper, Select, Typography, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PreviewIcon from '@mui/icons-material/Preview';
import Button from '@mui/material/Button';
import axios from 'axios';
import _ from 'lodash';
import { toJS } from 'mobx';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from 'react-router-dom';
import Loader from '../../../../common/Loader';
import DialogBox from '../../../../common/components/DialogBox';
import TableComponent from '../../../../common/components/Table';
import TooltipCustom from '../../../../common/components/TooltipCustom';
import ColumnConfig from '../../../../common/config/ColumnConfig';
import * as constants from '../../../../common/constants';
import env from '../../../../common/env';
import utils from '../../../../common/utils';
import UsersPermissions from '../../../users/models/UsersPermissions';
import chatGptAdminPromptsData from '../../models/ChatGptAdminPrompts';
import ChatgptAdmin from '../../models/ChatgptAdmin';
import AdminPromptDialog from './AdminPromptDialog';
import AdminPromptEvaluateDialog from './AdminPromptEvaluateDialog';

const useStyles = makeStyles({
  button: {
    backgroundColor: '#005d83 !important',
    color: 'white !important'
  }
});

const AdminPromptComponent = (props) => {
  const classes = useStyles();
  const {
    chatgptAdminPrompts,
    chatgptAdmin,
    ChatGptGetAdminPromptsStatus,
    ChatGptUtilityAddAdminStatus,
    ChatGptEditAdminPromptsStatus,
    userPermissions,
    language
  } = props;
  const history = useHistory();
  const [archived, setArchived] = useState(false);
  const [showCloneIcon, setShowCloneIcon] = useState(false);
  const [isDialogboxOpen, setIsDialogboxOpen] = useState(false);
  const [rowItem, setRowItem] = useState('');
  const [itemName, setItemName] = useState('');
  const [archiveDialogContent, setArchiveDialogContent] = useState(null);
  const [promptsData, setPromptsData] = useState([]);
  const [archieveData, setArchievedData] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [openEvaluateDialog, setOpenEvaluateDialog] = useState(false);
  const [order, setOrder] = useState('ASC');
  const [directionValue, setDirectionValue] = useState('');
  const [selectedValue, setSelectedValue] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [tenantKey, setTenantKey] = useState('');
  const [userToken, setUserToken] = useState('');
  const [apiLoading, setApiLoading] = useState(false);
  const permissionsList = toJS(userPermissions.permissionList.permissions);
  const [promptStatusValue, setpromptStatusValue] = useState('Live');
  const [promptTypeValue, setpromptTypeValue] = useState('');
  const promptStatusList = [
    { id: 'Live', value: 'Live' },
    { id: 'Archived', value: 'Archived' },
    { id: 'Draft', value: 'Draft' },
    { id: 'Experiment', value: 'Experiment' }
  ];
  const [types, setTypes] = useState([]);
  const [promptResponseData, setPromptResponseData] = useState([]);
  const getPromptsData = async (tenantIdVal) => {
    setApiLoading(true);
    const headers = {
      Authorization: `Bearer ${userToken}`
    };
    const response = await axios.post(
      `${env.EVERGREEN_API_BASE_URL}${constants.GET_ADMIN_PROMPTS_URL}`,
      { tenantId: tenantIdVal },
      { headers }
    );
    if (response && response.data && response.data.data) {
      const responseData = response.data.data;
      const archieve = response.data.data.filter((item) => item.status === 'ARCHIVED');
      const unArchieve = response.data.data.filter((item) => item.status === 'LIVE');
      setPromptResponseData(responseData);
      setArchievedData(archieve);
      setPromptsData(unArchieve);
      setArchived(true);
      setShowCloneIcon(true);
      setApiLoading(false);
    }
  };

  useEffect(() => {
    const token = utils.getToken();
    setUserToken(token);
  }, []);

  useEffect(() => {
    if (!_.isEmpty(userToken)) {
      let tenantIdVal = '';
      if (history.location.pathname === '/chatgptutility/channels/promptmgt') {
        setTenantKey('channels');
        tenantIdVal = constants.CHANNELS_TENANT_ID;
      }
      if (history.location.pathname === '/chatgptutility/aistudytools/promptmgt') {
        setTenantKey('AiStudyTool');
        tenantIdVal = constants.AI_STUDY_TOOLS_TENANT_ID;
      }
      getPromptsData(tenantIdVal);
      Framework.getEventManager().on(constants.SET_ADMIN_PROMPTS_UPDATED_DATA, () => {
        getPromptsData(tenantIdVal);
      });
    }
  }, [userToken]);

  useEffect(() => {
    let tenantKeyName = '';
    if (history.location.pathname === '/chatgptutility/channels/promptmgt') {
      tenantKeyName = 'channels';
    }
    if (history.location.pathname === '/chatgptutility/aistudytools/promptmgt') {
      tenantKeyName = 'AiStudyTool';
    }
    const tenantVal = tenantKeyName === 'AiStudyTool' ? 'aiStudyTools' : 'channels';
    chatgptAdmin.fetchTenantData(tenantVal);
    /* eslint-disable camelcase */
    Framework.getEventManager().on(constants.SET_ADMIN_DATA, () => {
      const { groups } = chatgptAdmin;
      if (groups && groups.length > 0) {
        const typesData = groups.filter((item) => item.name === 'PromptTypes')[0];
        setTypes(typesData);
      }
    });
  }, []);

  const sorting = (value, archivedStatus) => {
    const sortData = archivedStatus ? archieveData : promptsData;
    const promptArrow = ['promptStart', 'promptEnd'].includes(value);
    const dateFieldMatch = ['createdDate', 'updatedDate'].includes(value);
    if (order === 'ASC' && !promptArrow) {
      let sorted;
      if (dateFieldMatch) {
        sorted = [...sortData].sort((a, b) => a[value] && b[value] && new Date(a[value]) - new Date(b[value]));
      } else {
        sorted = [...sortData].sort((a, b) =>
          a[value] && b[value] && a[value].toLowerCase() > b[value].toLowerCase() ? 1 : -1
        );
      }
      if (archivedStatus) {
        setArchievedData(sorted);
      } else {
        setPromptsData(sorted);
      }
      setOrder('DSC');
      setDirectionValue(value);
    }
    if (order === 'DSC' && !promptArrow) {
      let sorted;
      if (dateFieldMatch) {
        sorted = [...sortData].sort((a, b) => a[value] && b[value] && new Date(b[value]) - new Date(a[value]));
      } else {
        sorted = [...sortData].sort((a, b) =>
          a[value] && b[value] && a[value].toLowerCase() < b[value].toLowerCase() ? 1 : -1
        );
      }
      if (archivedStatus) {
        setArchievedData(sorted);
      } else {
        setPromptsData(sorted);
      }
      setOrder('ASC');
      setDirectionValue(value);
    }
  };

  const onColumnClick = (val, archivedStatus) => {
    sorting(val, archivedStatus);
  };

  const changeToRawText = (promptVal) => {
    const { promptStart, promptEnd } = promptVal;
    const promptStartRawText = promptStart?.map((item) => {
      return { ...item, content: JSON.stringify(item.content, null, 2).slice(1, -1) };
    });
    const promptEndRawText = promptEnd?.map((item) => {
      return { ...item, content: JSON.stringify(item.content, null, 2).slice(1, -1) };
    });
    return { ...promptVal, promptStart: promptStartRawText, promptEnd: promptEndRawText };
  };

  const onRowClick = (val) => {
    setIsEdit(true);
    setOpenDialog(true);
    setSelectedValue(changeToRawText(val));
  };

  const handleClose = () => {
    setOpenDialog(false);
  };

  const handleAddNewPrompts = () => {
    setOpenDialog(true);
    setSelectedValue({});
    setIsEdit(false);
  };

  const handleTextFieldHandler = (e) => {
    e.preventDefault();
    setItemName(e.target.value);
  };

  const handleArchivedclick = (event, item) => {
    setArchiveDialogContent('');
    setRowItem(item);
    setIsDialogboxOpen(true);
    event.stopPropagation();
  };

  const handleArchivedClose = () => {
    setIsDialogboxOpen(false);
    setItemName('');
  };

  const getNextVersion = (versionValue) => {
    const regex = /^(\d+)\.(\d+)\.(\d+)$/;
    const match = regex.exec(versionValue);
    const majorVersion = parseFloat(match[1]);
    const minorVersion = parseFloat(match[2]);
    const patchVersion = parseFloat(match[3]);
    return `${majorVersion}.${minorVersion}.${patchVersion + 1}`;
  };

  const handleClone = async (event, item) => {
    if (item.version) {
      const versionData = [...promptsData, ...archieveData]
        .filter((val) => val.type === item.type)
        .map((d) => ({
          version: d.version
        }));
      const sortedVersionData = versionData.sort((a, b) => {
        const lowNumArr = a.version.split('.').map(Number);
        const highNumArr = b.version.split('.').map(Number);
        /* eslint-disable-next-line */
        for (let i = 0; i < lowNumArr.length; i++) {
          if (lowNumArr[i] !== highNumArr[i]) {
            return highNumArr[i] - lowNumArr[i];
          }
        }
        return 0;
      });
      const payload = {
        type: item.type && item.type.toUpperCase(),
        status: constants.PROMPT_DRAFT_STATUS,
        version: getNextVersion(sortedVersionData[0].version),
        tenantId: tenantKey === 'AiStudyTool' ? constants.AI_STUDY_TOOLS_TENANT_ID : constants.CHANNELS_TENANT_ID,
        tenantName: tenantKey,
        promptStart: item.promptStart,
        promptEnd: item.promptEnd
      };
      await chatgptAdminPrompts.saveAdminPromptsData(payload);
    }
    event.stopPropagation();
    handleClose();
  };

  const handleConfirmAction = async (confirmed) => {
    if (confirmed && itemName) {
      const payload = {
        ...rowItem,
        type: rowItem.type && rowItem.type.toUpperCase(),
        status: 'LIVE',
        comments: itemName
      };
      await chatgptAdminPrompts.updateAdminPromptsData(payload);
    }
  };

  const getFilteredPromptData = (type, status) => {
    let archieve = promptResponseData.filter((item) => item.status === status);
    let unArchieve = promptResponseData.filter((item) => item.status === status);
    if (type !== '') {
      archieve = promptResponseData.filter((item) => item.status === 'ARCHIVED' && item.type === type);
      unArchieve = promptResponseData.filter((item) => item.status === status && item.type === type);
    }
    setArchievedData(archieve);
    setPromptsData(unArchieve);
  };

  const handlePromptStatusChange = (event) => {
    const promptTypeSelected = promptTypeValue.toUpperCase();
    const promptStatusSelected = event.target.value.toUpperCase();
    setpromptStatusValue(event.target.value);
    getFilteredPromptData(promptTypeSelected, promptStatusSelected);
  };

  const handleInputChange = (event) => {
    const promptTypeSelected = event.target.value.toUpperCase();
    const promptStatusSelected = promptStatusValue.toUpperCase();
    setpromptTypeValue(event.target.value);
    getFilteredPromptData(promptTypeSelected, promptStatusSelected);
  };

  const handleEvaluate = (event, item) => {
    setOpenEvaluateDialog(true);
    setSelectedValue(item);
    event.stopPropagation();
  };

  const handleEvaluateClose = () => {
    setOpenEvaluateDialog(false);
    setSelectedValue({});
  };

  const handleLLMEvaluationClick = () => {
    history.push('/chatgptutility/aistudytools/promptmgt/effectiveness');
  };

  return (
    <>
      <LoadingHandler
        loading={
          apiLoading ||
          ChatGptGetAdminPromptsStatus.isPending ||
          ChatGptUtilityAddAdminStatus.isPending ||
          ChatGptEditAdminPromptsStatus.isPending
        }
        loadingContent={<Loader />}
        content={
          <Paper style={{ padding: '10px' }}>
            <Grid container spacing={2} style={{ flexDirection: 'column' }}>
              <Box display="flex" style={{ justifyContent: 'flex-end', marginTop: '20px', marginRight: '30px' }}>
                <TooltipCustom
                  title={
                    !permissionsList.includes('admin_can_edit') ? (
                      <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                    ) : (
                      <FormattedMessage {...language.getText('admin.ADD_NEW_PROMPT')} />
                    )
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddIcon />}
                      onClick={handleAddNewPrompts}
                      disabled={!permissionsList.includes('admin_can_edit')}
                      className={classes.button}
                      style={{ marginRight: '8px' }}
                    >
                      Add New Prompt
                    </Button>
                  </span>
                </TooltipCustom>
                <TooltipCustom
                  title={
                    !permissionsList.includes('admin_can_edit') ? (
                      <FormattedMessage {...language.getText('user.PERMISSIONS_LABEL')} />
                    ) : (
                      <FormattedMessage {...language.getText('effectiveness.LLM_EVALUATION')} />
                    )
                  }
                >
                  <span>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<PreviewIcon />}
                      onClick={handleLLMEvaluationClick}
                      disabled={!permissionsList.includes('admin_can_edit')}
                      className={classes.button}
                    >
                      LLM Evaluation
                    </Button>
                  </span>
                </TooltipCustom>
              </Box>
            </Grid>
            <Grid container spacing={2} style={{ flexDirection: 'column' }}>
              <Box display="flex" style={{ marginLeft: '30px' }}>
                <Typography variant="h5" spacing={9} style={{ marginTop: '4px' }}>
                  Filter By Status
                </Typography>
                <Select
                  className={classes.labelWidht}
                  style={{ width: '100px', marginRight: '20px', marginLeft: '20px' }}
                  name="promptStatus"
                  value={promptStatusValue}
                  onChange={(event) => handlePromptStatusChange(event)}
                >
                  {promptStatusList.map((filter) => (
                    <MenuItem value={filter.id}>{filter.value}</MenuItem>
                  ))}
                </Select>
                <Typography variant="h5" spacing={9} style={{ marginTop: '4px' }}>
                  Filter By Type
                </Typography>
                <Select
                  style={{ width: '130px', marginLeft: '20px' }}
                  name="promptType"
                  onChange={handleInputChange}
                  value={promptTypeValue}
                >
                  {types &&
                    types.properties &&
                    types.properties.map((item) => <MenuItem value={item.id}>{item.display_name}</MenuItem>)}
                </Select>
              </Box>
            </Grid>
            <Box sx={{ width: '100%' }} style={{ marginTop: '30px' }}>
              {promptStatusValue === 'Archived' ? (
                <TableComponent
                  columns={ColumnConfig.AdminPromptsDataGridColumn}
                  data={archieveData}
                  onColumnClick={onColumnClick}
                  order={order}
                  onRowClick={onRowClick}
                  directionValue={directionValue}
                  userEmailDetails=""
                  onMouseEnter={() => {}}
                  archived={archived}
                  handleArchivedclick={handleArchivedclick}
                  disableRepublish={!permissionsList.includes('admin_can_edit')}
                />
              ) : (
                <TableComponent
                  columns={ColumnConfig.AdminPromptsDataGridColumn}
                  data={promptsData}
                  onColumnClick={onColumnClick}
                  order={order}
                  onRowClick={onRowClick}
                  directionValue={directionValue}
                  userEmailDetails=""
                  onMouseEnter={() => {}}
                  showCloneIcon={showCloneIcon}
                  handleClone={handleClone}
                  disableRepublish={!permissionsList.includes('admin_can_edit')}
                  status={promptStatusValue}
                  handleEvaluate={handleEvaluate}
                />
              )}
            </Box>
          </Paper>
        }
      />
      {openDialog && (
        <AdminPromptDialog
          openDialog={openDialog}
          handleClose={handleClose}
          selectedValue={selectedValue}
          isEdit={isEdit}
          tenantKey={tenantKey}
          permissionsList={permissionsList}
          language={language}
        />
      )}
      {isDialogboxOpen && (
        <DialogBox
          open={isDialogboxOpen}
          handleClose={handleArchivedClose}
          title={constants.ADMIN_PROMPT_RESTORE_DIALOG_TEXT}
          content={archiveDialogContent}
          handleConfirm={handleConfirmAction}
          handleTextFieldHandler={handleTextFieldHandler}
          itemName={itemName}
        />
      )}
      {openEvaluateDialog && (
        <AdminPromptEvaluateDialog
          openDialog={openEvaluateDialog}
          handleClose={handleEvaluateClose}
          selectedValue={selectedValue}
        />
      )}
    </>
  );
};

AdminPromptComponent.propTypes = {
  chatgptAdmin: shapes.modelOf(ChatgptAdmin).isRequired,
  chatgptAdminPrompts: shapes.modelOf(chatGptAdminPromptsData).isRequired,
  ChatGptGetAdminPromptsStatus: shapes.state.isRequired,
  ChatGptUtilityAddAdminStatus: shapes.state.isRequired,
  ChatGptEditAdminPromptsStatus: shapes.state.isRequired,
  userPermissions: shapes.modelOf(UsersPermissions).isRequired,
  language: shapes.language.isRequired
};

export default observer(
  inject(
    'chatgptAdmin',
    'chatgptAdminPrompts',
    'ChatGptGetAdminPromptsStatus',
    'ChatGptUtilityAddAdminStatus',
    'ChatGptEditAdminPromptsStatus',
    'userPermissions',
    'language'
  )(AdminPromptComponent)
);

/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */

/**
 * Generic functions
 */
import { EventProvider } from '@aquila/core';
import { PublicClientApplication } from '@azure/msal-browser';
import { marked } from 'marked';
import { toJS } from 'mobx';
import moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { msalConfig } from '../authConfig';
import metaConfig from './config/MetaConfig';
import * as constants from './constants';
import env from './env';

export default class utils {
  static convertSecondstoHoursandMin(readingTime) {
    const hours = Math.floor(readingTime / 3600);
    const min = Math.round((readingTime % 3600) / 60);

    return {
      hours,
      min
    };
  }

  static convertDate(date) {
    return Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 10, 30, 0);
  }

  static epochToUTC(epochTimestamp) {
    const date = new Date(epochTimestamp * 1000).toUTCString().replace('GMT', 'UTC');
    return date;
  }

  static setCookie() {
    let keys = window.sessionStorage.getItem(`${'msal.token.keys.'}${env.INT_AZURE_CLIENT_ID}`);
    keys = JSON.parse(keys).accessToken;
    if (keys !== null) {
      let value = window.sessionStorage.getItem(keys);
      value = JSON.parse(value);
      const { secret, expiresOn } = value;
      const expires = utils.epochToUTC(expiresOn);
      document.cookie = `myAzureProxySession= ${secret}; expires= ${expires}; path=/; domain=.pearson.com`;
    }
  }

  static getToken() {
    let keys = window.sessionStorage.getItem(`${'msal.token.keys.'}${env.INT_AZURE_CLIENT_ID}`);
    keys = JSON.parse(keys).accessToken;
    if (keys !== null) {
      let value = window.sessionStorage.getItem(keys);
      value = JSON.parse(value);
      const { secret } = value;
      return secret;
    }
    return null;
  }

  static uuid() {
    return uuidv4();
  }

  static convertBoolean(str) {
    let bool = false;
    if (str && str !== null && str !== '') {
      const lowerStr = str.toLowerCase();
      if (lowerStr === 'y') {
        bool = true;
      }
    }
    return bool;
  }

  static authorFullName(data) {
    return data
      .map(
        (row) =>
          `${row.firstName ? row.firstName : ''} ${row.otherName ? row.otherName : ''} ${
            row.lastName ? row.lastName : ''
          }`
      )
      .join(', ');
  }

  static isbnDisplayName(data) {
    return data.map((row) => `${row.displayISBN ? row.displayISBN : ''}`).join(', ');
  }

  static logEvent(event) {
    EventProvider.getEventManager().dispatch({
      event: event.event,
      category: event.category,
      bookId: window.sessionStorage.getItem('bookId'),
      userId: window.sessionStorage.getItem('userId'),
      data: {
        label: event.label,
        action: event.action,
        value: event.value
      }
    });
  }

  static async redirectToLogin() {
    // expire cookie
    const currentUrl = window.location.href;
    document.cookie = 'myAzureProxySession=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/; domain=.pearson.com';
    const msalInstance = new PublicClientApplication(msalConfig);
    await msalInstance.initialize().then(() => {
      msalInstance.logoutRedirect({
        postLogoutRedirectUri: currentUrl
      });
    });
  }

  static getEditionTypeValue = (editionTypeID) => {
    let editionTypeValue = '';
    if (editionTypeID && editionTypeID > 0) {
      const bookEdition = metaConfig.bookEditionTypeId.find((item) => item.id === editionTypeID);
      if (bookEdition) {
        editionTypeValue = ` (${bookEdition.value})`;
      }
    }
    return editionTypeValue;
  };

  static serverSideProcessStatus = (sspStatus) => {
    let status = null;
    if (sspStatus !== null) {
      switch (sspStatus) {
        case constants.STATUSES.FOXIT_PROCESS_FAILED:
        case constants.STATUSES.FOXIT_FILES_S3UPLOAD_FAILED:
        case constants.STATUSES.FAILED:
          status = 'common.FAILED';
          break;
        case constants.STATUSES.COMPLETED:
          status = 'common.SUCCESS';
          break;
        case constants.STATUSES.FOXIT_IN_PROGRESS:
        case constants.STATUSES.FOXIT_PROCESS_COMPLETED:
        case constants.STATUSES.FOXIT_FILES_S3UPLOAD_IN_PROGRESS:
          status = 'common.IN_PROGRESS';
          break;
        case constants.STATUSES.COVER_PAGE_NOT_FOUND:
          status = 'common.COVER_PAGE_NOT_FOUND';
          break;
        case constants.STATUSES.SLATES_NOT_FOUND:
          status = 'common.SLATES_NOT_FOUND';
          break;
        default:
          status = 'common.NOT_STARTED';
          break;
      }
    }
    return status;
  };

  static convertBytes = (bytes) => {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0;
    let n = parseInt(bytes, 10) || 0;

    // eslint-disable-next-line no-plusplus
    while (n >= 1024 && ++l) {
      n /= 1024;
    }

    return `${n.toFixed(n < 10 && l > 0 ? 1 : 0)} ${units[l]}`;
  };

  static disciplineCodeStructure = (dataArray) => {
    const disciplineCode = [];
    dataArray.forEach((discipline) => {
      disciplineCode.push({
        id: discipline.disciplineCodeId,
        value: `${discipline.discipline}${constants.PIPE}${
          discipline.subDiscipline !== null ? discipline.subDiscipline : ''
        }${constants.PIPE}${discipline.course}${constants.PIPE}${discipline.disciplineCode}`
      });
    });
    return disciplineCode;
  };

  static processStoreValue = (chatgptResponseUtilityStore) => {
    /* eslint-disable camelcase */
    let rowProcessed = {};
    const {
      summary,
      quiz,
      discuss,
      chat_enrichment,
      chat_intent,
      content_classifier,
      flashcards,
      topic,
      summary_enrichment,
      summaryTopic,
      quizValidateIntent,
      quizIntent,
      mcq,
      mcqMisconceptions,
      mcqSkillIdentifier,
      mcqQuestionGeneration,
      quizMcq,
      quizFreeResponse,
      freeResponse,
      mcqDistract,
      dictionarySummary,
      dictionaryQuiz,
      content_filter
    } = chatgptResponseUtilityStore;
    if (chatgptResponseUtilityStore) {
      const dictionarySummaryData = dictionarySummary && dictionarySummary.length > 0 ? dictionarySummary : [];
      const dictionaryQuizData = dictionaryQuiz && dictionaryQuiz.length > 0 ? dictionaryQuiz : [];
      const summaryData = summary && summary.length > 0 ? summary : [];
      const quizData = quiz && quiz.length > 0 && quiz ? quiz : [];
      const discussData = discuss && discuss.length > 0 && discuss ? discuss : [];
      const chatEnrichmentData = chat_enrichment && chat_enrichment.length > 0 ? chat_enrichment : [];
      const chatIntentData = chat_intent && chat_intent.length > 0 ? chat_intent : [];
      const flashcardsData = flashcards && flashcards.length > 0 ? flashcards : [];
      const topicData = topic && topic.length > 0 ? topic : [];
      const summaryEnrichmentData = summary_enrichment && summary_enrichment.length > 0 ? summary_enrichment : [];
      const summaryTopicData = summaryTopic && summaryTopic.length > 0 ? summaryTopic : [];
      const contentClassifier = content_classifier && content_classifier.length > 0 ? content_classifier : [];
      const quizValidateData = quizValidateIntent && quizValidateIntent.length > 0 ? quizValidateIntent : [];
      const quizIintentData = quizIntent && quizIntent.length > 0 ? quizIntent : [];
      const mcqData = mcq && mcq.length > 0 ? mcq : [];
      const mcqMisconceptionsData = mcqMisconceptions && mcqMisconceptions.length > 0 ? mcqMisconceptions : [];
      const mcqSkillIdentifierData = mcqSkillIdentifier && mcqSkillIdentifier.length > 0 ? mcqSkillIdentifier : [];
      const mcqQuestionGenerationData =
        mcqQuestionGeneration && mcqQuestionGeneration.length > 0 ? mcqQuestionGeneration : [];
      const quizMcqData = quizMcq && quizMcq.length > 0 ? quizMcq : [];
      const quizFreeResponseData = quizFreeResponse && quizFreeResponse.length > 0 ? quizFreeResponse : [];
      const freeResponseData = freeResponse && freeResponse.length > 0 ? freeResponse : [];
      const mcqDistractData = mcqDistract && mcqDistract.length > 0 ? mcqDistract : [];
      const contentFilterData = content_filter && content_filter.length > 0 ? content_filter : [];
      const combineData = [
        ...dictionarySummaryData,
        ...dictionaryQuizData,
        ...summaryData,
        ...discussData,
        ...quizData,
        ...chatEnrichmentData,
        ...chatIntentData,
        ...flashcardsData,
        ...topicData,
        ...contentClassifier,
        ...summaryEnrichmentData,
        ...summaryTopicData,
        ...quizValidateData,
        ...quizIintentData,
        ...mcqData,
        ...mcqMisconceptionsData,
        ...mcqSkillIdentifierData,
        ...mcqQuestionGenerationData,
        ...quizMcqData,
        ...quizFreeResponseData,
        ...freeResponseData,
        ...mcqDistractData,
        ...contentFilterData
      ];
      /* eslint-disable no-param-reassign */
      if (combineData[0] && combineData[0].type === 'chat_intent') {
        try {
          combineData[0].botResponse = JSON.parse(combineData[0].botResponse);
        } catch (err) {
          const { botResponse } = combineData[0];
          combineData[0] = { ...combineData[0], botResponse: botResponse[0] };
        }
      }
      const combineDatatoJS = JSON.parse(JSON.stringify(toJS(combineData)));
      // const { prompt } = combineDatatoJS[0];
      if (combineDatatoJS[0] && Object.keys(combineDatatoJS[0]).length > 0) {
        rowProcessed = Object.entries(combineDatatoJS[0]).reduce((obj, [key, value]) => {
          if (key !== null && value !== null) {
            obj[key] = value;
          }
          return obj;
        }, {});
      }
    }
    if (rowProcessed.type === 'chat_intent') {
      const { botResponse } = rowProcessed;
      let processBotResponse = '';
      try {
        processBotResponse = [JSON.parse(botResponse)];
      } catch (err) {
        const splitTwoText = botResponse.split('[BAD-GPT]');
        const normGPT = JSON.parse(splitTwoText[0].split('[NORM-GPT]')[1]);
        const badGPT = JSON.parse(splitTwoText[1]);
        processBotResponse = [normGPT, badGPT];
      }
      rowProcessed.botResponse = processBotResponse;
    }
    return rowProcessed;
  };

  static displayUserId = (data) => {
    if (data.userId === constants.SIGNED_OUT_USER_ID) {
      return constants.SIGNED_OUT_USER;
    }
    return data.userId;
  };

  static processChannelsDataResponse = (channelsUtility) => {
    let rowProcessed = {};
    const { content_filter, channelsResult } = channelsUtility;
    if (channelsUtility) {
      const channelsResultData = channelsResult && channelsResult.length > 0 ? channelsResult : [];
      const contentFilterData = content_filter && content_filter.length > 0 ? content_filter : [];
      const combineData = [...channelsResultData, ...contentFilterData];
      const combineDatatoJS = JSON.parse(JSON.stringify(toJS(combineData)));
      /* eslint-disable no-param-reassign */
      if (combineDatatoJS[0] && Object.keys(combineDatatoJS[0]).length > 0) {
        rowProcessed = Object.entries(combineDatatoJS[0]).reduce((obj, [key, value]) => {
          if (key !== null && value !== null) {
            obj[key] = value;
          }
          return obj;
        }, {});
      }
    }
    return rowProcessed;
  };

  static processChannelsSubTypeResponse = (channelsSubType) => {
    let returnVal = [];
    const { content_filter, channelsResult } = channelsSubType;
    if (channelsSubType) {
      const channelsResultData = channelsResult && channelsResult.length > 0 ? channelsResult : [];
      const contentFilterData = content_filter && content_filter.length > 0 ? content_filter : [];
      const combineData = [...channelsResultData, ...contentFilterData];
      returnVal = JSON.parse(JSON.stringify(toJS(combineData)));
    }
    return returnVal;
  };

  static weeksStartEndDate = (month, monthIndex, year) => {
    const getMomentDate = (start, end, index) => {
      const startDate = moment([year, monthIndex - 1, start]).format(constants.MM_DD_YYYY_FORMAT);
      const endDate = moment([year, monthIndex - 1, end]).format(constants.MM_DD_YYYY_FORMAT);
      const currentDate = moment().format(constants.MM_DD_YYYY_FORMAT);
      return {
        startDate,
        endDate,
        weekNumber: `Week ${index + 1}`,
        month: moment([year, monthIndex - 1, start]).format(constants.MMMM_FORMAT),
        disable: moment().year() === year && endDate >= currentDate
      };
    };
    const weekStartEndDay = [];
    const first = month.day() === 0 ? 6 : month.day() - 1;
    let day = 7 - first;
    const last = month.daysInMonth();
    const count = (last - day) / 7;
    weekStartEndDay.push(getMomentDate(1, day, 0));
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < count; i++) {
      weekStartEndDay.push(getMomentDate(day + 1, Math.min((day += 7), last), i + 1));
    }
    return weekStartEndDay;
  };

  static getSessionData = () => {
    let keys = window.sessionStorage.getItem('msal.account.keys');
    if (keys !== null && keys !== '[]') {
      keys = keys.replace(/[,"[\]]/g, '');
      let value = window.sessionStorage.getItem(keys);
      value = JSON.parse(value);
      const { username } = value;
      return username;
    }
    return null;
  };

  static containsMathML = (str) => {
    const mathmlRegex = /<math[\s\S]*?<\/math>/gi; // Regex to match <math> ... </math> tags
    return mathmlRegex.test(str);
  };

  static loadMathJax = () => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = constants.MATHJAX_CDN_URL;
    document.head.appendChild(script);
  };

  static processMath = (element) => {
    if (typeof window.MathJax !== 'undefined' && typeof window.MathJax.Hub !== 'undefined') {
      if (element) {
        window.MathJax.Hub.Queue(['Typeset', window.MathJax.Hub, element]);
      }
    } else {
      // Load MathJax dynamically
      utils.loadMathJax();
    }
  };

  static containsMarkDownText = (res) => {
    const str = typeof res === 'string' ? res : res.toString();
    // Regular expressions for common Markdown patterns
    const markdownPatterns = [
      /#\s/, // Headers (e.g., # Header)
      /\[.*?\]\(.*?\)/, // Links (e.g., [link](http://example.com))
      /\*\*.*?\*\*/, // Bold (e.g., **bold text**)
      /__.*?__/, // Bold (e.g., __bold text__)
      /\*.*?\*/, // Italic (e.g., *italic text*)
      /_.*?_/, // Italic (e.g., _italic text_)
      /^\s*[-*+]\s+/, // Unordered lists (e.g., - item)
      /^\s*\d+\.\s+/, // Ordered lists (e.g., 1. item)
      /```/, // Code blocks (e.g., ```code``` for inline code blocks)
      /~~/, // Strikethrough (e.g., ~~strikethrough~~)
      /!\[.*?\]\(.*?\)/ // Images (e.g., ![alt text](url))
    ];

    return markdownPatterns.some((pattern) => pattern.test(str));
  };

  static convertToMarkedDownString = (content) => {
    const str = typeof content === 'string' ? content : content.toString();
    const formattedText = str.replace(/\n\s*\n/g, '\n');
    const parsedData = marked
      .parse(formattedText.replaceAll('`', '@@').replaceAll('\\', '@!'))
      .replaceAll('<em>', '_')
      .replaceAll('</em>', '_');
    return parsedData.replaceAll('@@', '`').replaceAll('@!', '\\');
  };
}

/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2021 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Pearson Education, Inc.  The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S. and Foreign Patents,
 * patent applications, and are protected by trade secret or copyright law.
 * Dissemination of this information, reproduction of this material, and copying or distribution of this software
 * is strictly forbidden unless prior written permission is obtained
 * from Pearson Education, Inc.
 */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { inject, observer } from 'mobx-react';
import axios from 'axios';
// import { toJS } from 'mobx';
import PropTypes from 'prop-types';
import Framework, { shapes } from '@greenville/framework';
import {
  Typography,
  withStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  Button
} from '@material-ui/core';
// import TooltipCustom from '../../../common/components/TooltipCustom';
// import utils from '../../../../common/utils';
import env from '../../../common/env';
import * as constants from '../../../common/constants';
import StatusDialog from './StatusDialog';
import BulkAssets from '../models/BulkAssets';

const styles = (theme) => ({
  root: {
    width: '100%',
    bottom: theme.spacing(2)
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.5),
      textAlign: 'center'
    }
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 14
  }
});

const BulkAssetsTable = ({ classes, language, data, bulkAssets }) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [status, setStatus] = React.useState({});
  const [open, setOpen] = React.useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleStatus = (id) => {
    bulkAssets.fetchStatus(id);
    Framework.getEventManager().on(constants.SET_PULSE_ASSETS_BY_ID, () => {
      setStatus(bulkAssets.dataById);
      setOpen(true);
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = async (contentPath) => {
    let signedUrl = '';
    const path = contentPath.split('/').slice(1).join('/');
    const param = {
      contentPath: path,
      type: 'download'
    };
    const response = await axios.post(`${env.EVERGREEN_API_BASE_URL}${constants.CM_GET_SIGNED_URL}`, param);
    if (response && response.data && response.data.data) {
      signedUrl = response.data.data;
      const aTag = document.createElement('a');
      aTag.setAttribute('download', '');
      aTag.setAttribute('href', signedUrl);
      aTag.click();
    } else {
      const notification = {
        open: true,
        message: `Error downloading. Please try again !`,
        type: 'error'
      };
      Framework.getStoreRegistry().getStore('notificationState').set(notification);
    }
  };

  const getFileName = (path) => {
    const fileName = path.split('/').pop();
    return fileName.split('.').slice(0, -1).join('.');
  };

  return (
    <>
      <TableContainer className={classes.root} component={Paper}>
        <Table className={classes.metaRoot} stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('bulkutility.CONTENT_ID')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('label.PRODUCT_ID')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('bulkutility.CONTENT_TYPE')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('label.BOOK_ID')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('bulkutility.CONTENT_PATH')} />
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Typography variant="h5">
                  <FormattedMessage {...language.getText('label.STATUS')} />
                </Typography>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 &&
              data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                <TableRow>
                  <TableCell>
                    <Typography className={classes.tableText} component="div">
                      {row.content_id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableText} component="div">
                      {row.product_id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableText} component="div">
                      {row.content_type}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography className={classes.tableText} component="div">
                      {row.book_id}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography
                      className={classes.tableText}
                      style={{ cursor: 'pointer', textDecoration: 'underline', color: '#0000EE' }}
                      component="div"
                      onClick={() => handleDownload(row.output_xls_s3path)}
                    >
                      {row.output_xls_s3path ? getFileName(row.output_xls_s3path) : null}
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Button
                      variant="outlined"
                      color="primary"
                      size="small"
                      onClick={() => handleStatus(row.product_id)}
                    >
                      <FormattedMessage {...language.getText('bulkutility.VIEW_STATUS')} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[10, 30, 60, 100]}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {open && <StatusDialog openhistory={open} close={handleClose} rowData={status} />}
    </>
  );
};

BulkAssetsTable.propTypes = {
  classes: PropTypes.object.isRequired,
  language: shapes.language.isRequired,
  data: PropTypes.object.isRequired,
  bulkAssets: shapes.modelOf(BulkAssets).isRequired
};

export default withStyles(styles)(observer(inject('language')(BulkAssetsTable)));

import { LoadingHandler, StateErrorDisplay } from '@greenville/framework';
import { Checkbox, FormControlLabel, FormGroup, Grid, Paper, styled } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CloseIcon from '@material-ui/icons/Close';
import { Box, Card, CardContent } from '@mui/material';
import Button from '@mui/material/Button';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import Switch from '@mui/material/Switch';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import _ from 'lodash';
import { PropTypes } from 'prop-types';
import React, { useEffect, useState } from 'react';
import Loader from '../Loader';
import * as constants from '../constants';
import utils from '../utils';
import MathJaxWrapper from '../../modules/chatUtility/common/components/MathJaxWrapper';

const styles = (theme) => ({
  root: {
    width: '100%'
  },
  metaRoot: {
    '& .MuiTableCell-root': {
      padding: theme.spacing(0.6)
    }
  },
  titleAuthorPadding: {
    paddingTop: theme.spacing(1)
  },
  paper: {
    padding: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  titleMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: theme.spacing(1.5)
  },
  contentMargin: {
    marginLeft: 'inherit',
    marginRight: 'inherit',
    marginBottom: 'inherit',
    '& .MuiDialogContent-root:first-child': {
      paddingTop: '12px'
    }
  },
  dialogContent: {
    padding: '4px 20px'
  },
  dialogPaper: {
    minHeight: '60vh',
    maxWidth: '80%'
  },
  tableText: {
    textAlign: 'center',
    color: '#6a7070',
    fontSize: 13
  },
  bookInfoText: {
    color: '#6a7070',
    fontSize: 13,
    paddingLeft: theme.spacing(0.6)
  },
  statusText: {
    textAlign: 'center',
    fontSize: 13
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(2),
    top: theme.spacing(0.7),
    color: theme.palette.grey[700]
  },
  aiStudyToolcard: {
    height: '435px',
    overflowY: 'auto !important'
  },
  inCreaseAiStudyToolcard: {
    overflowY: 'auto !important',
    height: '585px'
  },
  promptCard: {
    overflowY: 'auto !important',
    maxHeight: '235px',
    minHeight: '235px'
  },
  minimizedPromptCard: {
    overflowY: 'auto !important',
    height: '130px'
  },
  chatIntentCard: {
    overflowY: 'auto !important',
    height: '200px'
  },
  subTypeModel: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ques: {
    fontSize: '1rem',
    fontWeight: 500
  },
  quesOptions: {
    fontSize: '0.9rem',
    fontWeight: 400
  },
  feedback: {
    fontSize: '0.8rem',
    padding: '0.5rem 0rem 0.5rem 1rem'
  }
});

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

const DialogComponent = (props) => {
  const {
    classes,
    openChat,
    dataValue,
    handleClose,
    userTocDetails,
    handleModalChange,
    modalIndex,
    dataLength,
    subTypeData,
    loaderStatus,
    handleSelectedCheckBox,
    loaderParams
  } = props;
  const [checkboxSelectedValue, setCheckboxSelectedValue] = useState({});

  const [checked, setChecked] = useState({
    channelsRawCheck: false
  });
  const [channelsRawResponse, setChannelsRawResponse] = useState('');

  useEffect(() => {
    if (!_.isEmpty(dataValue.botResponse)) {
      if (checked.channelsRawCheck) {
        setChannelsRawResponse(JSON.stringify(dataValue?.botResponse, null, 2).slice(1, -1));
      } else {
        setChannelsRawResponse('');
      }
    }
  }, [checked]);

  useEffect(() => {
    if (subTypeData && subTypeData.length === 0) {
      setCheckboxSelectedValue({});
    }
  }, [subTypeData, dataValue]);

  const handleNavigationChange = (nav) => {
    setChecked({ ...checked, channelsRawCheck: false });
    handleModalChange(nav);
  };

  const handleSelectedValue = (event) => {
    let sourceType = '';
    if (event.target.checked) {
      sourceType = event.target.value;
    } else {
      sourceType = dataValue.mainType;
    }
    const { value } = event.target;
    if (value === constants.PROXY_CONTENT_FILTER) {
      setCheckboxSelectedValue({
        ...checkboxSelectedValue,
        PROXY_CONTENT_FILTER: !checkboxSelectedValue.PROXY_CONTENT_FILTER
      });
    }
    if (sourceType) {
      handleSelectedCheckBox(value, sourceType);
    }
  };

  const checkMathJax = (response) => {
    if (!_.isEmpty(response) && (response.includes('`') || response.includes('</math>') || response.includes('\\'))) {
      const regesXmlns = /<math([^>]*)>/g;
      const res = utils.containsMathML(response)
        ? response.replace(regesXmlns, '<math xmlns="http://www.w3.org/1998/Math/MathML">')
        : response;
      return (
        <MathJaxWrapper>
          {/* eslint-disable */}
          <span dangerouslySetInnerHTML={{ __html: res }} />
          {/* eslint-disable */}
        </MathJaxWrapper>
      );
    }
    return (
      <>
        {/* eslint-disable-next-line */}
        <span dangerouslySetInnerHTML={{ __html: response }} />
      </>
    );
  };

  const isJSON = (str) => {
    try {
      const parsedVal = JSON.parse(str);
      return {
        parsedData: parsedVal,
        isValidJson: parsedVal && !!str
      };
    } catch (e) {
      try {
        const parsedVal = JSON.parse(str.toString().replace(/'/g, '"'));
        return {
          parsedData: parsedVal,
          isValidJson: parsedVal && !!str
        };
      } catch (err) {
        return {
          isValidJson: false
        };
      }
    }
  };

  const renderHtmlResponse = () => {
    let botValue = dataValue.botResponse;
    if (botValue) {
      const { isValidJson, parsedData } = isJSON(JSON.stringify(dataValue.botResponse));
      if (isValidJson) {
        if (
          Object.prototype.hasOwnProperty.call(parsedData, 'blocklistsMatchResults') &&
          Object.prototype.hasOwnProperty.call(parsedData, 'Category_Severity') &&
          _.isEmpty(parsedData.blocklistsMatchResults) &&
          _.isEmpty(parsedData.Category_Severity)
        ) {
          botValue = '';
        }
      }
    }
    return (
      <Typography variant="body2" color="text.secondary" textAlign="left" key={dataValue.userId}>
        {checkMathJax(botValue)}
      </Typography>
    );
  };

  const renderQuestionIntentResponse = (parsedData) => {
    return (
      <>
        <Typography
          variant="body2"
          color="text.primary"
          textAlign="center"
        >{`Question Type: ${parsedData.questionType}`}</Typography>
        {parsedData.topics && Array.isArray(parsedData.topics) ? (
          <>
            {parsedData.topics.map((arr, index) => (
              <ul key={index}>
                <li>{arr}</li>
              </ul>
            ))}
          </>
        ) : (
          <Typography variant="body2" color="text.secondary" textAlign="left">
            Topic:
            {checkMathJax(parsedData.topic)}
          </Typography>
        )}
      </>
    );
  };

  const renderParsedResponse = () => {
    const { parsedData, isValidJson } = !_.isEmpty(dataValue.botResponse) && isJSON(dataValue.botResponse);
    if (dataValue.type === constants.GENERATE_QUESTION_INTENT) {
      return isValidJson ? (
        renderQuestionIntentResponse(parsedData)
      ) : (
        <Typography variant="body2" color="text.secondary" textAlign="left" key={dataValue.userId}>
          {checkMathJax(dataValue.botResponse)}
        </Typography>
      );
    }
    if (dataValue.type === constants.GENERATE_QUESTION_STEPS_AND_SOLUTION && parsedData && isValidJson) {
      return (
        <Typography variant="body2" color="text.secondary" textAlign="left" key={dataValue.userId}>
          <Typography variant="body2" color="text.secondary" textAlign="left">
            stepByStep
          </Typography>
          {checkMathJax(parsedData.stepByStep)}
          <Typography variant="body2" color="text.secondary" textAlign="left">
            solve
          </Typography>
          {checkMathJax(parsedData.solve)}
        </Typography>
      );
    }
    if (dataValue.type === constants.GENERATE_QUESTIONS && parsedData && isValidJson) {
      if (parsedData.questions.length > 0) {
        return parsedData.questions.map((value, index) => {
          return (
            <>
              <Typography className={classes.ques} variant="body2" color="text.primary">
                <b>Question - {index + 1}</b> : <span>{checkMathJax(value.question)}</span>
              </Typography>
              {value.answers &&
                value.answers.map((optionItem, subIndex) => {
                  return optionItem ? (
                    <>
                      <Typography className={classes.quesOptions} variant="body2" color="text.primary">
                        {subIndex + 1}.&nbsp;{checkMathJax(optionItem.answer)} -{' '}
                        {optionItem.correct ? (
                          <span style={{ color: 'green' }}>
                            Correct
                            <CheckCircleIcon />
                          </span>
                        ) : (
                          'Incorrect'
                        )}
                      </Typography>
                    </>
                  ) : null;
                })}
              <br />
            </>
          );
        });
      }
    }
    return isValidJson
      ? Object.keys(parsedData).map((val) => {
          if (val === 'bullets' && Array.isArray(parsedData[val])) {
            return (
              <>
                {parsedData[val].map((arr, index) => (
                  <ul key={index}>
                    <li>{arr}</li>
                  </ul>
                ))}
              </>
            );
          }
          return parsedData[val];
        })
      : renderHtmlResponse();
  };

  const handleToggleCheck = (event) => {
    setChecked({ ...checked, [event.target.name]: event.target.checked });
  };

  const renderRawResponse = () => {
    const botResValue = dataValue.botResponse;
    if (
      _.isEmpty(botResValue) ||
      [constants.PROXY_CONTENT_FILTER].includes(dataValue.type) ||
      (Object.prototype.hasOwnProperty.call(botResValue, 'blocklistsMatchResults') &&
        Object.prototype.hasOwnProperty.call(botResValue, 'Category_Severity') &&
        _.isEmpty(botResValue.blocklistsMatchResults) &&
        _.isEmpty(botResValue.Category_Severity))
    ) {
      return <></>;
    }
    return (
      <FormGroup display="inline" spacing={0} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <FormControlLabel
          control={<Switch name="channelsRawCheck" checked={checked.channelsRawCheck} onChange={handleToggleCheck} />}
          label="Raw Response"
          style={{ alignItems: 'center', padding: '0px' }}
        />
      </FormGroup>
    );
  };

  const renderBotResponse = () => {
    return !checked.channelsRawCheck ? (
      <CardContent style={{ textAlign: 'justify' }}>{renderParsedResponse()}</CardContent>
    ) : (
      <CardContent style={{ textAlign: 'justify' }}>
        <Typography variant="body2" color="text.secondary" textAlign="left" key={dataValue.userId}>
          {channelsRawResponse}
        </Typography>
      </CardContent>
    );
  };

  return (
    <Dialog fullScreen open={openChat} onClose={handleClose}>
      <Box>
        <LoadingHandler
          loading={loaderParams.loadingStatusVal}
          isError={loaderParams.isErrorValue}
          content={<></>}
          loadingContent={<Loader loaderStatus={!loaderStatus} />}
          errorContent={<StateErrorDisplay error={loaderParams.errorDisplayVal} showDetails />}
        />
      </Box>

      <Box sx={{ p: 2 }}>
        <Paper elevation={0}>
          <Grid container spacing={0} padding={0}>
            <Grid item xs={5}>
              <Typography align="left" variant="subtitle1">
                {constants.CHAT_USERLOGIN}: {utils.displayUserId(dataValue)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography align="left" display="inline">
                <Stack
                  display="inline"
                  spacing={0}
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                  <Button disabled={modalIndex === 0} onClick={() => handleNavigationChange('prev')} size="large">
                    <ChevronLeftIcon />
                  </Button>
                  <span color="secondary">{modalIndex + 1}</span> /<span color="secondary"> {dataLength}</span>
                  <Button disabled={modalIndex + 1 === dataLength} onClick={() => handleNavigationChange('next')}>
                    <ChevronRightIcon />
                  </Button>
                  {dataValue && dataValue.type && (
                    <Typography>
                      {constants.TYPE} :{' '}
                      {dataValue.type === 'answer' || dataValue.type === 'discuss'
                        ? 'Explain'
                        : dataValue.type.charAt(0).toUpperCase() + dataValue.type.slice(1)}
                    </Typography>
                  )}
                </Stack>
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography align="right" variant="subtitle1">
                {Object.keys(dataValue).length > 0 ? (
                  <Typography display="inline" variant="subtitle2">
                    <span>{`${dataValue && dataValue.serverDateTime}  (GMT) `}</span>
                  </Typography>
                ) : (
                  <span> </span>
                )}
                <Box display="inline" sx={{ pl: 4 }}>
                  <IconButton edge="start" color="default" onClick={() => handleClose()} aria-label="close">
                    <Tooltip title="Close">
                      <CloseIcon />
                    </Tooltip>
                  </IconButton>
                </Box>
              </Typography>
            </Grid>
          </Grid>
          {dataValue && constants.CHANNELS_CHECKBOX_TYPE.includes(dataValue.type) && (
            <Grid container spacing={0} padding={0} className={classes.subTypeModel}>
              <Stack sx={{ alignItems: 'center' }}>
                <FormGroup
                  display="inline"
                  spacing={0}
                  style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checkboxSelectedValue && checkboxSelectedValue.PROXY_CONTENT_FILTER === true}
                        color="primary"
                        value={constants.PROXY_CONTENT_FILTER}
                        onChange={(e) => handleSelectedValue(e)}
                      />
                    }
                    label="Include Content Filter"
                    style={{ alignItems: 'center', padding: '0px' }}
                  />
                </FormGroup>
              </Stack>
            </Grid>
          )}
        </Paper>
      </Box>
      <Box sx={{ p: 2, paddingTop: '0px' }}>
        <Grid container spacing={2} style={{ minWidth: 800 }}>
          <Grid item xs={6}>
            <Grid item xs={12}>
              <Item>
                <Card style={{ overflowY: 'auto', maxHeight: '145px', minHeight: '145px' }}>
                  <CardHeader title="User Input/Command" style={{ background: '#34282808' }} align="left" />
                  <CardContent>
                    <Typography variant="body2" color="text.primary" textAlign="left">
                      {dataValue && dataValue.question}
                      {userTocDetails &&
                        Object.keys(userTocDetails).map((val) => {
                          return <Typography variant="h6">{`: ${val.sectionName}`}</Typography>;
                        })}
                    </Typography>
                    <Typography variant="body2" color="text.primary" textAlign="left">
                      {dataValue && dataValue.userCommand}
                    </Typography>
                  </CardContent>
                </Card>
              </Item>
              <br />
              {dataValue && (
                <Grid>
                  <Item>
                    <Card
                      className={
                        dataValue.type === constants.PROXY_CONTENT_FILTER
                          ? classes.minimizedPromptCard
                          : classes.promptCard
                      }
                    >
                      <CardHeader
                        title={constants.CHAT_PROMPT_CONTENT}
                        style={{ background: '#34282808' }}
                        align="left"
                      />
                      <CardContent>
                        {dataValue &&
                          dataValue.content &&
                          Array.isArray(dataValue.content) &&
                          dataValue.content.map((val, index) => (
                            <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                              {val}
                            </Typography>
                          ))}
                        {dataValue && dataValue.content && dataValue.content.length === 0 ? (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            There is no data content available for this record.
                          </Typography>
                        ) : (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {dataValue.content}
                          </Typography>
                        )}
                        <br />
                        {dataValue.prompt && typeof dataValue.prompt === 'object' ? (
                          dataValue.prompt.map((data) => {
                            return (
                              <>
                                <Typography
                                  variant="body2"
                                  color="text.primary"
                                  textAlign="center"
                                >{`Role: ${data.role}`}</Typography>
                                {data.prompt && (
                                  <>
                                    <Typography variant="body2" color="text.primary" textAlign="left">
                                      {constants.CHAT_PROMPT}:
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" textAlign="left">
                                      {data.prompt.toString().replace(/\n/g, '\\n')}
                                    </Typography>
                                    <br />
                                  </>
                                )}
                              </>
                            );
                          })
                        ) : (
                          <Typography variant="body2" color="text.secondary" textAlign="left">
                            {dataValue && dataValue.prompt && dataValue.prompt.toString().replace(/\n/g, '\\n')}
                          </Typography>
                        )}
                      </CardContent>
                    </Card>
                  </Item>
                </Grid>
              )}
            </Grid>
            {dataValue &&
              dataValue.type === constants.PROXY_CONTENT_FILTER &&
              dataValue.botResponse &&
              Object.keys(dataValue.botResponse).length > 0 && (
                <>
                  <br />
                  <Item>
                    <Card className={classes.chatIntentCard}>
                      <CardHeader title="Content Filter Response" style={{ background: '#34282808' }} align="left" />
                      <CardContent>
                        {Object.keys(dataValue.botResponse).map((key) => {
                          if (key === 'Category_Severity') {
                            return dataValue.botResponse[key].map((data, index) => {
                              return (
                                <>
                                  <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                    Category : {data.Category}
                                  </Typography>
                                  <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                    Severity : {data.Severity}
                                  </Typography>
                                </>
                              );
                            });
                          }
                          return (
                            <>
                              <Typography variant="body2" color="text.secondary" textAlign="left">
                                blocklistsMatchResults :{' '}
                                {dataValue.botResponse[key].length > 0 ? (
                                  dataValue.botResponse[key].map((data, index1) => {
                                    return Object.entries(data).map(([blockListResultKey, blockListResultValue]) => (
                                      <Typography variant="body2" color="text.secondary" textAlign="left" key={index1}>
                                        {blockListResultKey} : {blockListResultValue}
                                      </Typography>
                                    ));
                                  })
                                ) : (
                                  <Typography variant="body2" color="text.secondary" textAlign="left">
                                    []
                                  </Typography>
                                )}
                              </Typography>
                              <br />
                            </>
                          );
                        })}
                      </CardContent>
                    </Card>
                  </Item>
                </>
              )}
            <br />
          </Grid>
          <Grid item xs={6}>
            <Item>
              <Card
                className={
                  dataValue.type === constants.PROXY_CONTENT_FILTER
                    ? classes.inCreaseAiStudyToolcard
                    : classes.aiStudyToolcard
                }
              >
                <CardHeader
                  title="AI Study Tool Response"
                  style={{ background: '#34282808' }}
                  align="left"
                  action={renderRawResponse()}
                />
                {dataValue &&
                dataValue.type === constants.PROXY_CONTENT_FILTER &&
                typeof dataValue.botResponse === 'object' ? (
                  <Typography variant="body2" color="text.secondary" textAlign="left">
                    {dataValue.finalResponse}
                  </Typography>
                ) : (
                  renderBotResponse()
                )}
              </Card>
            </Item>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          {subTypeData &&
            subTypeData.length > 0 &&
            subTypeData.map((item) => {
              if (checkboxSelectedValue.PROXY_CONTENT_FILTER === true && item.type === constants.PROXY_CONTENT_FILTER) {
                return (
                  item.botResponse &&
                  Object.keys(item.botResponse).length > 0 && (
                    <>
                      <br />
                      <Item>
                        <Card className={classes.chatIntentCard}>
                          <CardHeader
                            title="Content Filter Response"
                            style={{ background: '#34282808' }}
                            align="left"
                          />
                          <CardContent>
                            {Object.keys(item.botResponse).map((key) => {
                              if (key === 'Category_Severity') {
                                return item.botResponse[key].map((data, index) => {
                                  return (
                                    <>
                                      <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                        Category : {data.Category}
                                      </Typography>
                                      <Typography variant="body2" color="text.secondary" textAlign="left" key={index}>
                                        Severity : {data.Severity}
                                      </Typography>
                                    </>
                                  );
                                });
                              }
                              return (
                                <>
                                  <Typography variant="body2" color="text.secondary" textAlign="left">
                                    blocklistsMatchResults :{' '}
                                    {item.botResponse[key].length > 0 ? (
                                      item.botResponse[key].map((data, index1) => {
                                        return Object.entries(data).map(
                                          ([blockListResultKey, blockListResultValue]) => (
                                            <Typography
                                              variant="body2"
                                              color="text.secondary"
                                              textAlign="left"
                                              key={index1}
                                            >
                                              {blockListResultKey} : {blockListResultValue}
                                            </Typography>
                                          )
                                        );
                                      })
                                    ) : (
                                      <Typography variant="body2" color="text.secondary" textAlign="left">
                                        []
                                      </Typography>
                                    )}
                                  </Typography>
                                  <br />
                                </>
                              );
                            })}
                          </CardContent>
                        </Card>
                      </Item>
                    </>
                  )
                );
              }
              return <></>;
            })}
        </Grid>
      </Box>
    </Dialog>
  );
};

DialogComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  openChat: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  dataValue: PropTypes.object,
  dataLength: PropTypes.object,
  modalIndex: PropTypes.string,
  subTypeData: PropTypes.object,
  userTocDetails: PropTypes.object,
  handleModalChange: PropTypes.func.isRequired,
  loaderStatus: PropTypes.bool,
  handleSelectedCheckBox: PropTypes.func.isRequired,
  loaderParams: PropTypes.object.isRequired
};

DialogComponent.defaultProps = {
  modalIndex: '',
  dataValue: {},
  dataLength: {},
  userTocDetails: {},
  subTypeData: [],
  loaderStatus: false
};

export default withStyles(styles)(DialogComponent);

import * as React from 'react';
import { styled } from '@mui/material/styles';
import Button from '@material-ui/core/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import PropTypes from 'prop-types';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';

const themes = createTheme({
  palette: {
    primary: {
      light: '#047a9c',
      main: '#005d83',
      dark: '#003558',
      contrastText: '#ffffff'
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 10
      }
    }
  }
});

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  // height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

const style = {
  buttonStyle: {
    width: '150px',
    margin: '10px'
  }
};

const InputFileUploadComponent = (props) => {
  const { handleUploadFile } = props;
  return (
    <>
      <ThemeProvider theme={themes}>
        <Button
          component="label"
          variant="contained"
          color="primary"
          size="small"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
          style={style.buttonStyle}
        >
          Upload file
          <VisuallyHiddenInput type="file" onChange={(event) => handleUploadFile(event)} />
        </Button>
      </ThemeProvider>
    </>
  );
};
InputFileUploadComponent.propTypes = {
  handleUploadFile: PropTypes.func.isRequired
};
export default InputFileUploadComponent;
